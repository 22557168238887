import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { Option } from '../../interfaces';
import { AppTheme } from 'src/app/types/users.types';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ItemInputRadioModalComponent {
    @Input() theme: AppTheme;
    @Input() optionValue: unknown;
    @Input() searchbar: boolean;
    private option: Option;
    public searchTerm: string;
    @Input() title: string;
    @Input() displayAccordion: boolean;
    @Input() optionsGroups: { [key: string]: Option[] };
    @Input() optionsGroup: Option[];
    @Input() orderBy = 'name';
    @Input() controlName: string;
    @Input() form: FormGroup;
    public disabled = true;
    public contentClass: string;
    constructor(public modalController: ModalController, private platformService: PlatformService) {
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
    }

    filterOptions(event: Event): void {
        const {
            detail: { value }
        } = event as CustomEvent;

        this.searchTerm = value.toLowerCase();
    }

    async radioGroupChange(option: Option) {
        this.option = option;
        this.disabled = false;
        await this.modalController.dismiss(this.option, 'confirm');
    }

    async modalClose(): Promise<void> {
        await this.modalController.dismiss(null, 'cancel');
    }
}
