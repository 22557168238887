<ion-content [ngClass]="contentClass">
    <item-section-header [theme]="theme" [iconClose]="true" [title]="title" (closeModal$)="modalClose()"></item-section-header>
    <form [formGroup]="editForm" (ngSubmit)="submitRequest()" class="modal-layout">
        <item-input-text *ngIf="control" [label]="controlNameLabel" [controlName]="controlName" [theme]="theme"></item-input-text>
        <item-input-text [label]="controlNameChangedLabel" controlName="controlNameChanged" [theme]="theme"></item-input-text>
        <item-input-text [label]="controlNameChangedLabelConfirm" controlName="controlNameChangedConfirm" [theme]="theme"></item-input-text>
        <ion-item lines="none">
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-align-items-center ion-padding">
                    <ion-col [size]="size" class="ion-text-center">
                        <ion-button size="default" [expand]="expand" [color]="theme" type="submit" [disabled]="!editForm.valid">
                            {{ 'button.confirm' | translate | uppercase }}
                        </ion-button>
                    </ion-col>
                    <ion-col size="6" class="ion-text-start">
                        <ion-button *ngIf="userGroup !== Group.customer" size="default" expand="block" [color]="theme" (click)="controlRebase()">
                            {{ 'button.rebase' | translate }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
    </form>
</ion-content>
