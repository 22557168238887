<ion-item detail="true" lines="full" class="ion-no-margin ion-no-padding" mode="ios" (click)="openModal()">
    <ion-label class="ion-no-margin ion-no-padding">
        <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="'medium-' + theme">
            {{ 'unit.label.memory' | translate }}
        </ion-text>
        <ion-input
            class="text-input-roboto-regular-16 ion-no-margin ion-no-padding"
            type="text"
            [value]="'unit.input.memory' | translate"
            [readonly]="true"
        ></ion-input>
    </ion-label>
</ion-item>
