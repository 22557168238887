import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { GlobalError } from 'src/app/models/application.models';
import { ErrorDialogModule } from '../components/error-dialog/error-dialog.module';

@Injectable({
    providedIn: 'root'
})
export class ErrorDialogService {
    constructor(public modalController: ModalController, private logger: NGXLogger, public toastController: ToastController) {}

    openModal = async (error: GlobalError): Promise<HTMLIonModalElement> => {
        if (!error) return;

        const { message, status: statusCode, statusText } = error;

        const modal = await this.modalController.create({
            component: ErrorDialogModule,
            componentProps: {
                message,
                statusCode,
                statusText
            },
            mode: 'ios'
        });

        this.logger.info(`ErrorDialogService receiving error: ${error.message}`);

        await modal.present();

        return modal;
    };

    openToast = async (error: GlobalError): Promise<HTMLIonToastElement> => {
        if (!error) return;

        const toastTopOverlay = await this.toastController.getTop();
        if (toastTopOverlay) return;

        const { message, status: statusCode, statusText } = error;

        const toast = await this.toastController.create({
            header: `${statusCode}-${statusText}`,
            message,
            duration: 10000,
            icon: 'bug',
            position: 'middle',
            translucent: true,
            color: 'danger',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel'
                }
            ]
        });

        this.logger.info(`ErrorDialogService receiving error: ${error.message}`);

        const style = document.createElement('style');

        style.textContent = `
      ion-toast::part(header) {
        font-size: 1.2rem;
      }

      ion-toast::part(icon) {
        font-size: 2rem;
      }`;
        toast.appendChild(style);

        await toast.present();

        return toast;
    };
}
