import { Component, Input, OnInit } from '@angular/core';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'page-intro',
    templateUrl: './page-intro.page.html',
    styleUrls: ['./page-intro.page.scss']
})
export class PageIntroPage implements OnInit {
    @Input() introTitle: string;
    @Input() introText: string;
    @Input() data: string;
    public textColor = 'light';
    @Input() set theme(value: AppTheme) {
        if (value === AppTheme.dark) {
            this.textColor = 'light';
        } else {
            this.textColor = 'dark';
        }
    }
    constructor() {}

    ngOnInit() {}
}
