<ion-item button [formGroup]="form" (click)="openOptions()" detail="false" lines="lines" [ngClass]="contentClass" class="ion-no-margin ion-no-padding">
    <ion-label [position]="labelPosition">{{ label | translate }}</ion-label>
    <ion-input *ngIf="showInputName" [type]="inputType" [readonly]="readonly" [value]="option?.name"></ion-input>
    <ion-input [hidden]="showInputName" [type]="inputType" [formControlName]="controlName" [readonly]="readonly" [placeholder]="placeholder"></ion-input>
    <ng-container *ngIf="option">
        <ion-thumbnail *ngIf="option.avatar && theme" slot="end" class="avatar">
            <svg-icon [src]="'assets/ha/svg/' + option.avatar + '.svg?'" [svgStyle]="{ 'height.px':25,'width.px':25,'fill':'#c5c5c5'}"></svg-icon>
        </ion-thumbnail>
        <!-- <ion-thumbnail *ngIf="option.thumbnail" slot="end">
            <ion-img [src]="option.thumbnail"></ion-img>
        </ion-thumbnail> -->

        <ion-icon class="icon" *ngIf="option.icon && !option.avatar" slot="end" [name]="option.icon" [color]="theme" size="large"></ion-icon>
    </ng-container>

    <ion-icon
        *ngIf="iconName && !option?.icon && !openOptionsDisable || iconDetail"
        slot="end"
        name="chevron-forward"
        style="margin-right: -1%; margin-bottom: 5%"
    ></ion-icon>
</ion-item>
