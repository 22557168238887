import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { IpAddressesViewerModalComponent } from './ip-addresses-viewer-modal/ip-addresses-viewer-modal.component';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'ip-addresses-viewer',
    templateUrl: './ip-addresses-viewer.component.html',
    styleUrls: ['./ip-addresses-viewer.component.scss']
})
export class IpAddressesViewerComponent implements OnInit {
    @Input() theme: AppTheme;
    @Input() addresses: any[];
    @Input() publicIp: any;

    constructor(private platformService: PlatformService, public modalController: ModalController) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    openModal = async (): Promise<void> => {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: IpAddressesViewerModalComponent,
            componentProps: {
                theme: this.theme,
                addresses: this.addresses,
                publicIp: this.publicIp
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();
    };
}
