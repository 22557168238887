import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DisplayMode } from 'src/app/models/application.models';
import { UnitEntity } from 'src/app/models/units.models';
import { PlatformService } from 'src/app/services/platform.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { UnitsService } from 'src/app/services/units.service';
import { AppTheme } from 'src/app/types/users.types';

import Map from 'ol/Map';

@Component({
    selector: 'app-modal-yml-version',
    templateUrl: './modal-yml-version.component.html',
    styleUrls: ['./modal-yml-version.component.scss']
})
export class ModalYmlVersionComponent implements OnInit {
    @Input() theme: AppTheme;
    @Input() dockerCompose: string;
    public contentClass: string;
    public lines: string[] = [];

    constructor(
        public modalController: ModalController,
        private translate: TranslateService,
        private unitsService: UnitsService,
        private platformService: PlatformService
    ) {
        this.contentClass = 'ion-padding';
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {
        this.lines = this.dockerCompose.split(','); // Suddividi il contenuto in linee
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose = (): void => {
        this.modalController.dismiss();
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
}
