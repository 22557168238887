import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RoleChooserPage } from './role-chooser.page';
import { ItemSectionHeaderModule } from '../../item-section-header/item-section-header.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { RoleChooserModalComponent } from './role-chooser-modal/role-chooser-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        ItemSectionHeaderModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [RoleChooserPage, RoleChooserModalComponent],
    exports: [RoleChooserPage, RoleChooserModalComponent]
})
export class RoleChooserPageModule {}
