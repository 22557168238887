<item-section-header [title]="'users.pending.edit-modal'" [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()"></item-section-header>
<ion-content class="ion-padding">
    <section-header-background
        [theme]="theme"
        [imageDataUrl]="null"
        [name]="user.firstName"
        imageDataUrl="assets/backgrounds/user-default.webp"
    ></section-header-background>

    <ion-list lines="full">
        <!-- Nome -->
        <item-label-readout [theme]="theme" label="user.firstName" [value]="user.firstName"></item-label-readout>
        <!-- Job -->
        <item-label-readout [theme]="theme" label="Job" [value]="'label.users.' + user.job.toLowerCase()"></item-label-readout>
        <!-- Email -->
        <item-label-readout [theme]="theme" label="user.email" [value]="user.email"></item-label-readout>
        <!-- Form con campi modificabili -->
        <form [formGroup]="form">
            <!-- Lingua -->
            <language-chooser [theme]="theme" [value]="user.language" (selected)="changeLanguage($event, 'language')"></language-chooser>
            <!-- ruolo -->
            <role-chooser [theme]="theme" [value]="user.role" (selected)="changeRole($event, 'role')"></role-chooser>
            <!-- Vista -->
            <view-chooser
                [theme]="theme"
                [viewName]="user.viewName"
                [unitId]="user.unitId"
                (selected)="changeView($event, 'view')"
                [role]="roleSelected"
                [isEdit]="true"
            ></view-chooser>
            <!-- Orari -->
            <access-configuration
                *ngIf="roleSelected === 'member' || roleSelected === 'guest'"
                [timeWindowsEnable]="timeWindowsEnable"
                [role]="roleSelected"
                [theme]="theme"
                [(daysTimeWindows)]="daysTimeWindows"
                [(checkIn)]="checkIn"
                [(checkOut)]="checkOut"
                (valueChange)="timeWindowsEnableValueChanged($event)"
                (daysTimeWindowsChange)="daysValueChanged($event)"
                [titleWindowsEnable]="titleWindowsEnable"
            ></access-configuration>
        </form>
        <ion-button (click)="userPendingUpdate()" class="solid button-modal" expand="block" [disabled]="!form.valid">
            {{ 'button.submit' | translate }}
        </ion-button>
    </ion-list>
</ion-content>
