<ion-item detail="false" lines="none" [slot]="slot" [ngClass]="theme" *ngIf="sectionTitle" class="ion-no-margin ion-no-padding">
    <ion-label class="ion-item-align-start ion-text-start ion-margin-top padding-top add-space-top">
        <ion-text [ngClass]="classSectionTitle">{{sectionTitle | translate}}</ion-text>
    </ion-label>
</ion-item>

<ion-item detail="false" lines="none" [slot]="slot" [class]="class" *ngIf="titlePage || sectionSubtitle">
    <ion-label class="ion-item-align-start ion-text-start ion-text-wrap">
        <ion-text [ngClass]="classModalTitle">{{titlePage | translate}} {{name}}</ion-text>
    </ion-label>
</ion-item>

<ion-item detail="false" lines="none" [slot]="slot" *ngIf="title" [class]="class">
    <ion-icon *ngIf="backroute" slot="start" size="large" name="arrow-back" type="button" (click)="goBack()"></ion-icon>
    <!-- <ion-icon *ngIf="!backroute" slot="start" size="large" name="0000"></ion-icon> -->
    <ion-label class="ion-text-center">
        <ion-text [ngClass]="classModalTitle">{{ title | translate }}</ion-text>
    </ion-label>
    <ion-icon
        *ngIf="iconClose"
        slot="end"
        size="large"
        name="close"
        type="button"
        (click)="modalClose()"
        class="ion-no-margin ion-no-padding pointer"
    ></ion-icon>
</ion-item>
