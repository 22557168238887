<ion-content [ngClass]="contentClass">
    <item-section-header [iconClose]="true" (closeModal$)="modalClose()" [title]="title"></item-section-header>
    <form [formGroup]="userEditForm" (ngSubmit)="submitRequest()">
        <ion-grid [ngSwitch]="stepIndex">
            <ion-row class="ion-justify-content-center ion-align-items-center ion-margin" style="border-bottom: 1px solid rgba(189, 189, 189, 0.404)">
                <ion-col size="4">Inizio</ion-col>
                <ion-col size="4" class="ion-text-center ion-justify-content-center ion-align-items-center">
                    <div class="text-datetime-box">
                        <ion-text type="button" (click)="step()" [ngClass]="checkInOnly? ['classColorSelected'] : [stepIndex === 0 ? 'classColorSelected' : 'classColorUnselected']">
                            {{ userEditForm.value.checkIn | date : 'd/M/yy' }}
                        </ion-text>
                    </div>
                </ion-col>
                <ion-col size="4" class="margin-bottom ion-text-center">
                    <div class="text-datetime-box">
                        <ion-text type="button" (click)="changeTimesetCheckIn()" [ngClass]="checkInOnly? ['classColorSelected'] : [stepIndex === 0 ? 'classColorSelected' : 'classColorUnselected']">
                            {{ userEditForm.value.checkIn | date : 'H:mm' }}
                        </ion-text>
                    </div>
                    <ng-container *ngIf="!checkInOnly">
                        <ion-datetime
                            presentation="time"
                            formControlName="checkIn"
                            *ngIf="setCheckIn"
                            (ionBlur)="closeDateTimePicker()"
                            color="datetime-color"
                        ></ion-datetime>
                    </ng-container>
                    <ng-container *ngIf="checkInOnly">
                        <ion-datetime
                            presentation="time"
                            formControlName="checkIn"
                            *ngIf="setCheckIn"
                            (ionBlur)="closeDateTimePicker()"
                            color="datetime-color"
                            minuteValues="0,30"
                        ></ion-datetime>
                    </ng-container>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="!checkInOnly" class="ion-justify-content-center ion-align-items-center ion-margin" style="border-bottom: 1px solid rgba(189, 189, 189, 0.404)">
                <ion-col size="4">Fine</ion-col>
                <ion-col size="4" class="ion-text-center ion-justify-content-center ion-align-items-center">
                    <div class="text-datetime-box">
                        <ion-text type="button" (click)="step()" [ngClass]="[stepIndex === 1 ? 'classColorSelected' : 'classColorUnselected']">
                            {{ userEditForm.value.checkOut | date : 'd/M/yy' }}
                        </ion-text>
                    </div>
                </ion-col>
                <ion-col size="4" class="margin-bottom ion-text-center">
                    <div class="text-datetime-box">
                        <ion-text
                            type="button"
                            (click)="changeTimesetCheckOut()"
                            class="text-datetime-box"
                            [ngClass]="[stepIndex === 1 ? 'classColorSelected' : 'classColorUnselected']"
                        >
                            {{ userEditForm.value.checkOut | date : 'H:mm' }}
                        </ion-text>
                    </div>
                    <ion-datetime
                        presentation="time"
                        formControlName="checkOut"
                        *ngIf="setcheckOut"
                        (ionBlur)="closeDateTimePicker()"
                        color="datetime-color"
                    ></ion-datetime>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center ion-align-items-center">
                <ion-col size="12">
                    <ion-datetime *ngIf="checkInOnly" [value]="checkIn" presentation="date" formControlName="checkIn" size="cover" [color]="theme"></ion-datetime>
                    <ng-container *ngIf="!checkInOnly" [ngSwitch]="stepIndex">
                        <ion-datetime *ngSwitchCase="0" presentation="date" formControlName="checkIn" size="cover" [color]="theme"></ion-datetime>
                        <ion-datetime *ngSwitchCase="1" presentation="date" formControlName="checkOut" size="cover" [color]="theme"></ion-datetime>
                    </ng-container>
                </ion-col>
            </ion-row>

            <ion-row class="ion-justify-content-center ion-align-items-center">
                <ion-col size="12">
                    <ion-button type="submit" size="large" expand="full" class="solid ion-padding" [disabled]="!userEditForm.valid" expand="full">
                        {{ 'button.confirm' | translate | uppercase }}
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>
