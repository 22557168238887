import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTimeRangeChooserModalComponent } from './date-time-range-chooser-modal/date-time-range-chooser-modal.component';
import { ModalController, Platform } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'date-time-range-chooser',
    templateUrl: './date-time-range-chooser.page.html',
    styleUrls: ['./date-time-range-chooser.page.scss']
})
export class DateTimeRangeChooserPage {
    @Input() theme: AppTheme;
    @Input() checkIn: string;
    @Input() checkOut: string;
    @Input() timeZone: string;
    @Output() changed = new EventEmitter<any>();

    constructor(public modalController: ModalController, private platformService: PlatformService) {}

    async addEditDateTime(): Promise<void> {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: DateTimeRangeChooserModalComponent,
            componentProps: {
                theme: this.theme,
                checkIn: this.checkIn,
                checkOut: this.checkOut,
                timeZone: this.timeZone
            },

            cssClass: 'date-time-range-chooser',
            breakpoints,
            initialBreakpoint
        });

        modal.onDidDismiss().then((result: { data: any }) => {
            if (result.data) this.changed.emit(result.data);
        });

        await modal.present();
    }
}
