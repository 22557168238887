<ion-grid [formGroup]="form" class="ion-no-margin ion-no-padding">
    <ion-row class="ion-text-start ion-no-margin ion-no-padding">
        <ion-col size="12">
            <ion-item
                mode="md"
                lines="none"
                detail="false"
                class="ion-no-margin ion-no-padding"
                [ngClass]="{
                    error: form.get(controlName).hasError('pasword') && (form.get(controlName).touched || form.get(controlName).dirty),
                    'ion-input-color-lili': theme === 'lili',
                    'ion-input-color-dark': theme !== 'lili' && !form.get(controlName).hasError('password') && !form.get(controlName).value,
                    'white-border-dark': theme !== 'lili' && !form.get(controlName).hasError('password') && form.get(controlName).value,
                    'white-border-lili': theme === 'lili' && !form.get(controlName).hasError('password') && form.get(controlName).value
                }"
                [ngStyle]="{
                    '--border-color': form.get(controlName).hasError('password')
                        ? 'red'
                        : !form.get(controlName).value
                        ? 'initial'
                        : form.get(controlName).valid
                        ? 'success'
                        : 'white'
                }"
            >
                <ion-label position="floating">
                    <ion-text class="text-input-roboto-regular-16" [ngClass]="'medium-' + theme">{{ label | translate }}</ion-text>
                </ion-label>
                <ion-input
                    mode="md"
                    class="text-input-roboto-regular-16"
                    [type]="type"
                    fill="outline"
                    [formControlName]="controlName"
                    [readonly]="readonly"
                    labelPlacement="floating"
                    placeholder="{{ placeholder | translate }}"
                    [pattern]="pattern"
                ></ion-input>
                <ion-text
                    style="font-size: small; text-decoration: underline; color: #ffffff; padding-top: 12px"
                    *ngIf="passwordIcon && !showPassword"
                    slot="end"
                    (click)="toggleShow()"
                    [color]="theme"
                >
                    {{ 'input.password.show' | translate }}
                </ion-text>
                <ion-text
                    style="font-size: small; text-decoration: underline; color: #ffffff; padding-top: 12px"
                    *ngIf="passwordIcon && showPassword"
                    slot="end"
                    (click)="toggleShow()"
                    [color]="theme"
                >
                    {{ 'input.password.hide' | translate }}
                </ion-text>
            </ion-item>
        </ion-col>
    </ion-row>
    <ion-row class="ion-text-start ion-no-margin ion-no-padding" style="margin-top: -13px">
        <ion-col size="12" class="ion-text-start ion-no-margin ion-no-padding">
            <sub class="message-input-roboto-bold-11 grey" 
            *ngIf="!form.get(controlName).touched">
                {{ 'password.request' | translate }}
            </sub>
            <sub
                class="message-input-roboto-bold-11 red"
                *ngIf="(form.get('password').errors && form.get(controlName).touched)"
            >
                {{ 'password.invalid' | translate }}
            </sub>
        </ion-col>
    </ion-row>
</ion-grid>
