<!-- Orologio di sistema -->

<ion-item class="ion-text-start" detail="false" lines="none">
    <ion-grid style="padding-right: 0; margin-right: 15px">
        <ion-row>
            <ion-col>
                <ion-text class="h2-roboto-medium-22">{{unitName}}</ion-text>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="9">
                <ion-text class="text-input-roboto-regular-16">{{dateTime.date}}</ion-text>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
                <status-badge [status]="unitOnline ? 'online' : 'offline'" [color]="unitOnline ? 'success' : 'danger'"></status-badge>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-text class="color">{{dateTime.time}}</ion-text>
            </ion-col>
            <ion-col size="12" style="padding-top: 0">
                <ion-text class="text-input-roboto-regular-16">{{unitTimeZone}}</ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>
