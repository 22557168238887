import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { UnitDiskDataModalComponent } from './unit-disk-data-modal/unit-disk-data-modal.component';
import { ModalController } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'unit-disk-data',
    templateUrl: './unit-disk-data.component.html',
    styleUrls: ['./unit-disk-data.component.scss']
})
export class UnitDiskDataComponent implements OnInit {
    @Input() theme: AppTheme;
    @Input() diskData: any;

    constructor(private platformService: PlatformService, public modalController: ModalController) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    openModal = async (): Promise<void> => {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: UnitDiskDataModalComponent,
            componentProps: {
                theme: this.theme,
                diskData: this.diskData
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();
    };
}
