<form [formGroup]="userEditForm">
    <ion-list lines="full" *ngIf="_role === 'member'">
        <ion-item ngClass="timeWindow" lines="none" detail="false" class="ion-no-margin ion-no-padding">
            <ion-icon name="time" slot="start" style="margin-left: -20px; width: 30px; height: 30px"></ion-icon>
            <ion-text class="text-input-roboto-regular-16-mobile" slot="start" style="margin-left: -15px">{{ 'user.timeWindowsEnable' | translate }}</ion-text>
            <ion-fab-button class="greyFab" (click)="addEditDateTime()" slot="end" size="small">
                <ion-icon name="add" size="small"></ion-icon>
            </ion-fab-button>
        </ion-item>

        <date-time-window
            *ngIf="timeWindowsEnable"
            (changed)="dateTimeWindowsChanged($event)"
            [theme]="theme"
            title="user.daysTimeWindows.label"
            [daysTimeWindows]="daysTimeWindows"
            [days]="days"
        ></date-time-window>
    </ion-list>
    <!-- Sezione registrazione. Visualizzaimo solo se l'utente appartiene al gruppo ospiti -->
    <ion-list lines="full" *ngIf="_role === 'guest'">
        <ion-item *ngIf="!timeWindowsEnable" ngClass="timeWindow" lines="none" detail="false" class="ion-no-margin ion-no-padding">
            <ion-icon name="time" slot="start" style="margin-left: -20px; width: 30px; height: 30px"></ion-icon>
            <ion-text class="text-input-roboto-regular-16-mobile" slot="start" style="margin-left: -15px">{{ titleWindowsEnable | translate }}</ion-text>
            <ion-fab-button class="greyFab" (click)="addEditTime()" slot="end" size="small">
                <ion-icon name="add" size="small"></ion-icon>
            </ion-fab-button>
        </ion-item>
        <date-time-range-chooser
            *ngIf="timeWindowsEnable"
            [theme]="theme"
            (changed)="dateTimeRangeChanged($event)"
            [checkIn]="checkIn"
            [checkOut]="checkOut"
            [timeZone]="timeZone"
        ></date-time-range-chooser>
    </ion-list>
</form>
