import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { UnitByUserEntity } from 'src/app/models/units.models';
import { PlatformService } from 'src/app/services/platform.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { AppTheme } from 'src/app/types/users.types';
import { VpnModalListComponent } from './vpn-modal-list/vpn-modal-list.component';

@Component({
    selector: 'vpn',
    templateUrl: './vpn.component.html',
    styleUrls: ['./vpn.component.scss']
})
export class VpnComponent implements OnInit {
    @Input() theme: AppTheme;
    @Input() unit: UnitByUserEntity;
    @Output() selected = new EventEmitter();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(public modalController: ModalController, private platformService: PlatformService, private socketioService: SocketioService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async openModal(event: Event): Promise<void> {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal1: HTMLIonModalElement = await this.modalController.create({
            component: VpnModalListComponent,
            componentProps: {
                theme: this.theme,
                unit: this.unit
            },
            breakpoints,
            initialBreakpoint
        });
        await modal1.present();
    }
}
