import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UsersService } from '../services/users.service';
import { map, skipWhile, tap } from 'rxjs/operators';
import { UnitsService } from '../services/units.service';
import { forkJoin } from 'rxjs';
import { HomeService } from '../services/home.service';
import { UserByUnitEntity } from '../models/users.models';
import { Role } from '../types/users.types';
import { AnomaliesService } from '../services/anomalies.service';

@Injectable({ providedIn: 'root' })
export class IndoorGuard implements CanActivate {
    private user: UserByUnitEntity;

    /*-------------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------------*/
    constructor(
        private authService: AuthService,
        private homeService: HomeService,
        private unitsService: UnitsService,
        private usersService: UsersService,
        private anonaliesUnitService: AnomaliesService,
        private router: Router
    ) {}

    /*-------------------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------------------------------------------------------*/
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //Carichiamo lo unitId salvato in sessione
        const userId = this.authService.getUserIdFromStorage();
        //Carichiamo lo unitId salvato in sessione
        const unitId = this.homeService.getUnitIdFromStorage();

        let obsUser = this.usersService.readIndoorUser(userId, unitId).pipe(
            map((user) => {
                //console.log('indoorUserInfo', user);
                //*********************
                //TODO Verificare che l'utente sia abilitato e nelle fasce orarie consentite
                //*********************
                this.user = user;
            })
        );
        let obsUnit = this.unitsService.loadIndoorUnitEntity(unitId, userId).pipe(
            map((unit) => {
                //console.log('indoorUnitInfo', unit);
                //*********************
                // TODO Verificare che la centrale sia online
                //*********************
            })
        );

        //Carichiamo le anomalie outdoor
        this.anonaliesUnitService.loadAnomaliesByUserList(userId).subscribe();
        //Carichiamo le anomalie indoor
        this.anonaliesUnitService.loadAnomaliesByUnitList(unitId).subscribe();

        //Attendiamo entrambi i dati
        return forkJoin([obsUnit, obsUser]).pipe(
            map(() => {
                console.log('RICEVUTI ENTRAMBI!', state.url);
                if (state.url == '/indoor') {
                    switch (this.user.role) {
                        case Role.service:
                            console.log('Yes! SERVICE: Redirect to checklist...');
                            //Fare redirezione in base al ruolo
                            // return this.router.parseUrl('/indoor/checklist');
                            return this.router.parseUrl('/indoor/panel');

                        case Role.owner:
                        case Role.member:
                        case Role.guest:
                            console.log('Yes! OWNER/MEMBER/GUEST: Redirect to panel...');
                            //Fare redirezione in base al ruolo
                            return this.router.parseUrl('/indoor/panel');
                    }
                }
            })
        );
    }
}
