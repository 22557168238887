import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { IonInfiniteScroll, Platform } from '@ionic/angular';
import { InfiniteScrollCustomEvent } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

import { Option } from '../../interfaces';
import { AppTheme } from 'src/app/types/users.types';
import { PlatformService } from 'src/app/services/platform.service';
import { TrackByComponent } from 'src/app/utilities/track-by.component';

@Component({
    selector: 'item-input-radio-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class ItemInputRadioGroupComponent extends TrackByComponent implements OnInit {
    private dataThreshold = 20;
    private dataInit = 0;
    public contentClass: string;
    public optionsGroup: Option[];
    public _options: Option[];
    public optionsInf: Option[] = [];
    public form: FormGroup;

    @Input() theme: AppTheme;
    @Input() optionValue: unknown;
    @Input() orderBy = 'name';
    @Input() set options(value: Option[]) {
        this.optionsGroup = value
            .map((option) => ({
                ...option,
                name: this.translate.instant(option.key),
                tags: option.tags?.map((tag) => ({
                    ...tag,
                    name: this.translate.instant(tag.key)
                }))
            }))
            .sort((a: Option, b: Option) => this.sortBy(a, b));

        this._options = [...this.optionsGroup];
        this.filterOptions('');
    }
    @Input() showSliding = false;
    @Input() set searchTerm(value: string) {
        if (value !== undefined) this.filterOptions(value);
    }
    @Input() formGroupName: string;
    @Input() controlName: string;

    @Output() radioGroupChange$ = new EventEmitter<Option>();
    @Output() swipeStart$ = new EventEmitter<unknown>();
    @Output() swipeEnd$ = new EventEmitter<unknown>();

    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

    constructor(private translate: TranslateService, private platformService: PlatformService, private rootFormGroup: FormGroupDirective) {
        super();
        if (this.platformService.isSmartphone()) {
            this.contentClass = 'selectMobile';
        }
        this.translate.onLangChange.subscribe({
            next: () => {
                this.optionsGroup = this.optionsGroup
                    .map((option) => ({
                        ...option,
                        name: this.translate.instant(option.key),
                        tags: option.tags?.map((tag) => ({
                            ...tag,
                            name: this.translate.instant(tag.key)
                        }))
                    }))
                    .sort((a: Option, b: Option) => this.sortBy(a, b));

                this._options = [...this.optionsGroup];
            }
        });
    }

    private sortBy = (a: Option, b: Option): number => {
        if (typeof a[this.orderBy] === 'string') {
            if (a[this.orderBy].toString().toLowerCase() > b[this.orderBy].toString().toLowerCase()) return 1;
            else return -1;
        } else {
            if (a[this.orderBy] > b[this.orderBy]) return 1;
            else return -1;
        }
    };

    public ngOnInit(): void {
        if (this.controlName)
            this.form = this.formGroupName ? (this.rootFormGroup.control.get(this.formGroupName) as FormGroup) : (this.form = this.rootFormGroup.control);

        this._options = this.optionsGroup;
        this.getOptions();
    }

    ngAfterViewInit(): void {
        if (this.optionsGroup.length <= this.dataThreshold) this.infiniteScroll.disabled = true;
    }

    radioGroupChange(option: Option): void {
        if (this.controlName && this.form.controls[this.controlName].updateOn !== 'submit') {
            this.form.controls[this.controlName].markAsDirty();
            this.form.controls[this.controlName].patchValue(option.value);
        }
        if (!option.disabled) {
            if (this.optionValue === option.value) {
                this.optionValue = null;
                this.radioGroupChange$.emit({
                    ...option,
                    value: null
                });
            } else {
                this.optionValue = option.value;
                this.radioGroupChange$.emit(option);
            }
        }
    }

    private filterOptions(searchTerm: string): void {
        // Filtriamo quanto digitato
        this.optionsGroup = this._options?.filter(
            (option) => option.name.toLowerCase().includes(searchTerm) || option.tags?.some((tag) => tag.name.toLowerCase().includes(searchTerm))
        );

        this.optionsInf = [];
        this.dataInit = 0;
        this.getOptions();
    }

    private getOptions(): void {
        let dataEnd = this.dataInit + this.dataThreshold;
        if (this.dataInit + this.dataThreshold >= this.optionsGroup.length) {
            dataEnd = this.optionsGroup.length;
        }

        for (let i = this.dataInit; i < dataEnd; i++) this.optionsInf.push(this.optionsGroup[i]);

        this.dataInit += this.dataThreshold;
    }

    loadData(event: Event): void {
        this.getOptions();

        (event as InfiniteScrollCustomEvent).target.complete();

        // disable the infinite scroll
        if (this.optionsInf.length === this.optionsGroup.length) (event as InfiniteScrollCustomEvent).target.disabled = true;
    }

    swipeEnd(value: unknown): void {
        this.swipeEnd$.emit(value);
    }

    swipeStart(valueSwipeStart: unknown): void {
        this.swipeStart$.emit(valueSwipeStart);
    }
}
