<ion-card [ngStyle]="{'background-image': backgroundGrid, 'background-size': 'cover', 'background-repeat': 'no-repeat'}" class="center-item">
    <!-- <ion-img *ngIf="imageDataUrl" [src]="imageDataUrl"></ion-img>
      -->
    <div [ngClass]="'background-blur-'+theme" [ngStyle]="{'background-image': 'url(' + imageDataUrl + ')'}"></div>
    <ion-grid class="ion-padding unit-avatar-grid">
        <ion-row style="height: 60%">
            <ion-col size="12"></ion-col>
        </ion-row>
        <ion-row style="height: 40%" class="ion-align-items-end">
            <ion-col size="12">
                <ion-text [class]="className" class="theme-text">{{unitName }}</ion-text>

                <ion-item detail="false" lines="none" class="ion-no-margin ion-no-padding">
                    <ion-button slot="start" fill="clear" (click)="unitEdit()" class="ion-no-margin ion-no-padding ion-text-start">
                        <ion-text *ngIf="role =='service' || role === 'owner'" style="text-decoration: underline" [class]="classButton">
                            {{'unit.list.edit' | translate}}
                        </ion-text>
                    </ion-button>
                    <ion-button slot="end" fill="clear" (click)="unitIndoorExit()" class="ion-no-margin ion-no-padding ion-text-end">
                        <ion-icon class="color" size="small" name="log-out" style="margin-right: 4px"></ion-icon>
                        <ion-text style="text-decoration: underline" [class]="classButton">{{'menu.exit.unit' | translate }}</ion-text>
                    </ion-button>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card>
