import { DaysTimeWindow, Language, Role } from '../types/users.types';

export type UserLoginByEmailEntity = {
    email: string;
    userId: string;
    loginAttempts: number;
};

export interface UserLoginByEmailInsert extends Partial<UserLoginByEmailEntity> {
    email: string;
}
export interface TwoFAVerificationCode {
    verificationCode: string;
}
export interface TwoFALogin {
    verificationCode: string;
    apiKey: string;
    phoneNumber: string;
}

export interface VerifyUserRegistration {
    firstName: string;
    email: string;
    language: Language;
    apiKey: string;
}

export interface TwoFARegister {
    verificationCode: string;
    apiKey: string;
    phoneNumber: string;
}

export interface TwoFAVerificationCode {
    verificationId: string;
    phoneNumber: string;
    apiKey: string;
}

export declare enum TokenJob {
    registerRequest = 'REGISTER_REQUEST',
    restoreRequest = 'RESTORE_REQUEST'
}
export type UserByTokenEntity = {
    registrationToken: string;
    job: TokenJob;
    email: string;
    firstName: string;
    language: Language;
    role: Role;
    loginRemember: boolean;
    password: string;
    phoneNumber: string;
    redirect: string;
    policy: boolean;
};
export type EmailResetPassword = {
    apiKey: string;
    secret: string;
};

export type UserByTokenData = Omit<Partial<UserByTokenEntity>, 'registrationToken'>;

export type ResetPasswordResult = {
    apiKey: string;
    verificationId: string;
    phoneNumber: string;
};

export enum WelcomeJob {
    REGISTER_REQUEST = 'REGISTER_REQUEST',
    RESTORE_REQUEST = 'RESTORE_REQUEST',
    GOOGLE_SIGNIN = 'GOOGLE_SIGNIN',
    APPLE_SIGNIN = 'APPLE_SIGNIN',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    LILITECH_LOGIN = 'LILITECH_LOGIN',
    INVITE_USER = 'INVITE_USER',
    ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
    VERIFY_SMS = 'VERIFY_SMS'
}

export type UserByWelcomeEntity = {
    apiKey: string;
    userId: string;
    job: WelcomeJob;
    email: string;
    firstName: string;
    lastName: string;
    language: Language;
    image: string;
    role: Role;
    viewId: string;
    viewName: string;
    loginRemember: boolean;
    password: string;
    phoneNumber: string;
    redirect: string;
    policy: boolean;
    date: Date;
    loginWelcome: string;
    social: string;
    secret: string;
    totSmsRequest: number;
    scout: string;
    unitId: string;
    inviterId: string;
    enable: boolean;
    enableManual: boolean;
    timeWindowsEnable: boolean;
    daysTimeWindows: Array<DaysTimeWindow>;
    checkIn: string;
    checkOut: string;
};
export type UserByWelcomeData = Partial<UserByWelcomeEntity>;
