import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type MenuItem = {
    title: string;
    icon: string;
    url: string;
    color?: string;
    textColor?: string;
};

export type MenuRight = {
    outdoor: {
        adminItems: Array<MenuItem>;
        supportItems: Array<MenuItem>;
        customerItems: Array<MenuItem>;
    };
    indoor: {
        serviceItems: Array<MenuItem>;
        ownerItems: Array<MenuItem>;
        memberItems: Array<MenuItem>;
        guestItems: Array<MenuItem>;
    };
};

@Injectable({ providedIn: 'root' })
export class MenuRightService {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor() {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    get menu(): MenuRight {
        return {
            // I menu in modalità outdoor
            outdoor: {
                adminItems: [
                    {
                        title: 'menu.title.notification',
                        url: '/outdoor/notifications/list',
                        icon: 'notifications'
                    },
                    // {
                    //     title: 'menu.title.anomalies',
                    //     icon: 'warning-outline',
                    //     url: '/outdoor/anomalies'
                    // },
                    {
                        title: 'menu.title.history',
                        url: '/outdoor/history',
                        icon: 'file-tray-full-outline'
                    },
                    // {
                    //     title: 'menu.title.about',
                    //     icon: 'information',
                    //     url: '/outdoor/about'
                    // },
                    {
                        title: 'menu.title.help',
                        icon: 'help-circle-outline',
                        url: '/outdoor/help'
                    },
                    // {
                    //     title: 'menu.title.guide',
                    //     icon: 'map',
                    //     url: '/outdoor/guide'
                    // },
                    {
                        title: 'Debug',
                        icon: 'bug',
                        url: '/outdoor/debug'
                    }
                ],
                supportItems: [
                    {
                        title: 'menu.title.notification',
                        url: '/outdoor/notifications/list',
                        icon: 'notifications'
                    },
                    // {
                    //     title: 'menu.title.anomalies',
                    //     icon: 'warning-outline',
                    //     url: '/outdoor/anomalies'
                    // },
                    {
                        title: 'menu.title.history',
                        url: '/outdoor/history',
                        icon: 'file-tray-full-outline'
                    },
                    // {
                    //     title: 'menu.title.about',
                    //     icon: 'information',
                    //     url: '/outdoor/about'
                    // },
                    {
                        title: 'menu.title.help',
                        icon: 'help-circle-outline',
                        url: '/outdoor/help'
                    }
                    // {
                    //     title: 'menu.title.guide',
                    //     icon: 'map',
                    //     url: '/outdoor/guide'
                    // }
                ],
                customerItems: [
                    {
                        title: 'menu.title.notification',
                        url: '/outdoor/notifications/list',
                        icon: 'notifications'
                    },
                    {
                        title: 'menu.title.anomalies',
                        icon: 'warning-outline',
                        url: '/outdoor/anomalies'
                    },
                    {
                        title: 'menu.title.history',
                        url: '/outdoor/history',
                        icon: 'file-tray-full-outline'
                    },
                    // {
                    //     title: 'menu.title.about',
                    //     icon: 'information',
                    //     url: '/outdoor/about'
                    // },
                    {
                        title: 'menu.title.help',
                        icon: 'help-circle-outline',
                        url: '/outdoor/help'
                    }
                    // {
                    //     title: 'menu.title.guide',
                    //     icon: 'map',
                    //     url: '/outdoor/guide'
                    // }
                    // {
                    //     title: 'menu.title.plugins',
                    //     icon: 'options',
                    //     url: '/outdoor/plugins'
                    // }
                ]
            },
            // I menu in modalità indoor
            indoor: {
                serviceItems: [
                    {
                        title: 'menu.title.notification',
                        url: '/indoor/notifications/list',
                        icon: 'notifications'
                    },
                    {
                        title: 'menu.title.anomalies',
                        icon: 'warning-outline',
                        url: '/indoor/anomalies'
                    },
                    {
                        title: 'menu.title.history',
                        url: '/indoor/history',
                        icon: 'file-tray-full-outline'
                    },
                    // {
                    //     title: 'menu.title.payments',
                    //     url: '/indoor/payments',
                    //     icon: 'card-outline'
                    // },
                    {
                        title: 'menu.title.help',
                        icon: 'help-circle-outline',
                        url: '/indoor/help'
                    },
                    {
                        title: 'Debug',
                        icon: 'bug',
                        url: '/indoor/debug'
                    }
                ],
                ownerItems: [
                    {
                        title: 'menu.title.notification',
                        url: '/indoor/notifications/list',
                        icon: 'notifications'
                    },
                    {
                        title: 'menu.title.anomalies',
                        icon: 'warning-outline',
                        url: '/indoor/anomalies'
                    },
                    {
                        title: 'menu.title.history',
                        url: '/indoor/history',
                        icon: 'file-tray-full-outline'
                    },
                    // {
                    //     title: 'menu.title.payments',
                    //     url: '/indoor/payments',
                    //     icon: 'card-outline'
                    // },
                    {
                        title: 'menu.title.help',
                        icon: 'help-circle-outline',
                        url: '/indoor/help'
                    }
                ],
                memberItems: [
                    {
                        title: 'menu.title.notification',
                        url: '/indoor/notifications/list',
                        icon: 'notifications'
                    },
                    {
                        title: 'menu.title.history',
                        url: '/indoor/history',
                        icon: 'file-tray-full-outline'
                    },
                    {
                        title: 'menu.title.help',
                        icon: 'help-circle-outline',
                        url: '/indoor/help'
                    }
                ],
                guestItems: [
                    {
                        title: 'menu.title.notification',
                        url: '/indoor/notifications/list',
                        icon: 'notifications'
                    },
                    {
                        title: 'menu.title.help',
                        icon: 'help-circle-outline',
                        url: '/indoor/help'
                    }
                ]
            }
        };
    }
}
