import { Component, Input, OnInit } from '@angular/core';
import { Subscription, map, share, timer } from 'rxjs';
import { AppTheme, Language } from 'src/app/types/users.types';
interface DateTimeFormatOptions {
    localeMatcher?: 'best fit' | 'lookup' | undefined;
    weekday?: 'long' | 'short' | 'narrow' | undefined;
    era?: 'long' | 'short' | 'narrow' | undefined;
    year?: 'numeric' | '2-digit' | undefined;
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
    day?: 'numeric' | '2-digit' | undefined;
    hour?: 'numeric' | '2-digit' | undefined;
    minute?: 'numeric' | '2-digit' | undefined;
    second?: 'numeric' | '2-digit' | undefined;
    timeZoneName?: 'long' | 'short' | undefined;
    formatMatcher?: 'best fit' | 'basic' | undefined;
    hour12?: boolean | undefined;
    timeZone?: string | undefined;
}

interface DateTime {
    date: string;
    time: string;
}
@Component({
    selector: 'clock',
    templateUrl: './clock.page.html',
    styleUrls: ['./clock.page.scss']
})
export class ClockPage implements OnInit {
    private unitLanguage: Language;
    private unitHour12: boolean;
    private subscription: Subscription;

    public dateTime: DateTime;
    public unitTimeZone: string;
    @Input() unitName: string;
    @Input() theme: AppTheme;
    @Input() unitOnline: boolean;
    @Input() set timeZone(value: string) {
        if (value) {
            this.unitTimeZone = value;
            this.dateTime = this.setTime();
        }
    }

    @Input() set language(value: Language) {
        if (value) {
            this.unitLanguage = value;
            this.dateTime = this.setTime();
        }
    }

    @Input() set hour12(value: boolean) {
        this.unitHour12 = value;
        this.dateTime = this.setTime();
    }

    ngOnInit(): void {
        this.subscription = timer(0, 30000)
            .pipe(
                map(() => this.setTime()),
                share()
            )
            .subscribe((dateTime) => {
                this.dateTime = dateTime;
            });
    }

    private setTime = (): DateTime => {
        const dateOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: this.unitTimeZone
        };
        const date = new Date(Date.now()).toLocaleDateString(this.unitLanguage, dateOptions as DateTimeFormatOptions);

        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: this.unitHour12, timeZone: this.unitTimeZone };
        const time = new Date(Date.now()).toLocaleTimeString(this.unitLanguage, timeOptions as DateTimeFormatOptions);

        return { date, time };
    };

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
