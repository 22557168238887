<ion-content>
    <item-section-header [theme]="theme" icon="keypad" [title]="'change-coordinates.title'"></item-section-header>
    <div id="unitMap"></div>
    <ion-button class="confirmModal" [color]="theme" (click)="cancelChangeCoordinates()">
        {{ 'button.cancel' | translate }}
    </ion-button>
    <ion-button class="dismissModal" [color]="theme" (click)="confirmChangeCoordinates()">
        {{ 'button.confirm' | translate }}
    </ion-button>
</ion-content>
