import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ItemSectionHeaderModule } from '../../shared/item-section-header/item-section-header.module';
import { ItemInputsModule } from '../../shared/item-inputs/item-inputs.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { HttpClient } from '@angular/common/http';

import { VpnModalListComponent } from './vpn-modal-list/vpn-modal-list.component';
import { VpnItemComponent } from './vpn-item/vpn-item.component';
import { VpnModalAddComponent } from './vpn-modal-add/vpn-modal-add.component';
import { VpnComponent } from './vpn.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        ItemSectionHeaderModule,
        ItemInputsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [VpnComponent, VpnModalListComponent, VpnItemComponent, VpnModalAddComponent],
    exports: [VpnComponent, VpnModalListComponent, VpnItemComponent, VpnModalAddComponent]
})
export class VpnModule {}
