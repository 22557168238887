<ion-content>
    <item-section-header [title]="'unit.firmware-update.modal.title'" (closeModal$)="closeModal()" [iconClose]="true"></item-section-header>
    <ion-list class="input-padding">
        <ion-item
            button
            lines="full"
            class="row-container"
            detail="false"
            (click)="updateFirmware(item)"
            *ngFor="let item of firmwares; let i = index; trackBy: trackVersions"
        >
            <ion-grid fixed>
                <ion-row class="ion-no-padding ion-no-margin ion-align-items-center">
                    <ion-col size="7">
                        <div class="divContainer">
                            <ion-text>{{ 'unit.firmware-version.description' | translate }} {{ item.version }}</ion-text>
                        </div>
                    </ion-col>
                    <ion-col size="4" class="colFlex">
                        <status-badge *ngIf="item.newer" [status]="'unit.firmware-version.new-version'" [color]="'primary'"></status-badge>
                        <status-badge *ngIf="item.active" [status]="'unit.firmware-version.actual-version'" [color]="'success'"></status-badge>
                    </ion-col>

                    <ion-col size="1" class="colFlex">
                        <ion-icon *ngIf="!item.active" size="small" name="chevron-forward"></ion-icon>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="item.status !== 'idle'" class="ion-align-items-start">
                    <ion-col size="12">
                        <ion-item class="ion-no-margin ion-no-padding" lines="none">
                            <ion-spinner name="lines-small" class="spinner"></ion-spinner>
                            <ion-text class="boot-text notification-title-bold-13">
                                {{ 'unit.firmware-update.' + item.status | translate }}
                                <span>
                                    <br *ngIf="platformService.isSmartphone()" />
                                    {{ item.progress }}%
                                </span>
                            </ion-text>
                        </ion-item>

                        <ion-progress-bar [buffer]="item.progress / 100 + 0.1" [value]="item.progress / 100"></ion-progress-bar>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
        <div style="margin-top: 16px">
            <ion-button (click)="closeModal()" class="solid" expand="block">
                {{ 'button.close' | translate }}
            </ion-button>
        </div>
    </ion-list>
</ion-content>
