<ion-item
    [detail]="!isReadOnly"
    lines="full"
    class="ion-no-margin ion-no-padding"
    [disabled]="isReadOnly"
    mode="ios"
    [button]="!isReadOnly"
    (click)="!isReadOnly && chooseLanguage()"
>
    <ion-label class="ion-no-margin ion-no-padding">
        <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="'medium-'+theme">
            {{ 'language-chooser.label' | translate }}
        </ion-text>
        <ion-input class="text-input-roboto-regular-16 ion-no-margin ion-no-padding" type="text" [value]="showValue | translate" [readonly]="true"></ion-input>
    </ion-label>
</ion-item>
