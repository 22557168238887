import { Component, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme, DaysTimeWindow } from 'src/app/types/users.types';
import { UtilitiesComponent } from 'src/app/utilities/utilities.component';

@Component({
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class DateTimeWindowChooserModalComponent extends UtilitiesComponent {
    @Input() theme: AppTheme;
    @Input() daysTimeWindow: DaysTimeWindow;

    public contentClass: string;
    constructor(public modalController: ModalController, private platformService: PlatformService) {
        super();
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
    }

    submitRequest = async (daysTimeWindow: DaysTimeWindow): Promise<void> => {
        await this.modalController.dismiss(daysTimeWindow);
    };
}
