import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'src/app/services/application.service';
import { Option } from '../../interfaces';
import { getUpdatedValues, validateAtLestOneChecked } from 'src/app/utilities/utilities';
import { AppTheme, DaysTimeWindow } from 'src/app/types/users.types';
import { UtilitiesComponent } from 'src/app/utilities/utilities.component';

@Component({
    selector: 'date-time-window-chooser',
    templateUrl: './chooser.component.html',
    styleUrls: ['./chooser.component.scss']
})
export class DateTimeWindowChooserComponent extends UtilitiesComponent {
    @Input() theme: AppTheme;
    @Input() daysTimeWindow: DaysTimeWindow;
    @Input() controls: string[];
    @Output() $submitRequest = new EventEmitter<DaysTimeWindow>();
    @Output() $cancel = new EventEmitter();
    public contentClass: string;
    public days: Option[];

    //Validation
    public isDayValid = false;
    public isRangeValid = false;
    public isFormValid = false;

    public stepIndex = 0;

    public userEditForm: FormGroup;
    public daysSelectedButton: boolean[] = [];
    public fullDay: boolean = true;
    constructor(private appService: AppService) {
        super();
        //ho creato questa variabile di modo da avere un orario che parte già avanti di un'ora
        const currentDateTime = new Date();
        currentDateTime.setHours(currentDateTime.getHours() + 1);
        this.userEditForm = new FormGroup({
            days: new UntypedFormArray(
                [
                    new FormControl(null),
                    new FormControl(null),
                    new FormControl(null),
                    new FormControl(null),
                    new FormControl(null),
                    new FormControl(null),
                    new FormControl(null)
                ],
                { validators: validateAtLestOneChecked, updateOn: 'change' }
            ),
            allday: new FormControl(true, { updateOn: 'change' }),
            start: new FormControl(
                new Date().toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                })
            ),
            end: new FormControl(
                currentDateTime.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                })
            )
        });
        this.days = this.appService.days;
    }
    ngOnDestroy(): void {
        this.isFormValid = false;
        this.isRangeValid = false;
        this.isDayValid = false;
    }

    ngOnInit(): void {
        if (this.daysTimeWindow) {
            this.fullDay = this.daysTimeWindow.allday;
            this.daysSelected = this.daysTimeWindow.days;
            this.userEditForm.patchValue(this.daysTimeWindow);
            for (let i = 0; i < this.days.length; i++) {
                if (this.daysTimeWindow['days'][i] !== null) {
                    this.days[i].selected = this.daysTimeWindow['days'][i];
                }
            }
        }
    }
    submitRequest = (): void => {
        this.userEditForm.controls.days.setValue(this.daysSelected);
        this.$submitRequest.emit(this.userEditForm.value);
    };
    cancel = (): void => this.$cancel.emit();

    public checkingValidation() {
        if (
            this.userEditForm.controls.end.value > this.userEditForm.controls.start.value ||
            this.userEditForm.controls.end.value === this.userEditForm.controls.start.value
        ) {
            this.isRangeValid = true;
        } else {
            this.isRangeValid = false;
        }

        if (
            (this.isDayValid && this.userEditForm.controls.allday.value) ||
            (this.isDayValid && !this.userEditForm.controls.allday.value && this.isRangeValid)
        ) {
            this.isFormValid = true;
        } else {
            this.isFormValid = false;
        }
    }

    toggleDaySelection(day: any): void {
        if (day > -1) {
            // Verifico se il giorno è già selezionato
            const isSelected = this.daysSelected[day];
            if (isSelected) {
                // se il giorno è già selezionato lo deseleziono
                const updatedDaysSelected = [...this.daysSelected]; // Creo una copia dell'array this.daysSelected (questo lo devo fare perchè gli elementi dentro un oggetto sono di sola lettura)
                updatedDaysSelected[day] = false; // Modifico la copia dell'array
                this.daysSelected = updatedDaysSelected; // Assegno la copia modificata all'originale
            } else {
                // Il giorno non è selezionato, quindi seleziono
                const updatedDaysSelected = [...this.daysSelected]; // Creo una copia dell'array this.daysSelected
                updatedDaysSelected[day] = true; // Modifico la copia dell'array
                this.daysSelected = updatedDaysSelected; // Assegno la copia modificata all'originale
            }
            this.isDayValid = this.daysSelected.some((v) => v === true);
        }
        this.checkingValidation();
    }
    public step(stepIndex: number): void {
        this.stepIndex = stepIndex;
    }

    isSelected(index: number): boolean {
        return this.stepIndex === index;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async toggleFullDay(event: boolean) {
        this.userEditForm.controls.allday.setValue(event);
        this.fullDay = event;

        this.checkingValidation();
    }
}
