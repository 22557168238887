import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClockPage } from './clock.page';
import { StatusBadgeModule } from '../status-badge/status-badge.module';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, StatusBadgeModule],
    declarations: [ClockPage],
    exports: [ClockPage]
})
export class ClockModule {}
