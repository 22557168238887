import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme, Group } from 'src/app/types/users.types';
import { UtilitiesComponent } from 'src/app/utilities/utilities.component';

@Component({
    selector: 'change-userdata-modal',
    templateUrl: './component.html',
    styleUrls: ['./component.scss']
})
export class ChangeUserdataModalComponent extends UtilitiesComponent implements OnInit {
    //questo componente è fatto a immagine e somiglianza con il tuo change-credentials ma il tuo non cancellarlo che lo uso da esempio
    @Input() theme: AppTheme;
    @Input() controlName: string;
    @Input() firstNameLabel: string;
    @Input() title: string;
    @Input() field: string;
    @Input() userId: string;
    @Input() userGroup: Group;

    public control: boolean;
    public contentClass: string;

    public get Group(): typeof Group {
        return Group;
    }

    editForm: FormGroup;

    constructor(public modalController: ModalController, private platformService: PlatformService) {
        super();
        if (this.platformService.isDesktop()) this.contentClass = 'modal-layout';
        this.editForm = new FormGroup({
            controlName: new FormControl(null, { validators: Validators.required })
        });
    }

    ngOnInit(): void {
        this.editForm.controls['controlName'].patchValue(this.field);
    }

    submitRequest = async (): Promise<void> => {
        await this.modalController.dismiss(this.editForm.value);
    };
}
