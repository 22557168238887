<ion-app class="mostly-customized-scrollbar">
    <ion-split-pane [when]="'(min-width: 1440px)'" contentId="mainContent" class="mostly-customized-scrollbar">
        <!-- Menù di sinistra -->
        <ion-menu
            class="mostly-customized-scrollbar"
            menuId="pages"
            [disabled]="!loggedIn"
            contentId="mainContent"
            side="start"
            type="overlay"
            (ionDidOpen)="ionDidOpen($event)"
            (ionDidClose)="ionDidClose()"
        >
            <ng-container *ngIf="loggedIn">
                <!-- Contenuto del menu di sinistra -->

                <ion-content [class]="'menu-bg-' + theme" class="mostly-customized-scrollbar">
                    <div *ngIf="platformService.isDesktop()" class="logo-desktop">
                        <ion-img *ngIf="theme === 'dark'" src="assets\logos\logo-lilitech-dark.svg"></ion-img>
                        <ion-img *ngIf="theme === 'lili'" src="assets\logos\logo-lilitech.svg"></ion-img>
                    </div>
                    <div *ngIf="platformService.isSmartphone() || platformService.isTablet()">
                        <!-- <ion-img src="assets\logos\logo-lilitech-invisible.svg"></ion-img> -->
                        <ion-item class="ion-margin-top" detail="false" lines="none" *ngIf="platformService.isSmartphone() || platformService.isTablet()">
                            <!-- <ion-text class="message-input-roboto-bold-11" [ngClass]="'medium-' + theme">
                                {{ 'menu.label.swVersion' | translate }} {{ swVersion }}
                            </ion-text> -->
                            <ion-icon slot="end" name="close" [color]="theme" type="button" (click)="close()"></ion-icon>
                        </ion-item>
                    </div>

                    <!-- Bottone di chiusura menu -->

                    <app-menu-left (click)="close()"></app-menu-left>
                </ion-content>

                <!-- Bottone di logout -->
                <ion-menu-toggle menu="pages" auto-hide="false">
                    <ion-item button class="h3" lines="none" detail="false" (click)="logout()" [class]="'menu-bg-' + theme">
                        <ion-icon class="itemClass" style="padding-right: 0; margin-right: 15px" size="small" slot="start" name="log-out"></ion-icon>
                        <ion-text class="itemClass">{{ 'menu.logout' | translate }}</ion-text>
                    </ion-item>
                </ion-menu-toggle>
            </ng-container>
        </ion-menu>

        <!-- Menù di destra -->
        <ion-menu
            menuId="monitor"
            [disabled]="!loggedIn"
            contentId="mainContent"
            side="end"
            type="overlay"
            (ionDidOpen)="ionDidOpen($event)"
            class="menu-bg"
            (ionDidClose)="ionDidClose()"
        >
            <ng-container *ngIf="loggedIn">
                <!-- Contenuto del menu di destra -->
                <ion-content [class]="'menu-bg-' + theme">
                    <ion-item button detail="false" lines="none" *ngIf="platformService.isSmartphone() || platformService.isTablet()">
                        <ion-icon slot="end" name="close" [color]="theme" type="button" (click)="close()"></ion-icon>
                    </ion-item>
                    <!-- Bottone di chiusura menu -->
                    <app-menu-right (click)="close()"></app-menu-right>
                </ion-content>

                <ion-menu-toggle menu="pages" auto-hide="false">
                    <ion-item class="h3" lines="none" detail="false" [class]="'menu-bg-' + theme">
                        <ion-text class="message-input-roboto-bold-11" [ngClass]="'medium-' + theme">
                            {{ 'menu.label.swVersion' | translate }} {{ swVersion }}
                        </ion-text>
                    </ion-item>
                </ion-menu-toggle>
            </ng-container>
        </ion-menu>

        <!-- Contenuto principale -->
        <ion-router-outlet id="mainContent"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
