import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserEntity } from 'src/app/models/users.models';
import { UsersService } from 'src/app/services/users.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
    public group: string;
    public image: string;

    public theme: AppTheme;
    public firstName: string;
    public lastName: string;
    public imageDataUrl: string;

    @Input()
    set user(value: UserEntity) {
        if (value) {
            const { userId, theme, firstName, lastName, group } = value;
            this.theme = theme;
            this.firstName = firstName;
            this.lastName = lastName;

            //Scarichiamo l'immagine dell'utente
            this.usersService.userDownloadImage(userId).subscribe((image) => {
                if (image) {
                    const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => (this.imageDataUrl = <string>reader.result);
                } else {
                    this.imageDataUrl = 'assets/backgrounds/user-default.webp';
                }
            });

            this.group = group;
        }
    }
    @Output()
    userEdit$ = new EventEmitter();

    userEdit(): void {
        this.userEdit$.emit();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private usersService: UsersService) {}
}
