<ion-item lines="full" detail="true" button style="padding: 0" (click)="userEdit()">
    <div style="padding: 0 8px 0 0">
        <ion-thumbnail slot="start">
            <ion-img *ngIf="imageDataUrl" [src]="imageDataUrl" alt="imageDataUrl"></ion-img>
        </ion-thumbnail>
    </div>
    <ion-label>
        <ion-text class="title-header-roboto-bold-17">{{ firstName }} {{ lastName }}</ion-text>
        <br />
        <ion-text class="button-text-small-roboto-regular-14 primary-dark">{{ group | uppercase }}</ion-text>
    </ion-label>
</ion-item>
