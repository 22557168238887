import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DateTimeRangeChooserPage } from './date-time-range-chooser.page';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { DateTimeRangeChooserModalComponent } from './date-time-range-chooser-modal/date-time-range-chooser-modal.component';
import { ItemSectionHeaderModule } from '../../item-section-header/item-section-header.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        ItemSectionHeaderModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [DateTimeRangeChooserPage, DateTimeRangeChooserModalComponent],
    exports: [DateTimeRangeChooserPage, DateTimeRangeChooserModalComponent]
})
export class DateTimeRangeChooserModule {}
