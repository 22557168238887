import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';
import { LanguageChooserModalComponent } from './language-chooser-modal/language-chooser-modal.component';
import { LanguageEntity, LanguageService } from 'src/app/services/language.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'language-chooser',
    templateUrl: './language-chooser.page.html',
    styleUrls: ['./language-chooser.page.scss']
})
export class LanguageChooserPage implements OnInit {
    public showValue: string;
    public languages: LanguageEntity[];

    @Input() theme: AppTheme;
    @Input() isReadOnly: boolean = false;
    @Input() set value(value: string) {
        const format = this.languages.find((f) => f.value === value);
        this.showValue = format.key;
    }

    @Output() selected = new EventEmitter<string>();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private platformService: PlatformService, private languageService: LanguageService, public modalController: ModalController) {
        this.languages = this.languageService.languages;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async chooseLanguage(): Promise<void> {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: LanguageChooserModalComponent,
            componentProps: {
                theme: this.theme,
                languages: this.languages,
                showValue: this.showValue
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();
        const res = await modal.onWillDismiss();

        if (res.data) {
            this.showValue = res.data.key;
            this.selected.emit(res.data);
        }
    }
}
