import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'app-unit-disk-data-modal',
    templateUrl: './unit-disk-data-modal.component.html',
    styleUrls: ['./unit-disk-data-modal.component.scss']
})
export class UnitDiskDataModalComponent implements OnInit {
    public contentClass: string;
    @Input() theme: AppTheme;
    @Input() diskData: any[] = [];
    constructor(private modalController: ModalController) {}

    ngOnInit() {}
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    closeModal() {
        this.modalController.dismiss();
    }
    getKey(obj: any): string {
        return Object.keys(obj)[0];
    }
    getValue(obj: any) {
        return Object.values(obj)[0];
    }
}
