import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LanguageChooserPage } from './language-chooser.page';
import { ItemSectionHeaderModule } from '../../item-section-header/item-section-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { HttpClient } from '@angular/common/http';
import { LanguageChooserModalComponent } from './language-chooser-modal/language-chooser-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        ItemSectionHeaderModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [LanguageChooserPage, LanguageChooserModalComponent],
    exports: [LanguageChooserPage, LanguageChooserModalComponent]
})
export class LanguageChooserPageModule {}
