import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ItemInputTextComponent } from './item-input-text/item-input-text.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { ItemInputModalComponent } from './item-input-modal/item-input-modal.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ItemInputToggleComponent } from './item-input-toggle/item-input-toggle.component';
import { ItemInputEmailComponent } from './item-input-email/item-input-email.component';
import { ItemInputNumberComponent } from './item-input-number/item-input-number.component';
import { ItemInputTimerPickerComponent } from './item-input-timer-picker/component';
import { ItemInputPasswordComponent } from './item-input-password/item-input-password.component';
import { ItemInputCheckboxComponent } from './item-input-checkbox/item-input-checkbox.component';
import { ItemInputTextNFComponent } from './item-input-text-nf/item-input-text.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        ItemInputTextComponent,
        ItemInputTimerPickerComponent,
        ItemInputNumberComponent,
        ItemInputModalComponent,
        ItemInputToggleComponent,
        ItemInputEmailComponent,
        ItemInputPasswordComponent,
        ItemInputCheckboxComponent,
        ItemInputTextNFComponent
    ],
    exports: [
        ItemInputTextComponent,
        ItemInputTimerPickerComponent,
        ItemInputNumberComponent,
        ItemInputModalComponent,
        ItemInputToggleComponent,
        ItemInputEmailComponent,
        ItemInputPasswordComponent,
        ItemInputCheckboxComponent,
        ItemInputTextNFComponent
    ]
})
export class ItemInputsModule {}
