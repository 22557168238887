import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';
import { BackendService } from './backend.service';
import { SocketioService } from './socketio.service';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { WebsocketMessage } from 'src/app/models/websocket.models';
import { PushEventEntity } from '../models/push.models';

@Injectable({
    providedIn: 'root'
})
export class PushService {
    private pushesByUnitEntities: PushEventEntity[] = [];
    public pushesByUnitEntitiesObserver: ReplaySubject<PushEventEntity[]>;
    private pushesByUserEntities: PushEventEntity[] = [];
    public pushesByUserEntitiesObserver: ReplaySubject<PushEventEntity[]>;
    private unitId: string = '';

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private backendService: BackendService, private socketioService: SocketioService) {
        this.pushesByUnitEntitiesObserver = new ReplaySubject<PushEventEntity[]>(1);
        this.pushesByUserEntitiesObserver = new ReplaySubject<PushEventEntity[]>(1);

        //Ci mettiamo in ascolto dei messaggi Websocket per le eventi push indoor
        this.socketioService.getMessage(WebsocketEventName.insertPush).subscribe((websocket: WebsocketMessage<any>) => {
            if (websocket) {
                //Aggiorniamo la lista delle eventi push indoor
                this.onInsertPushByUnitEvent(websocket.payload);
                //Aggiorniamo la lista delle eventi push outdoor
                this.onInsertPushByUserEvent(websocket.payload);
            }
        });

        this.socketioService.getMessage(WebsocketEventName.deletePush).subscribe((websocket: WebsocketMessage<any>) => {
            if (websocket) {
                //Aggiorniamo la lista delle eventi push indoor
                this.onDeletePushByUnitEvent(websocket.payload);
                //Aggiorniamo la lista delle eventi push outdoor
                this.onDeletePushByUserEvent(websocket.payload);
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onInsertPushByUnitEvent(payload: any) {
        //Se il websocket non è per l'unità indoor corrente, lo ignoriamo
        if (payload.unitId !== this.unitId) return;
        //Cerchiamo nella lista delle eventi push
        const index = this.pushesByUnitEntities.findIndex((event) => event.issuedAt == payload.issuedAt);
        //Se il websocket porta una evento che non conosciamo...
        if (index === -1) {
            //Aggiorniamo la lista delle eventi push aggiungendo quello nuovo
            this.pushesByUnitEntities.unshift(payload);
            //Notifichiamo i nuovi eventi push
            console.log('Emitting added pushesByUnitEntities', this.pushesByUnitEntities);
            this.pushesByUnitEntitiesObserver.next(this.pushesByUnitEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onDeletePushByUnitEvent(payload: any) {
        //Cerchiamo nella lista delle eventi push
        const index = this.pushesByUnitEntities.findIndex((event) => event.issuedAt == payload.issuedAt);
        //Se il websocket porta una evento che già conosciamo...
        if (index !== -1) {
            //Cancelliamo l'evento push non più presente
            this.pushesByUnitEntities.splice(index, 1);
            //Notifichiamo i nuovi dati dell'evento
            console.log('Emitting removed pushesByUnitEntities', this.pushesByUnitEntities);
            this.pushesByUnitEntitiesObserver.next(this.pushesByUnitEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onInsertPushByUserEvent(payload: any) {
        //Cerchiamo nella lista delle eventi push
        const index = this.pushesByUserEntities.findIndex((event) => event.issuedAt == payload.issuedAt);
        //Se il websocket porta una evento che non conosciamo...
        if (index === -1) {
            //Aggiorniamo la lista degli eventi push aggiungendo quello nuovo
            this.pushesByUserEntities.unshift(payload);
            //Notifichiamo i nuovi eventi push
            console.log('Emitting added pushesByUserEntities', this.pushesByUserEntities);
            this.pushesByUserEntitiesObserver.next(this.pushesByUserEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onDeletePushByUserEvent(payload: any) {
        //Cerchiamo nella lista delle eventi push
        const index = this.pushesByUserEntities.findIndex((event) => event.issuedAt == payload.issuedAt);
        //Se il websocket porta una evento che già conosciamo...
        if (index !== -1) {
            //Cancelliamo l'evento push non più presente
            this.pushesByUserEntities.splice(index, 1);
            //Notifichiamo i nuovi dati dell'evento
            console.log('Emitting removed pushesByUserEntities', this.pushesByUserEntities);
            this.pushesByUserEntitiesObserver.next(this.pushesByUserEntities);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadPushesByUnitList(userId, unitId: string): Observable<PushEventEntity[]> {
        //Salviamo l'unità indoor corrente che ci servirà per filtrare i websocket
        this.unitId = unitId;
        return this.backendService.get<PushEventEntity[]>(`/v2/push/by-unit`, { userId, unitId }).pipe(
            map((pushes: PushEventEntity[]) => {
                //Aggiorniamo la lista degli eventi push
                this.pushesByUnitEntities = pushes;
                //Notifichiamo i nuovi dati eventi push
                console.log('Emitting new pushes by unit list...', this.pushesByUnitEntities);
                this.pushesByUnitEntitiesObserver.next(this.pushesByUnitEntities);
                return pushes;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadPushesByUserList(userId: string): Observable<PushEventEntity[]> {
        return this.backendService.get<PushEventEntity[]>(`/v2/push/by-user`, { userId }).pipe(
            map((pushes: PushEventEntity[]) => {
                //Aggiorniamo la lista degli eventi push
                this.pushesByUserEntities = pushes;
                //Notifichiamo i nuovi dati eventi push
                console.log('Emitting new pushes by user list...', this.pushesByUserEntities);
                this.pushesByUserEntitiesObserver.next(this.pushesByUserEntities);
                return pushes;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public deletePush(userId: string, unitId: string, issuedAt: Date): Observable<void> {
        return this.backendService.delete<void>(`/v2/push/delete`, { userId, unitId, issuedAt }).pipe(
            map(() => {
                return;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public deleteAllUnitPushes(userId: string, unitId: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/push/delete/by-unit`, { userId, unitId }).pipe(
            map(() => {
                return;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public deleteAllUserPushes(userId: string): Observable<void> {
        return this.backendService.delete<void>(`/v2/push/delete/by-user`, { userId }).pipe(
            map(() => {
                return;
            })
        );
    }
}
