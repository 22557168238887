import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'card-add-item',
    templateUrl: './card-add-item.page.html',
    styleUrls: ['./card-add-item.page.scss']
})
export class CardAddItemPage {
    @Input() avatar: string;
    @Input() theme: string;
    @Input() name: string;
    avatarSelected: string;
    selectedClass: string;
    @Input() set selected(val: boolean) {
        if (val) {
            this.selectedClass = 'selectedCard-' + this.theme;
            this.avatarSelected = 'white-' + this.theme;
        } else {
            this.selectedClass = 'unselectedCard-' + this.theme;
            this.avatarSelected = 'default-' + this.theme;
        }
    }
    @Input() roomPath: string;
    @Input() degreeValue: number;

    ngOnInit() {
        this.selectedClass = 'unselectedCard-' + this.theme;
        this.avatarSelected = 'default-' + this.theme;
    }
}
