import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from 'src/app/services/application.service';
import { Option } from 'src/app/components/shared/interfaces';
import { ModalController, Platform } from '@ionic/angular';
import { UsersService } from 'src/app/services/users.service';
import { DateTimeWindowChooserModalComponent } from 'src/app/components/shared/date-time-window/modal/modal.component';
import { DateTimeRangeChooserModalComponent } from 'src/app/components/shared/choosers/date-time-range-chooser/date-time-range-chooser-modal/date-time-range-chooser-modal.component';
import { AppTheme, DaysTimeWindow, Role } from 'src/app/types/users.types';
import { PlatformService } from 'src/app/services/platform.service';
import { getUpdatedValues } from 'src/app/utilities/utilities';

@Component({
    selector: 'access-configuration',
    templateUrl: './access-configuration.component.html',
    styleUrls: ['./access-configuration.component.scss']
})
export class AccessConfigurationComponent {
    public days: Option[];
    isFabToggled: boolean;
    public isToggle: boolean;
    // @Input() role: Role;
    public _role: Role;

    @Input() set role(value: Role) {
        this._role = value;
    }
    @Input() titleWindowsEnable = '';
    @Input() theme: AppTheme;
    @Input() userId: string;
    @Input() ttl: number;
    @Input() unitId: string;
    @Input() daysTimeWindows: Array<DaysTimeWindow>;
    @Input() timeWindowsEnable: boolean;
    @Input() checkIn: string;
    @Input() checkOut: string;
    @Input() timeZone: string;

    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    userEditForm = new FormGroup({
        timeWindowsEnable: new FormControl(false, { updateOn: 'change' })
    });
    @Output() daysTimeWindowsChange = new EventEmitter<Array<DaysTimeWindow>>();
    @Output() timeWindowsEnableChange = new EventEmitter<boolean>();
    @Output() checkInChange = new EventEmitter<string>();
    @Output() checkOutChange = new EventEmitter<string>();
    @Output() changed = new EventEmitter<Array<DaysTimeWindow>>();
    @Output() changedTime = new EventEmitter<any>();

    constructor(
        private usersService: UsersService,
        private platformService: PlatformService,
        private appService: AppService,
        public modalController: ModalController
    ) {
        this.days = this.appService.days;
        this.userEditForm.valueChanges.subscribe(() => {
            const updatedValues = getUpdatedValues(this.userEditForm);
            if (updatedValues && this.userEditForm.valid) {
                const timeWindowsEnable = updatedValues.control.value;

                this.timeWindowsEnableChange.emit(timeWindowsEnable);
                this.daysTimeWindowsChange.emit([]);

                this.userEditForm.controls.timeWindowsEnable.markAsPristine();
            }
        });
    }

    ngOnInit(): void {
        this.userEditForm.patchValue({ timeWindowsEnable: this.timeWindowsEnable });
    }

    public dateTimeWindowsChanged(daysTimeWindows: Array<DaysTimeWindow>): void {
        this.timeWindowsEnableChange.emit(this.timeWindowsEnable);
        this.daysTimeWindowsChange.emit(daysTimeWindows);
        // }
    }

    public dateTimeRangeChanged({ checkIn, checkOut }: any): void {
        this.timeWindowsEnableChange.emit(this.timeWindowsEnable);
        this.checkInChange.emit(checkIn);
        this.checkOutChange.emit(checkOut);
    }

    onValueChanged(newValue: boolean) {
        this.valueChange.emit(newValue);
    }

    async addEditDateTime(index?: number): Promise<void> {
        this.timeWindowsEnable = true;
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }

        const modal: HTMLIonModalElement = await this.modalController.create({
            component: DateTimeWindowChooserModalComponent,
            componentProps: {
                theme: this.theme,
                daysTimeWindow: index >= 0 ? this.daysTimeWindows[index] : null,
                timeWindowsEnable: this.timeWindowsEnable
            },
            breakpoints,
            initialBreakpoint
        });

        modal.onDidDismiss().then((result: { data: DaysTimeWindow }) => {
            if (result.data) {
                if (this.daysTimeWindows === null) {
                    this.daysTimeWindows = [];
                }
                const data =
                    index >= 0
                        ? this.daysTimeWindows.map((daysTimeWindow, i) => (i !== index ? daysTimeWindow : result.data))
                        : [...this.daysTimeWindows, result.data];
                this.daysTimeWindows = data;

                this.daysTimeWindowsChange.emit(data);
                this.valueChange.emit(this.timeWindowsEnable);
            }
        });

        await modal.present();
    }

    async addEditTime(index?: number): Promise<void> {
        this.timeWindowsEnable = true;
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: DateTimeRangeChooserModalComponent,
            componentProps: {
                theme: this.theme,
                checkIn: this.checkIn,
                checkOut: this.checkOut,
                timeZone: this.timeZone
            },

            cssClass: 'date-time-range-chooser',
            breakpoints,
            initialBreakpoint
        });

        modal.onDidDismiss().then((result: { data: any }) => {
            if (result.data) this.changedTime.emit(result.data);

            this.checkInChange.emit(result.data.checkIn);
            this.checkOutChange.emit(result.data.checkOut);
        });

        await modal.present();
    }
}
