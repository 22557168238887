<ion-content>
    <item-section-header [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()" title="users.users-invite.invite-modal"></item-section-header>
    <form [formGroup]="form" class="ion-no-margin ion-no-padding">
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
            <item-input-text [setFocus]="true" [theme]="theme" label="user.firstName" controlName="firstName"></item-input-text>
            <item-input-text [theme]="theme" label="users.labels.email" controlName="email"></item-input-text>

            <language-chooser [theme]="theme" [value]="inviterUser.language" (selected)="changeLanguage($event, 'language')"></language-chooser>

            <role-chooser [inviterRole]="inviterUser.role" [theme]="theme" [value]="inviterUser.role" (selected)="changeRole($event, 'role')"></role-chooser>

            <view-chooser [theme]="theme" [unitId]="unitId" (selected)="changeView($event, 'view')" [role]="roleSelected"></view-chooser>
            <access-configuration
                [role]="roleSelected"
                [theme]="theme"
                [(daysTimeWindows)]="daysTimeWindows"
                [(checkIn)]="checkIn"
                [(checkOut)]="checkOut"
                (changedTime)="changedTime($event)"
                (valueChange)="timeWindowsEnableValueChanged($event)"
                (daysTimeWindowsChange)="daysValueChanged($event)"
                [titleWindowsEnable]="titleWindowsEnable"
            ></access-configuration>
            <ion-button expand="full" class="solid button-modal" (click)="userIndoorInvite()" [disabled]="!form.valid">
                {{ 'button.invite' | translate | uppercase }}
            </ion-button>
        </ion-list>
    </form>
</ion-content>
