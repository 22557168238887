import { Component, Input, OnInit } from '@angular/core';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-label-readout',
    templateUrl: './item-label-readout.page.html',
    styleUrls: ['./item-label-readout.page.scss']
})
export class ItemLabelReadoutPage implements OnInit {
    @Input() theme: AppTheme;

    @Input() label: string;
    @Input() value: any;
    @Input() lines: string = 'full';
    @Input() placeHolder: string;
    constructor() {}

    ngOnInit() {}
}
