<ion-content>
    <item-section-header title="ip-addresses-viewer.modal.title" (closeModal$)="closeModal()" [iconClose]="true"></item-section-header>
    <ion-list class="input-padding">
        <ion-item>
            <ion-label>
                <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="'medium-' + theme" style="display: block">
                    {{ 'unit.label.public-ip' | translate }}
                </ion-text>
                <ion-text class="text-input-roboto-regular-16">{{ publicIp }}</ion-text>
            </ion-label>
        </ion-item>
        <ion-item *ngFor="let address of addresses | keyvalue">
            <ion-label>
                <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="'medium-' + theme">
                    {{ getKey(address.value) | translate }}
                </ion-text>
                <ion-text class="text-input-roboto-regular-16">{{ getValue(address.value) }}</ion-text>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
