/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export type PrivateIp = {
    dhcp: boolean;
    ip: string;
    subnet?: string;
    gateway?: string;
    dns?: string;
};

export const unitTimeout = 120;
