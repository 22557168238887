<ion-card
    [ngClass]="'unit-' + theme"
    [ngStyle]="{
        background:
            'linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.2) 100%), url(' + imageDataUrl + ') center center / cover'}"
>
    <ion-grid class="ion-no-margin ion-no-padding">
        <ion-row class="ion-justify-content-between ion-align-items-center">
            <div class="ion-text-start container">
                <ion-thumbnail class="thumbnail-desktop">
                    <ion-img [src]="imageDataUrl"></ion-img>
                    <div *ngIf="notificationCount > 0" class="notification-badge">{{ notificationCount }}</div>
                </ion-thumbnail>
                <ion-label>
                    <ion-text class="h4-roboto-medium-16 title-white">{{unit.unitName }}</ion-text>
                    <ion-text class="caption-roboto-medium-13" [color]="color">{{status | translate | titlecase}}</ion-text>
                </ion-label>
            </div>
            <div class="ion-text-end">
                <ion-icon color="light" name="information-circle" (click)="openAlertInfo()"></ion-icon>
            </div>
        </ion-row>

        <ion-row class="ion-justify-content-between ion-align-items-center ion-no-padding margin-top-24">
            <ion-col size="6" class="ion-no-margin ion-padding-end">
                <ion-button (click)="unitExit(unit.unitId)" size="small" fill="clear" expand="block" class="outline-small">
                    <ion-icon size="small" name="log-out"></ion-icon>
                    {{'unit.unlink-unit' | translate}}
                </ion-button>
            </ion-col>
            <ion-col size="6" class="ion-no-margin ion-padding-start">
                <ion-button
                    (click)="unitEnter(unit.unitId)"
                    [disabled]="!unitOnline || status === 'disabled'"
                    size="small"
                    fill="clear"
                    expand="block"
                    class="solid-small"
                >
                    {{'unit.list.enter' | translate}}
                    <ion-icon size="small" slot="end" name="log-in"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card>
