<ion-content>
    <ion-grid>
        <ion-row class="ion-text-center">
            <ion-col>
                <ion-text color="danger">
                    <h2><b>Oops something went wrong. Please try again</b></h2>
                </ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-center">
            <ion-col>
                <ion-text>
                    <h3>
                        Error message
                        <br />
                        {{ message }}
                    </h3>
                </ion-text>
                <ion-text>
                    <h4>
                        Status code
                        <br />
                        {{ statusCode }}
                    </h4>
                </ion-text>
                <ion-text>
                    <h4>
                        Status message
                        <br />
                        {{ statusText }}
                    </h4>
                </ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
