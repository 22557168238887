import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { UnitEntity } from 'src/app/models/units.models';
import { PlatformService } from 'src/app/services/platform.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'vpn-modal-add',
    templateUrl: './vpn-modal-add.component.html',
    styleUrls: ['./vpn-modal-add.component.scss']
})
export class VpnModalAddComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public contentClass: string;

    @Input() theme: AppTheme;
    @Input() unit: UnitEntity;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(public modalController: ModalController, private platformService: PlatformService, private socketioService: SocketioService) {
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {
        this.form = new FormGroup({
            networkId: new FormControl<string>(null, [Validators.required, this.networkValidator()])
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public async saveSettings(): Promise<void> {
        this.socketioService.publishTopic<any>(this.unit.unitId, WebsocketEventName.joinVpnNetwork, {
            payload: { command: 'join', network: this.form.value.networkId }
        });

        await this.modalController.dismiss({ returnToParent: true });
        // this.modalClose();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose(): void {
        this.modalController.dismiss();
    }
    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    networkValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const networkRegex = /^[0-9a-fA-F]{16}$/;

            if (control.value && !networkRegex.test(control.value)) {
                return { invalidNetworkId: true };
            }

            return null;
        };
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy(): void {
        // this.form.reset();
    }
}
