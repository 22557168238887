import { Component, Input } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { SocketioService } from 'src/app/services/socketio.service';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DisplayMode, Result } from 'src/app/models/application.models';
import { UnitsService } from 'src/app/services/units.service';
import { SteppedFormModalModule } from '../../shared/stepped-form-modal/stepped-form-modal.module';
import { AppTheme } from 'src/app/types/users.types';
import { UnitEntity } from 'src/app/models/units.models';
import { UnitEventName } from 'src/app/models/events-names.models';
import { PrivateIp, unitTimeout } from 'src/app/types/units.types';
import { UnitJoinToUser, UnitRegistered } from 'src/app/interfaces/units.interfaces';
import { SubscriptionKind } from 'src/app/types/socketio.types';
import { WebsocketMessage, PayloadVoid } from 'src/app/models/websocket.models';
import { PlatformService } from 'src/app/services/platform.service';
import { ModalUnitDataComponent } from '../modal-unit-data/modal-unit-data.component';

@Component({
    templateUrl: './add-modal.component.html',
    styleUrls: ['./add-modal.component.scss']
})
export class UnitAddModal {
    @Input() theme: AppTheme;
    @Input() userId: string;
    @Input() imageDataUrl: string;
    public display = DisplayMode.main;
    private interval: NodeJS.Timeout;
    public serialNumber: string;
    private unitId: string;
    public seconds: string;
    private timeout = 120;
    public percent = 100;
    public fakeUnits: boolean;
    public contentClass: string;
    private unit: UnitEntity;

    unitCreateForm = new FormGroup({
        serialNumber: new FormControl(null, { validators: Validators.required, updateOn: 'change' })
    });

    unitform = new FormGroup({
        unitName: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
        address: new FormControl(null, { updateOn: 'blur' })
    });

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        public modalController: ModalController,
        private alertController: AlertController,
        private translate: TranslateService,
        private unitsService: UnitsService,
        private socketio: SocketioService,
        private platformService: PlatformService
    ) {
        // Prendiamo le info di build per gestire i bottoni fake
        this.fakeUnits = environment.fakeUnits;
        this.contentClass = 'ion-padding';

        this.unitCreateForm.get('serialNumber').valueChanges.subscribe((value) => {
            this.serialNumber = value;
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {
        // Subscribe to socketio messages on TOPICS event
        this.socketio.getMessage(UnitEventName.link).subscribe((kafkaMessage: WebsocketMessage<PayloadVoid>) => {
            if (kafkaMessage) {
                const { topic } = kafkaMessage;
                if (topic === this.unitId) {
                    this.unitsService.unitLinkToUser(this.unitId, this.userId).subscribe({
                        next: (unit) => {
                            this.unit = unit;
                            console.log(`UnitAddModal - Unit ${this.unit.unitId} linked succesfully to user ${this.userId}`);
                            //Apriamo la modal di conferma
                            this.correctUnitCode();
                            //Metto qui questo modal controller così che quando si apre la modal per aggiungere i dati della unit si chiuda la modale sotto
                            this.modalController.dismiss({
                                dismissed: true
                            });
                        },
                        error: (error) => {}
                    });
                }
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private convertSeconds = (seconds: number): string => {
        let minDigit = Math.floor(seconds / 60).toString();
        let secDigit = (seconds % 60).toString();

        if (/^\d$/.test(minDigit)) {
            minDigit = '0' + minDigit;
        }
        if (/^\d$/.test(secDigit)) {
            secDigit = '0' + secDigit;
        }

        return minDigit + ':' + secDigit;
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private createTimer = (): void => {
        this.seconds = this.convertSeconds(this.timeout);
        let calcSec = this.timeout;

        this.interval = setInterval(() => {
            if (calcSec > 0) {
                calcSec--;
                this.percent = (calcSec * 100) / unitTimeout;
                this.seconds = this.convertSeconds(calcSec);
            } else {
                clearInterval(this.interval);
                this.display = DisplayMode.timeout;

                //Facciamo smettere di lampeggiare il led link
                this.socketio.publishTopic<PayloadVoid>(this.unitId, UnitEventName.unlinked);
            }
        }, 1000);
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async unitRegisterFake(): Promise<void> {
        const serialNumber = `${Math.floor(Math.random() * 99999999999999)}`;
        this.unitCreateForm.get('serialNumber').patchValue(serialNumber);

        // Attiviamo la centrale fasulla
        this.unitsService.unitActivate({ serialNumber, fake: true }).subscribe({
            next: (unit: UnitRegistered) => {
                console.log(`Fake unit activated ${JSON.stringify(unit)}`);
                // Registriamo la centrale fasulla
                this.unitsService
                    .unitRegister({
                        serialNumber,
                        fake: true,
                        hardwareVersion: '0.0.0',
                        softwareVersion: '0.0.0',
                        privateIp: { dhcp: true } as PrivateIp
                    })
                    .subscribe((unit: UnitRegistered) => {
                        console.log(`Fake unit registered ${JSON.stringify(unit)}`);
                    });
            },
            error: (error) => {}
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    unitJoinToSerialNumber(): void {
        // Addomestichiamo la stringa
        this.serialNumber = this.serialNumber.trim().toUpperCase();
        console.log(`Joining serial number ${this.serialNumber}`);
        this.unitsService.unitJoinToSerialNumber({ serialNumber: this.serialNumber }).subscribe({
            next: (unit: UnitEntity) => {
                console.log(`Unit joined ${JSON.stringify(unit)}`);

                const { unitId, serialNumber } = unit;
                // Se la join è andata a buon fine ci iscriviamo al topic della unitId
                this.socketio.topicSubscribe(unitId, SubscriptionKind.unit, 'Joining: ' + serialNumber);

                //Ci segnamo lo unitId per poterlo utilizzare al momento in cui riceveremo il link da websocket
                this.unitId = unitId;
                this.createTimer();
                this.display = DisplayMode.timer;
            },
            error: _ => {
                this.wrongUnitCode();
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    changedProperty(property: string, event: unknown): void {
        if (property == 'serial') {
            this.serialNumber = event['detail'].value;
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    fakeLinkClick = (): void => {
        // Publish to Socketio
        // this.socketio.topicPublish<PayloadVoid>(this.unitId, UnitEventName.link);
        this.unitsService.unitLinkToUser(this.unitId, this.userId).subscribe(() => {
            console.log(`UnitAddModal - Unit ${this.userId} linked succesfully to user ${this.userId}`);
            //Apriamo la modal di conferma
            this.correctUnitCode();
            //Metto qui questo modal controller così che quando si apre la modal per aggiungere i dati della unit si chiuda la modale sotto
            this.modalController.dismiss({
                dismissed: true
            });
        });
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose = (): void => {
        clearInterval(this.interval);
        this.modalController.dismiss();
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    tryAgain = (): void => {
        clearInterval(this.interval);

        // Publish to Socketio spegnendo il led
        this.socketio.publishTopic<PayloadVoid>(this.unitId, UnitEventName.unlinked);

        this.display = DisplayMode.main;
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public wrongUnitCode = async (): Promise<void> => {
        const alert = await this.alertController.create({
            header: this.translate.instant(' Ops! Il tuo supervisore non si è abbinato'),
            subHeader: this.translate.instant('C’è stato qualche problema con l’abbinamento del supervisore.'),

            cssClass: 'alert-icon-error danger-alert',

            buttons: [{ cssClass: 'button-alert-class', text: this.translate.instant('button.try-again'), role: 'retry' }]
        });
        await alert.present();
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public correctUnitCode = async (): Promise<void> => {
        const alert = await this.alertController.create({
            header: this.translate.instant('Supervisore abbinato correttamente!'),
            subHeader: this.translate.instant('Inizia a personalizzare Il tuo nuovo impianto!'),
            cssClass: 'alert-icon-confirm confirm-alert',

            buttons: [
                {
                    text: this.translate.instant('button.init'),
                    // role: 'retry',
                    cssClass: 'button-alert-class',
                    handler: () => {
                        //Apriamo la modal di modifica dati
                        this.openChangeValueModal(this.unitCreateForm.value);
                    }
                }
            ]
        });
        await alert.present();
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public generateRandomCoordinates(minLatitudine: number, maxLatitudine: number, minLongitudine: number, maxLongitudine: number): any {
        // Genera una latitudine casuale all'interno dell'intervallo specificato
        const latitude = Math.random() * (maxLatitudine - minLatitudine) + minLatitudine;
        // Genera una longitudine casuale all'interno dell'intervallo specificato
        const longitude = Math.random() * (maxLongitudine - minLongitudine) + minLongitudine;
        // Restituisce la coppia di coordinate
        return { latitude, longitude };
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async openChangeValueModal(controlName: any): Promise<void> {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: ModalUnitDataComponent,
            componentProps: {
                theme: this.theme
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();
        const res = await modal.onDidDismiss();

        if (res.data) {
            // if (this.fakeUnits) {
            //     const fakeCoords = this.generateRandomCoordinates(0, 8, 0, 50);
            //     res.data.coordinates.lat = fakeCoords.latitude;
            //     res.data.coordinates.lon = fakeCoords.longitude;
            // }
            //Aggiorniamo i dati della unit su database
            this.unitsService
                .unitUpdate(this.unitId, {
                    unitName: res.data.form.unitName,
                    latitude: res.data.coordinates.lat,
                    longitude: res.data.coordinates.lon,
                    address: res.data.address
                })
                .subscribe();
        }
        // },
        // error: async (error) => {};
        // // });
        // await modal.present();
    }
}
