import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, map } from 'rxjs';
import { BackendService } from './backend.service';
import { ViewData, ViewEntity } from '../models/views.models';
import { RoomEntity } from '../models/rooms.models';
import { ScenarioEntity } from '../models/scenarios/°°°scenarios.models';
import { DeviceEntity } from '../models/devices/°°°devices';
import { DeviceFamilyEntity } from '../models/devices-families';
import { SocketioService } from './socketio.service';
import { WebsocketMessage } from '../models/websocket.models';
import { WebsocketEventName } from '../models/events-names.models';
import { NavController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ViewsService {
    public listedViewsObserver: ReplaySubject<ViewEntity[]>;
    private listedViews: ViewEntity[];

    public editedViewObserver: ReplaySubject<ViewEntity>;
    private editedView: ViewEntity;

    public listedScenariosObserver: BehaviorSubject<ScenarioEntity[]>;
    public listedScenarios: ScenarioEntity[];

    public listedRoomsObserver: ReplaySubject<RoomEntity[]>;
    public listedRooms: RoomEntity[];

    public listedFamiliesObserver: ReplaySubject<DeviceFamilyEntity[]>;
    public listedFamilies: DeviceFamilyEntity[];

    public listedDevicesObserver: ReplaySubject<DeviceEntity[]>;
    public listedDevices: DeviceEntity[];

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private socketioService: SocketioService, private backendService: BackendService, private navCtrl: NavController) {
        this.listedViewsObserver = new ReplaySubject<ViewEntity[]>(1);
        this.editedViewObserver = new ReplaySubject<ViewEntity>(1);
        this.listedScenariosObserver = new BehaviorSubject<ScenarioEntity[]>([]);
        this.listedRoomsObserver = new ReplaySubject<RoomEntity[]>(1);
        this.listedFamiliesObserver = new ReplaySubject<DeviceFamilyEntity[]>(1);
        this.listedDevicesObserver = new ReplaySubject<DeviceEntity[]>(1);

        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.insertView).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onInsertView(kafkaMessage.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.updateView).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onUpdateView(kafkaMessage.payload);
            }
        });

        //Ci mettiamo in ascolto dei messaggi websocket
        this.socketioService.getMessage(WebsocketEventName.deleteView).subscribe((kafkaMessage: WebsocketMessage<any>) => {
            if (kafkaMessage) {
                this.onDeleteView(kafkaMessage.payload);
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onInsertView(payload: ViewEntity) {
        //Aggiorniamo la lista di views
        const index = this.listedViews.findIndex((view) => view.viewId == payload.viewId);
        //Se il websocket porta una view che già conosciamo...
        if (index !== -1) {
            //Ne aggiorniamo l'oggetto originale
            this.listedViews[index] = { ...this.listedViews[index], ...payload };
            //Notifichiamo i nuovi dati della view
            console.log('Emitting new listedView...', this.listedViews);
            this.listedViewsObserver.next(this.listedViews);
        } else {
            //Aggiorniamo la lista di views
            this.listedViews.push(payload);
            //Notifichiamo i nuovi dati della view
            console.log('Emitting new listedView...', this.listedViews);
            this.listedViewsObserver.next(this.listedViews);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onUpdateView(payload: ViewEntity | any) {
        //Aggiorniamo il singolo view
        if (this.editedView?.viewId === payload.viewId) {
            //Ne aggiorniamo l'oggetto originale
            this.editedView = { ...this.editedView, ...payload };
            //Notifichiamo i nuovi dati della view
            console.log('Emitting new editedView...', this.editedView);
            this.editedViewObserver.next(this.editedView);
        }

        //Aggiorniamo la lista di views
        const index = this.listedViews.findIndex((view) => view.viewId == payload.viewId);
        //Se il websocketMessage porta un view che conosciamo...
        if (index !== -1) {
            //Ne aggiorniamo l'oggetto originale
            this.listedViews[index] = { ...this.listedViews[index], ...payload };
            //Notifichiamo i nuovi dati della view
            console.log('Emitting new listedView...', this.listedViews);
            this.listedViewsObserver.next(this.listedViews);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public onDeleteView(payload: ViewEntity) {
        //Aggiorniamo il singolo view
        if (this.editedView?.viewId == payload.viewId) {
            //Si salta alla lista
            this.navCtrl.navigateRoot(`/indoor/views/list`, { replaceUrl: true });
        }

        //Aggiorniamo la lista di views
        const index = this.listedViews.findIndex((view) => view.viewId == payload.viewId);
        //Se il websocketMessage porta un view che conosciamo...
        if (index !== -1) {
            //Lo cancelliamo dalla lista
            this.listedViews.splice(index, 1);
            //Notifichiamo i nuovi dati della view
            console.log('Emitting new listedView...', this.listedViews);
            this.listedViewsObserver.next(this.listedViews);
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public saveViewId(viewId: string) {
        sessionStorage.setItem('viewId', viewId);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadViewId() {
        return sessionStorage.getItem('viewId');
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo la lista delle viste
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public loadViewsList(unitId: string): Observable<ViewEntity[]> {
        //Leggiamo i dati delle viste
        return this.backendService.get<ViewEntity[]>(`/v2/views`, { unitId }).pipe(
            map((views: ViewEntity[]) => {
                //Aggiorniamo le info delle viste
                this.listedViews = views;
                //Notifichiamo l'elenco delle viste
                console.log('Emitting new listedViews...', this.listedViews);
                this.listedViewsObserver.next(this.listedViews);
                return views;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Leggiamo una vista
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public readView(unitId: string, viewId: string): Observable<ViewEntity> {
        return this.backendService.get<ViewEntity>(`/v2/views/read`, { unitId, viewId }).pipe(
            map((view: ViewEntity) => {
                //Aggiorniamo le info della vista editata
                this.editedView = view;
                //Notifichiamo i nuovi dati della vista
                console.log('Emitting new editedViews...', this.editedView);
                this.editedViewObserver.next(this.editedView);
                return view;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiorniamo una vista
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public updateView(unitId: string, viewId: string, data: ViewData): Observable<void> {
        return this.backendService.put(`/v2/views/update`, { unitId, viewId, ...data });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Aggiungiamo una vista
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public createView(unitId: string, data: ViewData): Observable<ViewEntity> {
        return this.backendService.post(`/v2/views/create`, { unitId, ...data });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Rimuoviamo una vista
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public deleteView(unitId: string, viewId: string): Observable<void> {
        return this.backendService.delete(`/v2/views/delete`, { unitId, viewId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Switch on una vista
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public switchOnView(unitId: string, viewId: string, parentId: string, roomId: string): Observable<void> {
        return this.backendService.post(`/v2/views/rooms/add`, { unitId, viewId, parentId, roomId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Switch off una vista
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public switchOffView(unitId: string, viewId: string, parentId: string, roomId: string): Observable<void> {
        return this.backendService.post(`/v2/views/rooms/remove`, { unitId, viewId, parentId, roomId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Switch on uno scenario
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public switchOnScenario(unitId: string, viewId: string, scenarioId: string, roomId: string): Observable<void> {
        return this.backendService.post(`/v2/views/scenarios/add`, { unitId, viewId, roomId, scenarioId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Switch off uno scenario
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public switchOffScenario(unitId: string, viewId: string, scenarioId: string, roomId: string): Observable<void> {
        return this.backendService.post(`/v2/views/scenarios/remove`, { unitId, viewId, roomId, scenarioId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Switch on device
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public switchOnDevice(unitId: string, viewId: string, deviceId: string, roomId: string, familyId: string): Observable<void> {
        return this.backendService.post(`/v2/views/devices/add`, { unitId, viewId, roomId, deviceId, familyId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Switch off device
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public switchOffDevice(unitId: string, viewId: string, deviceId: string, roomId: string): Observable<void> {
        return this.backendService.post(`/v2/views/devices/remove`, { unitId, viewId, roomId, deviceId });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Interroga la tabella rooms_by_view passandogli oltre a unitId e viewId anche roomId che corrisponde al parentId
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public findRoomsByView(unitId: string, viewId: string, parentId: string): Observable<RoomEntity[]> {
        return this.backendService.get(`/v2/views/rooms`, { unitId, viewId, parentId }).pipe(
            map((rooms: RoomEntity[]) => {
                //Aggiorniamo le info della lista rooms per quella view e per quella stanza
                this.listedRooms = rooms;
                //Notifichiamo le nuove rooms
                console.log('Emitting new listedRooms...', this.listedRooms);
                this.listedRoomsObserver.next(this.listedRooms);
                return rooms;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Interroga la tabella devices_by_view (Il backend restituisce solo le famiglie)
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public findFamiliesByView(unitId: string, viewId: string): Observable<DeviceFamilyEntity[]> {
        return this.backendService.get(`/v2/views/families`, { unitId, viewId }).pipe(
            map((families: DeviceFamilyEntity[]) => {
                //Aggiorniamo le info della lista families
                this.listedFamilies = families;
                //Notifichiamo le nuove families
                console.log('Emitting new listedFamilies...', this.listedFamilies);
                this.listedFamiliesObserver.next(this.listedFamilies);
                return families;
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Interroga la tabella devices_by_view (Il backend restituisce solo i devices per la famiglia indicata)
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public findDevicesByView(unitId: string, viewId: string, familyId: string): Observable<DeviceEntity[]> {
        return this.backendService.get(`/v2/views/devices`, { unitId, viewId, familyId }).pipe(
            map((devices: DeviceEntity[]) => {
                //Aggiorniamo le info della lista devices
                this.listedDevices = devices;
                //Notifichiamo i nuovi devices
                console.log('Emitting new listedDevices...', this.listedDevices);
                this.listedDevicesObserver.next(this.listedDevices);
                return devices;
            })
        );
    }
}
