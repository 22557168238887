<ion-content>
    <item-section-header title="unit-network-readonly.modal.title" (closeModal$)="closeModal()" [iconClose]="true"></item-section-header>
    <ion-list class="input-padding">
        <ion-item *ngFor="let data of networkData | keyvalue">
            <ion-label>
                <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="'medium-' + theme" style="display: block">
                    {{ 'unit.label.' + data.key | translate }}
                </ion-text>
                <ion-text class="text-input-roboto-regular-16">{{ data.value }}</ion-text>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
