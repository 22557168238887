import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { GlobalErrorHandlerService } from '../../services/error-handler.service';
import { HeaderInterceptor } from '../../interceptors/auth.interceptor';

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        ErrorDialogService
    ]
})
export class ErrorsModule {}
