<ion-item lines="full" detail="false">
    <ion-label>
        <ion-text [class]="descriptionClass" style="display: block">
            {{ vpn.name }}
        </ion-text>
        <ion-text [class]="serialNumberClass">{{ vpn.nwid }}</ion-text>
    </ion-label>
    <ion-button slot="end" class="solid" (click)="leaveNetwork(vpn.nwid)" expand="block">
        {{ 'button.leave-network' | translate }}
    </ion-button>
</ion-item>
