export enum NotificationChannel {
    push = 'push',
    mail = 'mail',
    telegram = 'telegram',
    whatsapp = 'whatsapp',
    sms = 'sms',
    call = 'call'
}

export enum NotificationIcons {
    push = 'notifications-outline',
    mail = 'mail-outline',
    telegram = 'navigate-circle-outline',
    whatsapp = 'logo-whatsapp',
    sms = 'mail-open-outline',
    call = 'call-outline'
}

export interface NotificationEmail {
    name: string;
    template: string;
}

export interface NotificationForm {
    emailTemplate?: string;
    object?: string;
    text?: string;
    filters?: string[];
    scheduledDate?: Date;
}

export class NotificationEntity {
    messageType?: string[] = [];
    emailTemplate?: string;
    object?: string;
    text?: string;
    filters?: string[] = [];
    sendDate?: Date;
    scheduledDate?: Date;
    senderId?: string;
    senderName?: string;
}

export class NotificationSingleMessageEntity {
    messageType?: string;
    emailTemplate?: string;
    object?: string;
    text?: string;
    filters?: string[] = [];
    sendDate?: Date;
    scheduledDate?: Date;
    senderId?: string;
    senderName?: string;
    countSendedNotifications?: number
}

export interface Balance {
    payment: string;
    type: string;
    amount: number
}
