import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment } from './../environments/environment';

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
    return new TranslateHttpLoader(http, environment.i18nPrefix, '.json');
};

// TODO Non riesco a far funzionare l'handler quando mancano delle traduzioni
// https://github.com/ngx-translate/core#how-to-handle-missing-translations

// @Injectable()
// export class MyMissingTranslationHandler implements MissingTranslationHandler {
//     handle(params: MissingTranslationHandlerParams): string {
//         console.log('handle');
//         return `**MISSING KEY: ${params.key}**`;
//     }
// }

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
            // missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
            // useDefaultLang: false
        })
    ]
})
export class HttpLoaderFactoryModule {}
