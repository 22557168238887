import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { DevicesFamilyNames } from '../types/devices-families.types';

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
const isObject = (object: unknown) => object !== null && object !== undefined && typeof object === 'object';

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const mapToObject = <V>(map: Map<string, V>): Record<string, V> =>
    Array.from(map.entries()).reduce((o, [key, value]) => {
        o[key] = value;
        return o;
    }, {});

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const getUpdatedValues = (form: FormGroup): { property: string; control: FormControl | FormGroup } => {
    let updatedValues = null;
    form['_forEachChild']((control: FormControl | FormGroup, property: string) => {
        if (control.dirty)
            updatedValues = {
                property,
                control
            };
    });
    return updatedValues;
};

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] => {
    return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const validateAtLestOneChecked = (form: FormGroup): ValidationErrors | null =>
    form.value.some((checked: boolean) => checked) ? null : { mismatch: true };

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const millisecondsToTime = (
    milliseconds: number
): {
    hours: string;
    minutes: string;
    seconds: string;
} => {
    const seconds = milliseconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;

    const hours24 = Math.floor(hours) % 24;
    const minutes60 = Math.floor(minutes) % 60;
    const seconds60 = Math.floor(seconds) % 60;

    return {
        hours: hours24 < 10 ? '0' + hours24 : hours24.toString(),
        minutes: minutes60 < 10 ? '0' + minutes60 : minutes60.toString(),
        seconds: seconds60 < 10 ? '0' + seconds60 : seconds60.toString()
    };
};

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const deepEqual = (object1: unknown, object2: unknown) => {
    const areObjects = isObject(object1) && isObject(object2);
    if (!areObjects) return object1 === object2;

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) return false;
    }
    return true;
};

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const controllersToItems = (params: Record<string, string | boolean | number>): any =>
    Object.entries(params).reduce(
        (prev, [key, value]) =>
            value !== null && value !== undefined
                ? {
                      ...prev,
                      [key]: { value }
                  }
                : prev,
        {}
    );

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export const getUrlQueryParamValue = (name: string, url: string) => {
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const sortByName = (array) => {
    return array.slice().sort((a, b) => {
        // Controlla se `name` è null o undefined e gestiscilo di conseguenza
        if (a.name === null || a.name === undefined) return 1;
        if (b.name === null || b.name === undefined) return -1;

        // Converti in stringhe, imposta tutto in minuscolo e confronta
        const aValue = a.name.toString().toLowerCase();
        const bValue = b.name.toString().toLowerCase();

        return aValue.localeCompare(bValue);
    });
};
export const sortAndTranslateByName = (array, translate) => {
    return array.slice().sort((a, b) => {
        // Controlla se `name` è null o undefined e gestiscilo di conseguenza
        if (a.name === null || a.name === undefined) return 1;
        if (b.name === null || b.name === undefined) return -1;

        // Traduci `name` usando `translate.instant`'device.familyName.' + family.name
        const aTranslated = translate.instant('device.deviceType.' + a.name);
        const bTranslated = translate.instant('device.deviceType.' + b.name);

        // Ordina in base al valore tradotto
        return aTranslated.localeCompare(bTranslated);
    });
};
export const sortByFirstname = (array) => {
    return array.slice().sort((a, b) => {
        // Controlla se `name` è null o undefined e gestiscilo di conseguenza
        if (a.firstName.toString().toLowerCase() === null || a.firstName.toString().toLowerCase() === undefined) return 1;
        if (b.firstName.toString().toLowerCase() === null || b.firstName.toString().toLowerCase() === undefined) return -1;

        // Converti in stringhe per assicurarti che la localeCompare funzioni correttamente
        const aValue = a.firstName.toString().toLowerCase();
        const bValue = b.firstName.toString().toLowerCase();

        return aValue.localeCompare(bValue);
    });
};

export const sortByFamilyId = (array) => {
    return array.slice().sort((a, b) => {
        // Controlla se `name` è null o undefined e gestiscilo di conseguenza
        if (a.familyId === null || a.familyId === undefined) return 1;
        if (b.familyId === null || b.familyId === undefined) return -1;

        // Converti in stringhe per assicurarti che la localeCompare funzioni correttamente
        const aValue = a.familyId.toString();
        const bValue = b.familyId.toString();

        return aValue.localeCompare(bValue);
    });
};
export const sortAndTranslateByFamilyId = (array, translate) => {
    return array.slice().sort((a, b) => {
        // Controlla se `familyId` è null o undefined e gestiscilo di conseguenza
        if (a.familyId === null || a.familyId === undefined) return 1;
        if (b.familyId === null || b.familyId === undefined) return -1;

        // Traduci `familyId` usando `translate.instant`'device.familyName.' + family.familyId
        const aTranslated = translate.instant('device.familyName.' + a.familyId);
        const bTranslated = translate.instant('device.familyName.' + b.familyId);

        // Ordina in base al valore tradotto
        return aTranslated.localeCompare(bTranslated);
    });
};
export const sortByNameDevice = function (array: any[]): any[] {
    return array.sort((a, b) => {
        const nameA = a.notifier.name.toLowerCase();
        const nameB = b.notifier.name.toLowerCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};
/*----------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------funzione creata nel caso in cui il device venga cercato come valore invece che come chiave ----------------------------------------------*/

export function getKeyByValue(value: string): string | undefined {
    const entries = Object.entries(DevicesFamilyNames);
    for (const [key, val] of entries) {
        if (val === value) {
            return key;
        }
    }
    return undefined;
}
