import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'error-dialog',
    templateUrl: './error-dialog.page.html',
    styleUrls: ['./error-dialog.page.scss']
})
export class ErrorDialogPage implements OnInit {
    @Input() message: string;
    @Input() statusCode: string;
    @Input() statusText: string;
    constructor() {}

    ngOnInit() {}
}
