export enum AutomationTypesNames {
    shutter = 'shutter',
    sunBlind = 'sunBlind',
    sunshadeAwning = 'sunshadeAwning',
    curtain = 'curtain',
    window = 'window',
    gate = 'gate',
    pedestrianGate = 'pedestrianGate',
    frontDoor = 'frontDoor',
    barrier = 'barrier',
    tilting = 'tilting'
}
export const AutomationDataType = {
    shutter: {
        name: 'shutter',
        avatar: 'shutter-close',
        image: 'assets/controls/shutter.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            },
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            positionable: {
                absolutePosition: {
                    type: 'number',
                    defaultValue: 0,
                    minValue: 0,
                    maxValue: 100,
                    unitMeasure: '%',
                    inputTypes: {
                        trigger: {
                            type: 'slider'
                        },
                        condition: {
                            type: 'slider'
                        },
                        action: {
                            type: 'slider'
                        }
                    },
                    operators: {
                        trigger: ['greater', 'less'],
                        condition: ['greater', 'less'],
                        action: ['set']
                    }
                }
            }
        }
    },
    sunBlind: {
        name: 'sunBlind',
        avatar: 'curtain-close',
        image: 'assets/controls/sunBlind.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            },
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            positionable: {
                absolutePosition: {
                    type: 'number',
                    defaultValue: 0,
                    minValue: 0,
                    maxValue: 100,
                    unitMeasure: '%',
                    inputTypes: {
                        trigger: {
                            type: 'slider'
                        },
                        condition: {
                            type: 'slider'
                        },
                        action: {
                            type: 'slider'
                        }
                    },
                    operators: {
                        trigger: ['greater', 'less'],
                        condition: ['greater', 'less'],
                        action: ['set']
                    }
                }
            }
        }
    },
    sunshadeAwning: {
        name: 'sunshadeAwning',
        avatar: 'curtain-close',
        image: 'assets/controls/sunshadeAwning.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            },
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            positionable: {
                absolutePosition: {
                    type: 'number',
                    defaultValue: 0,
                    minValue: 0,
                    maxValue: 100,
                    unitMeasure: '%',
                    inputTypes: {
                        trigger: {
                            type: 'slider'
                        },
                        condition: {
                            type: 'slider'
                        },
                        action: {
                            type: 'slider'
                        }
                    },
                    operators: {
                        trigger: ['greater', 'less'],
                        condition: ['greater', 'less'],
                        action: ['set']
                    }
                }
            }
        }
    },
    curtain: {
        name: 'curtain',
        avatar: 'curtain-close',
        image: 'assets/controls/curtain.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            },
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            positionable: {
                absolutePosition: {
                    type: 'number',
                    defaultValue: 0,
                    minValue: 0,
                    maxValue: 100,
                    unitMeasure: '%',
                    inputTypes: {
                        trigger: {
                            type: 'slider'
                        },
                        condition: { type: 'slider' },
                        action: { type: 'slider' }
                    },
                    operators: {
                        trigger: ['greater', 'less'],
                        condition: ['greater', 'less'],
                        action: ['set']
                    }
                }
            }
        }
    },
    window: {
        name: 'window',
        avatar: 'windows',
        image: 'assets/controls/window.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            }
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // }
        }
    },
    gate: {
        name: 'gate',
        avatar: 'gate-open',
        image: 'assets/controls/gate.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            }
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // }
        }
    },
    pedestrianGate: {
        name: 'pedestrianGate',
        avatar: 'gate-close',
        image: 'assets/controls/pedestrianGate.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            }
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // }
        }
    },
    frontDoor: {
        name: 'frontDoor',
        avatar: 'door-close',
        image: 'assets/controls/frontDoor.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop', 'lock', 'unlock']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop', 'locked', 'unlocked']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop', 'lock', 'unlock']
                        }
                    }
                }
            }
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // }
        }
    },
    barrier: {
        name: 'barrier',
        avatar: 'bar-close',
        image: 'assets/controls/barrier.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            }
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // }
        }
    },
    tilting: {
        name: 'tilting',
        avatar: 'tilting-closed',
        image: 'assets/controls/tilting.webp',
        items: {
            default: {
                drive: {
                    type: 'string',
                    defaultValue: 'idle',
                    operators: {
                        trigger: ['equal', 'different', 'any'],
                        condition: ['equal', 'different'],
                        action: ['set']
                    },
                    inputTypes: {
                        trigger: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        condition: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        },
                        action: {
                            type: 'selector',
                            values: ['open', 'close', 'stop']
                        }
                    }
                }
            }
            // blockInfo: {
            //     blockSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // faultInfo: {
            //     faultSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // },
            // alarmInfo: {
            //     alarmSignal: {
            //         type: 'boolean',
            //         defaultValue: false,
            //         inputTypes: {
            //             trigger: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             condition: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             },
            //             action: {
            //                 type: 'selector',
            //                 values: [true, false]
            //             }
            //         },
            //         operators: {
            //             trigger: ['equal', 'any'],
            //             condition: ['equal']
            //         }
            //     }
            // }
        }
    }
};
