import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CredentialByUserData } from 'src/app/models/credentials.models';
import { Token } from 'src/app/models/token.models';
import { UserByTokenEntity } from 'src/app/models/welcome.models';
import * as sha1 from 'js-sha1';

@Injectable({ providedIn: 'root' })
export class CredentialsService {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private backend: BackendService, private auth: AuthService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsUpdate(userId: string, data: CredentialByUserData, dataCheck: CredentialByUserData): Observable<Token> {
        return this.backend.put(`/credentials`, { userId, ...data, dataCheck });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsReset = (captchaToken: string, token: string, credentialsData: CredentialByUserData): Observable<void> =>
        this.backend.patch<void>(`/credentials/reset`, {
            captchaToken,
            token,
            ...credentialsData
        });

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsResetRequest = (userId: string, property: string, ttl: number): Observable<void> =>
        this.backend.patch<void>(`/credentials/reset/request`, {
            userId,
            property,
            redirect: environment.frontendUrl + '/external/reset',
            ttl
        });

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsNullable = (userId: string, property: string): Observable<boolean> => this.backend.get(`/credentials/nullable`, { userId, property });

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsPasswordRestoreCheckEmail(captchaToken: string, email: string, ttl: number): Observable<void> {
        return this.backend.patch<void>(`v2/credentials/password/reset/check/email`, {
            captchaToken,
            email: email.trim().toLowerCase(),
            redirect: environment.frontendUrl + '/external/reset',
            ttl
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsCheckRegistrationToken = (email: string, token: string): Observable<UserByTokenEntity> =>
        this.backend.get<UserByTokenEntity>(`/credentials/password/reset/check/token`, {
            email,
            token
        });

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsUpdatePin(userId: string, data: CredentialByUserData): Observable<void> {
        return this.backend.put<void>(`/v2/credentials/update-pin`, {
            userId,
            pin: data.pin
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsUpdatePassword(userId: string, data: CredentialByUserData): Observable<void> {
        return this.backend.put<void>(`/v2/credentials/update-edit-password`, {
            userId,
            password: sha1(data.password)
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userCredentialsCheckPin(userId: string, pin: string): Observable<boolean> {
        return this.backend.get<boolean>(`/v2/credentials/check-pin`, { userId, pin });
    }
}
