import { Injectable } from '@angular/core';
import { Role } from '../types/users.types';

export interface RoleEntity {
    key: string;
    value: string;
    orderId: number;
}

export const roles: RoleEntity[] = [
    {
        key: 'roles-chooser.service',
        value: Role.service,
        orderId: 0
    },
    {
        key: 'roles-chooser.owner',
        value: Role.owner,
        orderId: 1
    },
    {
        key: 'roles-chooser.member',
        value: Role.member,
        orderId: 2
    },
    {
        key: 'roles-chooser.guest',
        value: Role.guest,
        orderId: 3
    }
];

@Injectable({ providedIn: 'root' })
export class RoleService {
    get roles(): RoleEntity[] {
        return roles;
    }
}
