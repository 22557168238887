import { PrivateIp } from '../types/units.types';

export interface UnitActivate {
    serialNumber: string;
    fake: boolean;
}

export interface UnitActivated {
    serialNumber: string;
    unitId: string;
}

export interface UnitRegister {
    serialNumber: string;
    fake?: boolean;
    hardwareVersion?: string;
    softwareVersion?: string;
    privateIp?: PrivateIp;
}

export interface UnitRegistered {
    serialNumber: string;
    unitId: string;
    unitName: string;
    accessToken: string;
    refreshToken: string;
    linked: boolean;
}

export interface UnitJoinToUser {
    serialNumber: string;
}

export interface UnitJointToUser {
    unitId: string;
}

export interface UnitByUserDelete {
    userId: string;
    unitId: string;
}

export interface UnitIndoorCheck {
    enable?: boolean;
}

// export interface UnitIndoor extends UnitEntity {
//     user: UserByUnitEntity;
//     contacts: UsersIndoor;
// }
// export interface Units {
//     outdoor: UnitEntity[];
// }
