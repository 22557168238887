import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-input-text-nf',
    templateUrl: './item-input-text.component.html',
    styleUrls: ['./item-input-text.component.scss']
})
export class ItemInputTextNFComponent {
    @Input() checked: boolean;
    @Input() title: string;
    @Input() theme: AppTheme;
    @Input() itemToggleClass: string;
    @Input() inputValue: string = '';
    @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();

    onInputChange() {
        this.inputValueChange.emit(this.inputValue);
    }
}
