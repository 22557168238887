import { HttpErrorResponse } from '@angular/common/http';
import { Group } from '../types/users.types';

export interface TabModel {
    title: string;
    icon: string;
    tab?: string;
    url?: string;
    path?: string;
    group?: Group;
    headerTitle?: string;
}

export interface HeaderRouteData {
    title?: string;
    hint?: string;
    backRoute?: string;
    helper?: string;
}

export enum DisplayMode {
    loading = 'loading',
    existing = 'existing',
    registered = 'registered',
    error = 'error',
    unknown = 'unknown',
    activate = 'activate',
    activated = 'activated',
    login = 'login',
    policy = 'policy',
    done = 'done',
    alreadyBound = 'alreadyBound',
    notFound = 'notFound',
    main = 'main',
    bound = 'bound',
    timeout = 'timeout',
    timer = 'timer',
    forgot = 'forgot',
    requested = 'requested',
    restore = 'restore',
    restored = 'restored',
    register = 'register',
    termConditionApp = 'termConditionApp',
    termConditionGoogleAccount = 'termConditionGoogleAccount',
    deviceFamilies = 'deviceFamilies',
    deviceTypes = 'deviceTypes',
    name = 'name',
    coordinates = 'coordinates',
    deviceName = 'deviceName',
    deviceRoom = 'deviceRoom',
    deviceConfirm = 'deviceConfirm',
    scenarioName = 'scenarioName',
    scenarioRoom = 'scenarioRoom',
    scenarioOptions = 'scenarioOptions',
    ruleDeviceFamily = 'ruleDeviceFamily',
    ruleDevice = 'ruleDevice',
    ruleItem = 'ruleItem',
    ruleOperator = 'ruleOperator',
    ruleDelay = 'ruleDelay',
    ruleItemValue = 'ruleItemValue',
    ruleRepeat = 'ruleRepeat',
    viewCreation = 'viewCreation',
    viewRooms = 'viewRooms',
    viewScenarios = 'viewScenarios',
    viewDevices = 'viewDevices',
    viewUsers = 'viewUsers',
    selectSensors = 'selectSensors',
    selectNotifiers = 'selectNotifiers',
    selectPrealarmNotifiers = 'selectPrealarmNotifiers',
    selectAlarmNotifiers = 'selectAlarmNotifiers',
    selectStateNotifiers = 'selectStateNotifiers',
    selectActuators = 'selectActuators',
    selectEnvSensor = 'selectEnvSensor',
    selectSystems = 'selectSystems',
    selectPipe = 'selectPipe',
    selectThermostat = 'selectThermostat'
}

export enum DisplayThermostat {
    setProfile = 'setProfile',
    chrono = 'chrono',
    comfort = 'comfort',
    economy = 'economy',
    protection = 'protection',
    standby = 'standby'
}

export enum Result {
    malformedRequest = 'MALFORMED_REQUEST',
    alreadyExists = 'ALREADY_EXISTS',
    alreadyBound = 'ALREADY_BOUND',
    notFound = 'NOT_FOUND',
    notValid = 'NOT_VALID',
    notEnable = 'NOT_ENABLE',
    wrongCredentials = 'WRONG_CREDENTIALS',
    tooManyLogins = 'TOO_MANY_LOGINS',
    privacyCheckRequired = 'PRIVACY_CHECK_REQUIRED',
    missingPhoneNumber = 'MISSING_PHONENUMBER'
}

export type GlobalError = Pick<HttpErrorResponse, 'message' | 'status' | 'statusText'>;

export enum ConnectionType {
    http = 'http',
    socketio = 'socketio'
}

export enum EntityView {
    usersIndoor = 'usersIndoor',
    usersOutdoor = 'usersOutdoor',
    usersPending = 'usersPending',
    usersAdmitted = 'usersAdmitted'
}

export interface ResultPaginated<T> {
    result: T[];
    pageState: string;
}
export interface Pagination {
    fetchSize: number;
    pageState: string;
}
