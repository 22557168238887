<ion-list [formGroup]="form">
    <ion-radio-group [value]="optionValue" [formControlName]="controlName">
        <ion-item-sliding *ngFor="let option of optionsInf; trackBy: trackOption" [disabled]="!showSliding">
            <ion-item button detail="false" (click)="radioGroupChange(option)">
                <ion-thumbnail *ngIf="option.avatar" slot="start">
                    <svg-icon [class]="'avatar-' + theme" [src]="'assets/ha/svg/' + option.avatar + '.svg'" [svgStyle]="{ 'height.px': 35 }"></svg-icon>
                </ion-thumbnail>
                <ion-icon *ngIf="option.icon" slot="start" [name]="option.icon" [color]="theme" size="large"></ion-icon>
                <ion-thumbnail *ngIf="option.thumbnail">
                    <ion-img [src]="option.thumbnail"></ion-img>
                </ion-thumbnail>
                <ion-label>
                    <ion-text>{{ option.name }}</ion-text>
                    <br />
                    <ion-text *ngIf="option.tags" style="font-size: x-small">
                        <ng-container *ngFor="let tag of option.tags">{{ tag.name }}</ng-container>
                    </ion-text>
                </ion-label>
                <ion-badge mode="ios" *ngIf="option.valueSwipeEnd" [color]="theme">
                    {{ 'room.insides' | translate }}
                    {{ option.valueSwipeEnd }}
                </ion-badge>
                <ion-radio [ngClass]="contentClass" *ngIf="!option.disabled" slot="end" [color]="theme" [value]="option.value"></ion-radio>
            </ion-item>
            <ion-item-options *ngIf="option.valueSwipeStart" side="start" (ionSwipe)="swipeStart(option.valueSwipeStart)">
                <ion-item-option style="--background: none">
                    <ion-fab-button size="small" [color]="theme" (click)="swipeStart(option.valueSwipeStart)">
                        <ion-icon name="arrow-up"></ion-icon>
                    </ion-fab-button>
                </ion-item-option>
            </ion-item-options>
            <ion-item-options *ngIf="option.valueSwipeEnd" side="end" (ionSwipe)="swipeEnd(option.value)">
                <ion-item-option style="--background: none">
                    <ion-fab-button size="small" [color]="theme" (click)="swipeEnd(option.value)">
                        <ion-icon name="arrow-down"></ion-icon>
                    </ion-fab-button>
                </ion-item-option>
            </ion-item-options>
        </ion-item-sliding>
    </ion-radio-group>
</ion-list>
<ion-infinite-scroll threshold="80%" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data..."></ion-infinite-scroll-content>
</ion-infinite-scroll>
