import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppTheme } from 'src/app/types/users.types';

import { AppService } from 'src/app/services/application.service';
import { ClientConnected } from 'src/app/models/clients.models';
import { TokenUserPayload } from 'src/app/models/token.models';

@Component({
    selector: 'user-client-card',
    templateUrl: './component.html',
    styleUrls: ['./component.scss']
})
export class UserClientCardComponent implements OnInit {
    @Input() theme: AppTheme;
    @Input() client: ClientConnected;
    @Output() userClientDelete$ = new EventEmitter<Omit<TokenUserPayload, 'username'>>();
    public osAvatar: string;
    public browserAvatar: string;
    public browserName: string;
    public osName: string;
    constructor(private appService: AppService) {}

    ngOnInit(): void {
        this.osName = this.client?.os?.name ?? '';
        this.browserName = this.client?.browser?.name ?? '';

        this.osAvatar = this.appService?.osAvatar[this.client.os.name] ?? this.appService.osAvatar?.default;
        this.browserAvatar = this.appService?.browserAvatar[this.client.browser.name] ?? this.appService.browserAvatar?.default;
    }

    public disconnect = (): void => {
        const { userId, clientId } = this.client;
        this.userClientDelete$.emit({ userId, clientId });
    };
}
