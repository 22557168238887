<ion-content [ngClass]="contentClass">
    <item-section-header [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()" [title]="'zerotier.add-modal'"></item-section-header>
    <ion-list lines="full" class="ion-padding">
        <form [formGroup]="form" class="ion-padding">
            <item-input-text label="Network Id" [theme]="theme" controlName="networkId"></item-input-text>
        </form>
        <ion-button (click)="saveSettings()" class="solid" [disabled]="!form.valid" expand="block">
            {{ 'button.submit' | translate }}
        </ion-button>
    </ion-list>
</ion-content>
