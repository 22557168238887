import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UnitEntity } from 'src/app/models/units.models';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';
import { VpnModalAddComponent } from '../vpn-modal-add/vpn-modal-add.component';
import { SocketioService } from 'src/app/services/socketio.service';
import { SubscriptionKind } from 'src/app/types/socketio.types';

@Component({
    selector: 'vpn-modal-list',
    templateUrl: './vpn-modal-list.component.html',
    styleUrls: ['./vpn-modal-list.component.scss']
})
export class VpnModalListComponent implements OnInit, OnDestroy {
    public contentClass: string;

    @Input() theme: AppTheme;
    @Input() unit: UnitEntity;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        public modalController: ModalController,
        private platformService: PlatformService,
        private translate: TranslateService,
        private socketioService: SocketioService
    ) {
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackVpns = (index: number, itemObject: any): string => {
        return itemObject.nwid;
    };
    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async openModal(event: Event) {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal1 = await this.modalController.getTop();
        const modal2: HTMLIonModalElement = await this.modalController.create({
            component: VpnModalAddComponent,
            componentProps: {
                theme: this.theme,
                unit: this.unit
            },
            breakpoints,
            initialBreakpoint
        });
        await modal2.present();
        const { data } = await modal2.onWillDismiss();
        if (data && data.returnToParent) {
            await modal1.dismiss();
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose(): void {
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy(): void {}
}
