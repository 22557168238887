import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityView } from 'src/app/models/application.models';
import { UserEntity, UserPendingEntity } from 'src/app/models/users.models';
import { PlatformService } from 'src/app/services/platform.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'outdoor-user-item',
    templateUrl: './outdoor-user-item.page.html',
    styleUrls: ['./outdoor-user-item.page.scss']
})
export class OutdoorUserItem implements OnInit {
    public usersPending: UserPendingEntity[];
    public avatar: string;
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() email: string;
    @Input() set imageDataUrls(value: UserEntity) {
        this.usersService.userDownloadImage(value.userId).subscribe(async (image) => {
            if (image) {
                const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => (this.avatar = <string>reader.result);
            } else {
                this.avatar = 'assets/backgrounds/user-default.webp';
            }
        });
    }

    @Input() userEnable: boolean;
    @Input() detail = true;
    @Input() set users(value: UserPendingEntity[]) {
        this.usersPending = value;
    }
    @Input() entityView: EntityView;
    public className: string;
    public classEmail: string;

    @Output() userEdit$ = new EventEmitter();
    @Output() userPendingReinvite$ = new EventEmitter();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private platformService: PlatformService, private usersService: UsersService) {
        this.setStyle();
        this.platformService.resize.subscribe(async () => this.setStyle());
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userEdit = (): void => this.userEdit$.emit();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userPendingReinvite = (): void => this.userPendingReinvite$.emit();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            this.className = 'text-input-roboto-regular-16';
            this.classEmail = 'notification-title-regular-13 display-block';
        }
        if (this.platformService.isSmartphone()) {
            (this.className = 'text-input-roboto-regular-16'), (this.classEmail = 'label-items-mobile');
        }
        if (this.platformService.isTablet()) {
            (this.className = 'text-input-tablet'), (this.classEmail = 'label-items-mobile');
        }
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    get EntityView(): typeof EntityView {
        return EntityView;
    }
}
