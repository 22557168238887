<ion-item button (click)="openModal()" class="ion-no-padding ion-no-margin itemHeight rowLine" detail="false" lines="none">
    <ion-grid fixed>
        <ion-row class="ion-no-padding ion-no-margin">
            <ion-col size="9.5">
                <div class="divContainer">
                    <ion-label position="floating">
                        <ion-text class="label-item-roboto-regular-13 ion-no-margin ion-no-padding" [ngClass]="'medium-' + theme">
                            {{ 'unit.label.vpn.readonly' | translate }}
                        </ion-text>
                    </ion-label>
                    <ion-input class="text-input-roboto-regular-16" type="text" [value]="'unit.vpn.readonly' | translate" [readonly]="true"></ion-input>
                </div>
            </ion-col>
            <ion-col size="2" class="colFlex"></ion-col>
            <ion-col size="0.5" class="colFlex">
                <ion-icon size="small" name="chevron-forward"></ion-icon>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>
