import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { CardAddItemModule } from '../devices/shared/card-add-item/card-add-item.module';
import { DateTimeRangeChooserModule } from '../shared/choosers/date-time-range-chooser/date-time-range-chooser.module';
import { LanguageChooserPageModule } from '../shared/choosers/language-chooser/language-chooser.module';
import { RoleChooserPageModule } from '../shared/choosers/role-chooser/role-chooser.module';
import { DateTimeWindowModule } from '../shared/date-time-window/date-time-window.module';
import { ItemInputRadioModule } from '../shared/item-input-radio/item-input-radio.module';
import { ItemInputsModule } from '../shared/item-inputs/item-inputs.module';
import { ItemSectionHeaderModule } from '../shared/item-section-header/item-section-header.module';
import { PageIntroModule } from '../shared/page-intro/page-intro.module';
import { AccessConfigurationComponent } from './access-configuration/access-configuration.component';
import { AdmittedUserItemComponent } from './admitted-user-item/admitted-user-item.component';
import { ChangeCredentialsModalComponent } from './change-credentials-modal/change-credentials-modal.component';
import { ChangeUserdataModalComponent } from './change-userdata-modal/component';
import { UserClientCardComponent } from './connected-client-card/component';
import { UserInviteModalComponent } from './invite/invite-user.modal.component';
import { PendingUserItemComponent } from './pending-user-item/pending-user-item.component';
import { AdmittedUserEditModalComponent } from './admitted-user-edit-modal/admitted-user-edit-modal.component';
import { ViewChooserPageModule } from '../shared/choosers/view-chooser/view-chooser.module';
import { PendingUserEditModalComponent } from './pending-user-edit-modal/pending-user-edit-modal.component';
import { ItemLabelReadoutModule } from '../shared/item-label-readout/item-label-readout.module';
import { SectionHeaderBackgroundModule } from '../shared/section-header-background/section-header-background.module';
import { StatusBadgeModule } from '../shared/status-badge/status-badge.module';
import { OutdoorUserItem } from './outdoor-user-item/outdoor-user-item.page';
import { OutdoorPendingUserEditModalComponent } from './outdoor-pending-user-item/outdoor-pending-user-edit-modal/outdoor-pending-user-edit-modal.component';

@NgModule({
    declarations: [
        UserClientCardComponent,
        ChangeCredentialsModalComponent,
        AccessConfigurationComponent,
        ChangeUserdataModalComponent,
        AdmittedUserItemComponent,
        PendingUserEditModalComponent,
        AdmittedUserEditModalComponent,
        PendingUserItemComponent,
        UserInviteModalComponent,
        OutdoorUserItem,
        OutdoorPendingUserEditModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ItemInputsModule,
        ItemInputRadioModule,
        ItemSectionHeaderModule,
        RoleChooserPageModule,
        LanguageChooserPageModule,
        DateTimeRangeChooserModule,
        DateTimeWindowModule,
        LanguageChooserPageModule,
        ItemLabelReadoutModule,

        SectionHeaderBackgroundModule,
        ReactiveFormsModule,
        ViewChooserPageModule,
        ItemSectionHeaderModule,
        PageIntroModule,
        CardAddItemModule,
        StatusBadgeModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        UserClientCardComponent,
        ChangeCredentialsModalComponent,
        AccessConfigurationComponent,
        ChangeUserdataModalComponent,
        AdmittedUserItemComponent,
        AdmittedUserEditModalComponent,
        PendingUserItemComponent,
        UserInviteModalComponent,
        OutdoorUserItem,
        OutdoorPendingUserEditModalComponent
    ]
})
export class UsersFeaturesModule {}
