<item-section-header title="headers.units-list-add.unit-binding" [iconClose]="true" (closeModal$)="modalClose()"></item-section-header>
<ion-content [ngClass]="contentClass">
    <form [formGroup]="unitform" class="ion-no-margin ion-no-padding">
        <ng-container [ngSwitch]="display">
            <!-- Inviamo il serial number da linkare -->
            <ng-container *ngSwitchCase="'name'">
                <ion-grid class="ion-no-margin ion-no-padding">
                    <ion-row class="ion-justify-content-center ion-align-items-center ion-margin">
                        <ion-col size="12" class="ion-text-center">
                            <ion-label class="h2-roboto-medium-22 ion-padding">
                                <ion-text>{{ 'unit.add.rename-unit' | translate }}</ion-text>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-center ion-align-items-center ion-padding ion-margin">
                        <ion-col size="12" class="ion-text-center">
                            <!-- chiedere a vale se ci va un'immagine di default o l'utente lo può caricare -->
                            <ion-thumbnail (click)="imageUpload()" class="pointer">
                                <ion-img [src]="background"></ion-img>
                            </ion-thumbnail>
                        </ion-col>
                    </ion-row>

                    <ion-row class="ion-justify-content-center ion-align-items-center">
                        <ion-col size="12" class="ion-text-center">
                            <item-input-text [theme]="theme" label="unit.unitName" controlName="unitName"></item-input-text>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                <ion-button class="solid ion-margin-top" size="default" (click)="goToCoordinate()" expand="full">
                    {{ 'button.continue' | translate | uppercase }}
                </ion-button>
            </ng-container>

            <!-- Attesa del click sulla centrale -->
            <ng-container *ngSwitchCase="'coordinates'">
                <ion-grid class="ion-no-margin ion-no-padding">
                    <ion-row class="ion-justify-content-center ion-align-items-center ion-margin">
                        <ion-col size="12" class="ion-text-center">
                            <ion-label class="h2-roboto-medium-22 ion-padding">
                                <ion-text>{{ 'unit.add.address-unit' | translate }} {{ unitform.get('unitName')?.value }}?</ion-text>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-center ion-align-items-center">
                        <ion-col size="12">
                            <item-input-text
                                [label]="'Inserisci indirizzo'"
                                [theme]="theme"
                                controlName="address"
                                [invalidText]="invalidAddress"
                            ></item-input-text>
                            <ion-button class="solid" expand="full" (click)="locateAddress()">{{ 'button.confirm' | translate | uppercase }}</ion-button>
                            <sub *ngIf="invalidAddress" class="card-subtitle-roboto-medium-11 error ion-no-margin ion-no-padding" style="margin-top: 13px">
                                {{ invalidAddress }}
                            </sub>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ng-container>
        </ng-container>
    </form>
</ion-content>
