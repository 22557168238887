/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    Configurazione da far girare per lo sviluppo in staging (su AWS).
    Si compila con il comando yarn build:staging
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/

import pack from '../../package.json';

export const environment = {
    appVersion: `staging.${pack.version}`,
    name: 'staging',
    fakeUsers: false,
    fakeUnits: false,
    skipTwoFA: false,
    skipCaptcha: false,
    frontendUrl: 'https://staging-app.lilitech.com',
    backendUrl: 'https://staging-api.lilitech.com',
    i18nPrefix: '/assets/i18n/',
    recaptcha: {
        siteKey: '6LfnoNEaAAAAAIcD2mk533alBlVsegwXVQMF51-l'
    },
    map: {
        mapCenter: [7.7107478424773825, 45.098946719040924]
    },
    weather_Api_Key: '1590160abe5eb493be17f0dc046d4da5'
};
