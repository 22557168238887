import { Component, EventEmitter, Output } from '@angular/core';
import packageJson from '../../package.json';
import { MenuController, ToastController } from '@ionic/angular';
import { Subscription, filter, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserEntity } from './models/users.models';
import { UsersService } from './services/users.service';
import { AppTheme, Language } from './types/users.types';
import { PredefinedColors } from '@ionic/core';
import { SocketioService } from './services/socketio.service';
import { SwUpdate, UnrecoverableStateEvent, VersionReadyEvent } from '@angular/service-worker';
import { PlatformService } from './services/platform.service';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public closeEnable: boolean;
    public loggedIn = false;
    private subs: Subscription;
    private loggedUserEntity: UserEntity;
    public theme: AppTheme;
    public swVersion: string;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        private translate: TranslateService,
        public platformService: PlatformService,
        public toastController: ToastController,
        public menu: MenuController,
        private authService: AuthService,
        private usersService: UsersService,
        private swUpdate: SwUpdate,
        private socketioService: SocketioService //private socketEventListenerService: SocketEventListenerService,
    ) {
        this.subs = new Subscription();

        //Il bottone chiudi deve essere presente solo in mobile
        if (this.platformService.isSmartphone()) {
            this.closeEnable = true;
        } else {
            this.closeEnable = false;
        }

        //Applichiamo la lingua di default
        this.translate.setDefaultLang(Language.it);
        // console.log(`Setting default language: ${Language.it}`);

        //Estraiamo la lingua del browser
        const browserLanguage = navigator.language;
        const languages = Object.values(Language);
        //Impostiamo un default
        let currentLanguage = Language.it;
        for (const language of languages) {
            if (language.includes(browserLanguage)) {
                //La lingua del browser è supportata
                currentLanguage = language;
                break;
            }
        }
        //Applichiamo la Lingua del browser
        if (this.translate.currentLang !== currentLanguage) {
            this.translate.use(currentLanguage);
            // console.log(`Setting browser language: ${currentLanguage}`);
        }

        //Gestiamo i dati dell'utente loggato
        this.subs.add(
            this.usersService.loggedUserObserver.subscribe((user) => {
                //console.log('app.component received loggedUserEntity', Object.assign({}, user));
                this.loggedUserEntity = user;
                if (this.loggedUserEntity) {
                    this.loggedIn = true;
                    this.theme = this.loggedUserEntity.theme;
                    const { language, theme } = this.loggedUserEntity;
                    //Applichiamo la lingua utente
                    // console.log(`Setting user language: ${language}`);
                    this.translate.use(language);
                    //Applichiamo il tema dell'utente
                    // console.log(`Setting user theme: ${theme}`);
                    document.body.setAttribute('color-theme', theme);

                    // this.cookToast('alerts.update.success', 'success');
                } else {
                    this.loggedIn = false;
                }
            })
        );

        this.swVersion = packageJson.version;

        //sw is enabled
        if (this.swUpdate.isEnabled) {
            // console.log('Software update is enabled');
            const updatesAvailable = this.swUpdate.versionUpdates.pipe(
                filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                map((evt) => ({
                    current: evt.currentVersion.hash,
                    available: evt.latestVersion.hash
                }))
            );
            updatesAvailable.subscribe({
                next: async (result) => {
                    // console.log(`A new app version is available!`);
                    alert(this.translate.instant('alerts.update.newer-version'));
                    try {
                        const success = await this.swUpdate.activateUpdate();
                        const { current, available } = result;

                        // console.log(`The app will be updated to a new version`);
                        //this.swUpdateStatus('alerts.update.success', 'success', `Current ver. ${available} - Previous ver.${current} `);
                        // this.cookToast('alerts.update.success', 'success');
                        this.cookToast('success', 'toast.app.update-newer-version');
                    } catch (error) {
                        console.error(`Software update error: ${error}`);
                        // this.cookToast('alerts.update.error', 'danger', error);
                        this.cookToast('danger', 'Software update error');
                    }
                }
            });
            this.swUpdate.unrecoverable.subscribe({
                next: (result: UnrecoverableStateEvent) => {
                    console.warn(`The app is in a broken state and a full page reload is required: ${result.reason}`);
                    this.cookToast('warning', `The app is in a broken state and a full page reload is required: ${result.reason}`);
                    document.location.reload();
                }
            });
        } else {
            // console.log('Software update is disabled');
        }

        this.socketioService.init();

        //this.socketEventListenerService.init();
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    close(): void {
        this.menu.close();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    logout(): void {
        this.menu.close().then(() => {
            this.authService.logOut();
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public ionDidOpen(event): void {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ionDidClose(): void {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async cookToast(color: PredefinedColors, message?: string): Promise<void> {
        const toast = await this.toastController.create({
            message: this.translate.instant(message),
            // header,
            // message,
            icon: '1633',
            position: 'middle',
            duration: 4000,
            color
        });

        toast.present();

        toast.onDidDismiss().then(() => {
            document.location.reload();
        });
    }
}
