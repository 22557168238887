<item-section-header [theme]="theme" [iconClose]="true" [title]="title" (closeModal$)="modalClose()"></item-section-header>
<ion-content [formGroup]="form" [ngClass]="contentClass" [fullscreen]="true" class="ion-padding">
    <ion-list>
        <ion-item *ngIf="searchbar" class="searchbar">
            <ion-searchbar [placeholder]="'avatar-chooser.search-avatar' | translate" (ionChange)="filterOptions($event)" debounce="500"></ion-searchbar>
        </ion-item>
    </ion-list>
    <ion-accordion-group *ngIf="optionsGroups">
        <ng-container *ngFor="let options of optionsGroups | keyvalue">
            <ion-accordion>
                <ion-item slot="header">
                    <ion-label>{{ options.key | translate }}</ion-label>
                </ion-item>
                <item-input-radio-group
                    slot="content"
                    [options]="options.value"
                    [title]="options.key"
                    [theme]="theme"
                    [optionValue]="optionValue"
                    [searchTerm]="searchTerm"
                    (radioGroupChange$)="radioGroupChange($event)"
                    [controlName]="controlName"
                ></item-input-radio-group>
            </ion-accordion>
        </ng-container>
    </ion-accordion-group>
    <ng-container *ngIf="optionsGroup">
        <item-input-radio-group
            [orderBy]="orderBy"
            [options]="optionsGroup"
            [title]="title"
            [theme]="theme"
            [optionValue]="optionValue"
            [searchTerm]="searchTerm"
            (radioGroupChange$)="radioGroupChange($event)"
            [controlName]="controlName"
        ></item-input-radio-group>
    </ng-container>
</ion-content>
