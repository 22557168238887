import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SectionHeaderBackgroundPage } from './section-header-background.page';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [SectionHeaderBackgroundPage],
    exports: [SectionHeaderBackgroundPage]
})
export class SectionHeaderBackgroundModule {}
