import { EventNames } from './events-names.models';

export enum Origin {
    all = 'ALL',
    app = 'APP',
    cloud = 'CLOUD',
    unit = 'UNIT'
}

export type PayloadVoid = void;
export enum EventType {
    user = 'user',
    unit = 'unit'
}

export type WebsocketMessage<Payload = unknown> = {
    topic: string;
    eventName: EventNames;
    origin: Origin;
    payload?: Payload;
    clientId?: string;
};

export interface SocketConnectionEntity {
    socketId: string;
    origin: Origin;
    elementId: string;
    startedAt: Date;
}
