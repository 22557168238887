<ng-container *ngIf="enable">
    <ion-list class="ion-no-padding margin">
        <!-- Orologio indoor -->
        <clock
            *ngIf="indoor"
            [theme]="theme"
            [timeZone]="timeZone"
            [unitOnline]="unitOnline"
            [unitName]="unitName"
            [language]="language"
            [hour12]="hour12"
        ></clock>
    </ion-list>
    <!-- Lista delle voci di menu -->
    <ion-list class="ion-no-padding">
        <ng-container *ngFor="let item of menuItems; trackBy: trackItems">
            <ion-menu-toggle menu="monitor" auto-hide="false">
                <ion-item detail="false" routerDirection="root" lines="none" [routerLink]="[item.url]" [color]="item.color">
                    <ion-icon
                        style="padding-right: 0; margin-right: 15px"
                        size="small"
                        slot="start"
                        [name]="item.icon"
                        [ngStyle]="{ color: item.textColor }"
                    ></ion-icon>
                    <!-- Solo per il menu anomalie -->
                    <ng-container *ngIf="item.url.includes('anomalies')">
                        <!-- In caso outdoor -->
                        <ion-badge mode="ios" style="margin-left: 5%" size="small" *ngIf="!indoor && outdoorAnomalies !== 0" slot="end" [color]="theme">
                            {{ outdoorAnomalies }}
                        </ion-badge>
                        <!-- In caso indoor -->
                        <ion-badge mode="ios" style="margin-left: 5%" size="small" *ngIf="indoor && indoorAnomalies !== 0" slot="end" [color]="theme">
                            {{ indoorAnomalies }}
                        </ion-badge>
                    </ng-container>
                    <ng-container *ngIf="item.url.includes('notifications')">
                        <!-- In caso outdoor -->

                        <!-- In caso indoor -->
                        <!-- <ion-badge mode=ios style="margin-left: 5%" size="small" *ngIf="indoor && indoorAnomalies !== 0" slot="end" [color]="theme">
                            {{ pushesMap.get(indoorUnitEntity.unitId) }}
                        </ion-badge> -->
                    </ng-container>
                    <ion-label [color]="item.color" class="ion-text-start">
                        <ion-text class="text-input-roboto-regular-16" [ngStyle]="{ color: item.textColor }">{{ item.title | translate }}</ion-text>
                    </ion-label>
                </ion-item>
            </ion-menu-toggle>
        </ng-container>
    </ion-list>
</ng-container>
