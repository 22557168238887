import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TextFieldTypes } from '@ionic/core';
import sha1 from 'js-sha1';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CredentialsService } from 'src/app/services/credentials.service';
import { AppTheme, Group } from 'src/app/types/users.types';
import { PlatformService } from 'src/app/services/platform.service';
import { UtilitiesComponent } from 'src/app/utilities/utilities.component';

@Component({
    selector: 'change-credentials-modal',
    templateUrl: './change-credentials-modal.component.html',
    styleUrls: ['./change-credentials-modal.component.scss']
})
export class ChangeCredentialsModalComponent extends UtilitiesComponent implements OnInit {
    @Input() theme: AppTheme;
    @Input() controlName: string;
    @Input() controlNameLabel: string;
    @Input() controlNameChangedLabel: string;
    @Input() controlNameChangedLabelConfirm: string;
    @Input() inputType: TextFieldTypes = 'text';
    @Input() title: string;
    @Input() userId: string;
    @Input() userGroup: Group;
    @Input() expand: string;
    public control: boolean;
    public contentClass: string;
    size: string;

    public get Group(): typeof Group {
        return Group;
    }

    editForm: FormGroup;

    constructor(
        public modalController: ModalController,
        private credentialsService: CredentialsService,
        private alertController: AlertController,
        private translateService: TranslateService,
        private platformService: PlatformService,
        public toastController: ToastController
    ) {
        super();
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
        this.expand = 'block';
        if (this.expand) {
            this.size = '12';
        } else {
            this.size = '6';
        }
    }

    ngOnInit(): void {
        this.credentialsService.userCredentialsNullable(this.userId, this.controlName).subscribe((isNull) => {
            if (isNull) {
                this.control = true;
                this.editForm.addControl(this.controlName, new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]));
            } else this.control = false;
        });

        this.editForm = new FormGroup(
            {
                controlNameChanged: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
                controlNameChangedConfirm: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)])
            },
            [this.controlNameConfirming]
        );
    }

    private controlNameConfirming = (form: FormGroup): ValidationErrors | null =>
        form.get('controlNameChanged').value === form.get('controlNameChangedConfirm').value ? null : { mismatch: true };

    submitRequest = (): void => {
        this.credentialsService
            .userCredentialsUpdate(
                this.userId,
                {
                    [this.controlName]: sha1(this.editForm.value.controlNameChanged.trim())
                },
                this.control ? { [this.controlName]: sha1(this.editForm.value[this.controlName].trim()) } : {}
            )
            .subscribe({
                next: () => {
                    this.modalController.dismiss();
                }
            });
    };

    public controlRebase = async (): Promise<void> => {
        const alert = await this.alertController.create({
            header: this.translateService.instant('alerts.user.password.restore.header'),
            subHeader: this.translateService.instant('alerts.user.password.restore.subHeader'),
            message: this.translateService.instant('alerts.message'),
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: this.theme
                },
                {
                    text: 'Okay',
                    role: 'delete',
                    cssClass: this.theme,
                    handler: () => {
                        this.credentialsService.userCredentialsResetRequest(this.userId, this.controlName, 0).subscribe({
                            next: () => {
                                this.modalController.dismiss();
                                this.toastMessage('toast.credentials.change', 4000, 'middle', 'information-circle', 'success');
                            }
                        });
                    }
                }
            ]
        });

        await alert.present();
    };
}
