/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export enum RootEventName {
    subscribe = 'SUBSCRIBE',
    unsubscribe = 'UNSUBSCRIBE',
    publish = 'PUBLISH',
    topics = 'TOPICS'
}

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/

export enum WebsocketEventName {
    query = 'QUERY',
    reply = 'REPLY',

    //Rooms
    insertRoom = 'DATABASE_INSERT_ROOM',
    updateRoom = 'DATABASE_UPDATE_ROOM',
    deleteRoom = 'DATABASE_DELETE_ROOM',

    //Units
    insertUnit = 'DATABASE_INSERT_UNIT',
    updateUnit = 'DATABASE_UPDATE_UNIT',
    deleteUnit = 'DATABASE_DELETE_UNIT',
    changeIPAddress = 'CHANGE_IP_ADDRESS', //Inviato dal frontend
    changeHostname = 'CHANGE_HOSTNAME', //Inviato dal frontend
    rebootInProgress = 'REBOOT_IN_PROGRESS',
    joinVpnNetwork = 'JOIN_VPN_NETWORK',
    leaveVpnNetwork = 'LEAVE_VPN_NETWORK',
    reflashQuery = 'REFLASH_QUERY',
    reflashStatus = 'REFLASH_STATUS',
    reflashRequest = 'REFLASH_REQUEST',

    runtimeQueryUnit = 'RUNTIME_QUERY_UNIT',
    runtimeReplyUnit = 'RUNTIME_REPLY_UNIT',

    //Users
    insertUsers = 'DATABASE_INSERT_USERS',
    updateUsers = 'DATABASE_UPDATE_USERS',
    deleteUsers = 'DATABASE_DELETE_USERS',
    allowMaintenance = 'ALLOW_MAINTENANCE',

    //Units By User
    insertUnitsByUser = 'DATABASE_INSERT_UNITS_BY_USER',
    updateUnitsByUser = 'DATABASE_UPDATE_UNITS_BY_USER',
    deleteUnitsByUser = 'DATABASE_DELETE_UNITS_BY_USER',

    //Users By Unit
    insertUsersByUnit = 'DATABASE_INSERT_USERS_BY_UNIT',
    updateUsersByUnit = 'DATABASE_UPDATE_USERS_BY_UNIT',
    deleteUsersByUnit = 'DATABASE_DELETE_USERS_BY_UNIT',

    //Credentials By User
    updateCredentialsByUser = 'DATABASE_UPDATE_CREDENTIALS_BY_USER',
    insertConnectionsByUser = 'DATABASE_INSERT_CONNECTIONS_BY_USER',
    updateConnectionsByUser = 'DATABASE_UPDATE_CONNECTIONS_BY_USER',
    deleteConnectionsByUser = 'DATABASE_DELETE_CONNECTIONS_BY_USER',
    insertUserLoginsByEmail = 'DATABASE_INSERT_USER_LOGINS_BY_EMAIL',
    insertMaintainersByUser = 'DATABASE_INSERT_MAINTAINERS_BY_USER',
    deleteMaintainersByUser = 'DATABASE_DELETE_MAINTAINERS_BY_USER',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    //Scenario Favorite
    insertScenarioFavorite = 'DATABASE_INSERT_SCENARIO_FAVORITE',
    updateScenarioFavorite = 'DATABASE_UPDATE_SCENARIO_FAVORITE',
    deleteScenarioFavorite = 'DATABASE_DELETE_SCENARIO_FAVORITE',
    syncroScenarioFavorite = 'DATABASE_SYNCRO_SCENARIO_FAVORITE',

    //Device Favorite
    insertDeviceFavorite = 'DATABASE_INSERT_DEVICE_FAVORITE',
    updateDeviceFavorite = 'DATABASE_UPDATE_DEVICE_FAVORITE',
    deleteDeviceFavorite = 'DATABASE_DELETE_DEVICE_FAVORITE',
    syncroDeviceFavorite = 'DATABASE_SYNCRO_DEVICE_FAVORITE',

    //Device Hierarchy
    insertDeviceHierarchy = 'DATABASE_INSERT_DEVICE_HIERARCHY',
    updateDeviceHierarchy = 'DATABASE_UPDATE_DEVICE_HIERARCHY',
    deleteDeviceHierarchy = 'DATABASE_DELETE_DEVICE_HIERARCHY',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    //Devices
    insertDevices = 'DATABASE_INSERT_DEVICES',
    updateDevices = 'DATABASE_UPDATE_DEVICES',
    deleteDevices = 'DATABASE_DELETE_DEVICES',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    //Device Schedules
    insertDeviceSchedule = 'DATABASE_INSERT_DEVICE_SCHEDULE',
    updateDeviceSchedule = 'DATABASE_UPDATE_DEVICE_SCHEDULE',
    deleteDeviceSchedule = 'DATABASE_DELETE_DEVICE_SCHEDULE',
    sincroDeviceSchedule = 'DATABASE_SYNCRO_DEVICE_SCHEDULE',

    //Scenario Schedules
    insertScenarioSchedule = 'DATABASE_INSERT_SCENARIO_SCHEDULE',
    updateScenarioSchedule = 'DATABASE_UPDATE_SCENARIO_SCHEDULE',
    deleteScenarioSchedule = 'DATABASE_DELETE_SCENARIO_SCHEDULE',
    sincroScenarioSchedule = 'DATABASE_SYNCRO_SCENARIO_SCHEDULE',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Tecnologie
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    insertTechnologies = 'DATABASE_INSERT_TECHNOLOGIES',
    updateTechnologies = 'DATABASE_UPDATE_TECHNOLOGIES',
    deleteTechnologies = 'DATABASE_DELETE_TECHNOLOGIES',

    insertTechnologiesByUnit = 'DATABASE_INSERT_TECHNOLOGIES_BY_UNIT',
    updateTechnologiesByUnit = 'DATABASE_UPDATE_TECHNOLOGIES_BY_UNIT',
    deleteTechnologiesByUnit = 'DATABASE_DELETE_TECHNOLOGIES_BY_UNIT',
    rebootTechnologiesByUnit = 'DATABASE_REBOOT_TECHNOLOGIES_BY_UNIT',
    probeTechnologiesByUnit = 'DATABASE_PROBE_TECHNOLOGIES_BY_UNIT',

    insertNotificationsBySource = 'DATABASE_INSERT_NOTIFICATIONS_BY_SOURCE',
    updateNotificationsBySource = 'DATABASE_UPDATE_NOTIFICATIONS_BY_SOURCE',
    deleteNotificationsBySource = 'DATABASE_DELETE_NOTIFICATIONS_BY_SOURCE',

    insertSocketConnections = 'DATABASE_INSERT_SOCKET_CONNECTIONS',
    updateSocketConnections = 'DATABASE_UPDATE_SOCKET_CONNECTIONS',
    deleteSocketConnections = 'DATABASE_DELETE_SOCKET_CONNECTIONS',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    //Scenarios
    insertScenario = 'DATABASE_INSERT_SCENARIO',
    updateScenario = 'DATABASE_UPDATE_SCENARIO',
    deleteScenario = 'DATABASE_DELETE_SCENARIO',

    //Scenario Triggers
    insertScenarioTrigger = 'DATABASE_INSERT_SCENARIO_TRIGGER',
    updateScenarioTrigger = 'DATABASE_UPDATE_SCENARIO_TRIGGER',
    deleteScenarioTrigger = 'DATABASE_DELETE_SCENARIO_TRIGGER',

    //Scenario Conditions
    insertScenarioCondition = 'DATABASE_INSERT_SCENARIO_CONDITION',
    updateScenarioCondition = 'DATABASE_UPDATE_SCENARIO_CONDITION',
    deleteScenarioCondition = 'DATABASE_DELETE_SCENARIO_CONDITION',

    //Scenario Actions
    insertScenarioAction = 'DATABASE_INSERT_SCENARIO_ACTION',
    updateScenarioAction = 'DATABASE_UPDATE_SCENARIO_ACTION',
    deleteScenarioAction = 'DATABASE_DELETE_SCENARIO_ACTION',

    //Views
    insertView = 'DATABASE_INSERT_VIEW',
    updateView = 'DATABASE_UPDATE_VIEW',
    deleteView = 'DATABASE_DELETE_VIEW',

    insertUsersByView = 'DATABASE_INSERT_USERS_BY_VIEW',
    updateUsersByView = 'DATABASE_UPDATE_USERS_BY_VIEW',
    deleteUsersByView = 'DATABASE_DELETE_USERS_BY_VIEW',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        NOTIFICHE, ANOMALIE E STORICO EVENTI
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    historyUnitEvent = 'DATABASE_HISTORY_UNIT_EVENT',
    historyUserEvent = 'DATABASE_HISTORY_USER_EVENT',

    insertAnomaly = 'DATABASE_INSERT_ANOMALY',
    deleteAnomaly = 'DATABASE_DELETE_ANOMALY',

    insertAnomalyByUser = 'DATABASE_INSERT_ANOMALY_BY_USER',
    deleteAnomalyByUser = 'DATABASE_DELETE_ANOMALY_BY_USER',

    insertPush = 'DATABASE_INSERT_PUSH',
    deletePush = 'DATABASE_DELETE_PUSH',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Tecnologia KNX
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    //Configurazione
    updateTechnologyKnxConfiguration = 'DATABASE_UPDATE_TECHNOLOGY_KNX_CONFIGURATION',
    deleteTechnologyKnxConfiguration = 'DATABASE_DELETE_TECHNOLOGY_KNX_CONFIGURATION',

    //Group addresses
    insertTechnologyKnxAddress = 'DATABASE_INSERT_TECHNOLOGY_KNX_ADDRESS',
    updateTechnologyKnxAddress = 'DATABASE_UPDATE_TECHNOLOGY_KNX_ADDRESS',
    deleteTechnologyKnxAddress = 'DATABASE_DELETE_TECHNOLOGY_KNX_ADDRESS',

    //Routings
    insertTechnologyKnxRouting = 'DATABASE_INSERT_TECHNOLOGY_KNX_ROUTING',
    updateTechnologyKnxRouting = 'DATABASE_UPDATE_TECHNOLOGY_KNX_ROUTING',
    deleteTechnologyKnxRouting = 'DATABASE_DELETE_TECHNOLOGY_KNX_ROUTING',

    //Routings
    insertTechnologyKnxScenariosRouting = 'DATABASE_INSERT_TECHNOLOGY_KNX_SCENARIOS_ROUTING',
    updateTechnologyKnxScenariosRouting = 'DATABASE_UPDATE_TECHNOLOGY_KNX_SCENARIOS_ROUTING',
    deleteTechnologyKnxScenariosRouting = 'DATABASE_DELETE_TECHNOLOGY_KNX_SCENARIOS_ROUTING',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Tecnologia MAYA
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    //Configurazione
    insertTechnologyMayaConfiguration = 'DATABASE_INSERT_TECHNOLOGY_MAYA_CONFIGURATION',
    updateTechnologyMayaConfiguration = 'DATABASE_UPDATE_TECHNOLOGY_MAYA_CONFIGURATION',
    deleteTechnologyMayaConfiguration = 'DATABASE_DELETE_TECHNOLOGY_MAYA_CONFIGURATION',
    //Group addresses
    insertTechnologyMayaAddress = 'DATABASE_INSERT_TECHNOLOGY_MAYA_ADDRESS',
    updateTechnologyMayaAddress = 'DATABASE_UPDATE_TECHNOLOGY_MAYA_ADDRESS',
    deleteTechnologyMayaAddress = 'DATABASE_DELETE_TECHNOLOGY_MAYA_ADDRESS',

    //Routings
    insertTechnologyMayaRouting = 'DATABASE_INSERT_TECHNOLOGY_MAYA_ROUTING',
    updateTechnologyMayaRouting = 'DATABASE_UPDATE_TECHNOLOGY_MAYA_ROUTING',
    deleteTechnologyMayaRouting = 'DATABASE_DELETE_TECHNOLOGY_MAYA_ROUTING',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Tecnologia EWELINK
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    insertTechnologiesEwelinkConfiguration = 'DATABASE_INSERT_TECHNOLOGY_EWELINK_CONFIGURATION',
    updateTechnologiesEwelinkConfiguration = 'DATABASE_UPDATE_TECHNOLOGY_EWELINK_CONFIGURATION',
    deleteTechnologiesEwelinkConfiguration = 'DATABASE_DELETE_TECHNOLOGY_EWELINK_CONFIGURATION',
    insertTechnologiesEwelinkRoutings = 'DATABASE_INSERT_TECHNOLOGY_EWELINK_ROUTINGS',
    updateTechnologiesEwelinkRoutings = 'DATABASE_UPDATE_TECHNOLOGY_EWELINK_ROUTINGS',
    deleteTechnologiesEwelinkRoutings = 'DATABASE_DELETE_TECHNOLOGY_EWELINK_ROUTINGS',
    insertTechnologiesEwelinkDevices = 'DATABASE_INSERT_TECHNOLOGY_EWELINK_DEVICES',
    updateTechnologiesEwelinkDevices = 'DATABASE_UPDATE_TECHNOLOGY_EWELINK_DEVICES',
    deleteTechnologiesEwelinkDevices = 'DATABASE_DELETE_TECHNOLOGY_EWELINK_DEVICES',

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Tecnologia Lilitech
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    technologyLilitechBootloaderQuery = 'TECHNOLOGY_LILITECH_BOOTLOADER_QUERY',
    technologyLilitechBootloaderInfo = 'TECHNOLOGY_LILITECH_BOOTLOADER_INFO',
    technologyLilitechBootloaderReflash = 'TECHNOLOGY_LILITECH_BOOTLOADER_REFLASH',

    dscvryTechnologyLilitechPending = 'DATABASE_DSCVRY_TECHNOLOGY_LILITECH_PENDING',
    upsertTechnologyLilitechPending = 'DATABASE_UPSERT_TECHNOLOGY_LILITECH_PENDING',
    deleteTechnologyLilitechPending = 'DATABASE_DELETE_TECHNOLOGY_LILITECH_PENDING',
    clnsupTechnologyLilitechPending = 'DATABASE_CLNSUP_TECHNOLOGY_LILITECH_PENDING',

    linkTechnologyLilitechAdmitted = 'DATABASE_LINK_TECHNOLOGY_LILITECH_ADMITTED',
    insertTechnologyLilitechAdmitted = 'DATABASE_INSERT_TECHNOLOGY_LILITECH_ADMITTED',
    updateTechnologyLilitechAdmitted = 'DATABASE_UPDATE_TECHNOLOGY_LILITECH_ADMITTED',
    deleteTechnologyLilitechAdmitted = 'DATABASE_DELETE_TECHNOLOGY_LILITECH_ADMITTED',

    reqestTechnologyLilitechProperty = 'DATABASE_REQEST_TECHNOLOGY_LILITECH_PROPERTY',
    returnTechnologyLilitechProperty = 'DATABASE_RETURN_TECHNOLOGY_LILITECH_PROPERTY',
    upsertTechnologyLilitechProperty = 'DATABASE_UPSERT_TECHNOLOGY_LILITECH_PROPERTY',
    deleteTechnologyLilitechProperty = 'DATABASE_DELETE_TECHNOLOGY_LILITECH_PROPERTY',
    syncroTechnologyLilitechProperty = 'DATABASE_SYNCRO_TECHNOLOGY_LILITECH_PROPERTY',

    insertTechnologyLilitechBinding = 'DATABASE_INSERT_TECHNOLOGY_LILITECH_BINDING',
    deleteTechnologyLilitechBinding = 'DATABASE_DELETE_TECHNOLOGY_LILITECH_BINDING',

    insertTechnologyLilitechScenariosBinding = 'DATABASE_INSERT_TECHNOLOGY_LILITECH_SCENARIOS_BINDING',
    deleteTechnologyLilitechScenariosBinding = 'DATABASE_DELETE_TECHNOLOGY_LILITECH_SCENARIOS_BINDING',

    //Routings
    insertTechnologyLilitechRouting = 'DATABASE_INSERT_TECHNOLOGY_LILITECH_ROUTING',
    updateTechnologyLilitechRouting = 'DATABASE_UPDATE_TECHNOLOGY_LILITECH_ROUTING',
    deleteTechnologyLilitechRouting = 'DATABASE_DELETE_TECHNOLOGY_LILITECH_ROUTING',

    //Routings
    insertTechnologyLilitechScenariosRouting = 'DATABASE_INSERT_TECHNOLOGY_LILITECH_SCENARIOS_ROUTING',
    updateTechnologyLilitechScenariosRouting = 'DATABASE_UPDATE_TECHNOLOGY_LILITECH_SCENARIOS_ROUTING',
    deleteTechnologyLilitechScenariosRouting = 'DATABASE_DELETE_TECHNOLOGY_LILITECH_SCENARIOS_ROUTING',


    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Tecnologia Lilitech
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    insertTechnologyShellyModule = 'DATABASE_INSERT_TECHNOLOGY_SHELLY_MODULE',
    updateTechnologyShellyModule = 'DATABASE_UPDATE_TECHNOLOGY_SHELLY_MODULE',
    deleteTechnologyShellyModule = 'DATABASE_DELETE_TECHNOLOGY_SHELLY_MODULE',

    insertTechnologyShellyBinding = 'DATABASE_INSERT_TECHNOLOGY_SHELLY_BINDING',
    deleteTechnologyShellyBinding = 'DATABASE_DELETE_TECHNOLOGY_SHELLY_BINDING',
   
    insertTechnologyShellyRouting = 'DATABASE_INSERT_TECHNOLOGY_SHELLY_ROUTING',
    deleteTechnologyShellyRouting = 'DATABASE_DELETE_TECHNOLOGY_SHELLY_ROUTING',

    insertTechnologyShellyScenarioBinding = 'DATABASE_INSERT_TECHNOLOGY_SHELLY_SCENARIOS_BINDING',
    deleteTechnologyShellyScenarioBinding = 'DATABASE_DELETE_TECHNOLOGY_SHELLY_SCENARIOS_BINDING',
    
    insertTechnologyShellyScenarioRouting = 'DATABASE_INSERT_TECHNOLOGY_SHELLY_SCENARIOS_ROUTING',
    deleteTechnologyShellyScenarioRouting = 'DATABASE_DELETE_TECHNOLOGY_SHELLY_SCENARIOS_ROUTING',
}

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export enum UnitEventName {
    join = 'UNIT_JOIN',
    link = 'UNIT_LINK',
    linked = 'UNIT_LINKED',
    unlinked = 'UNIT_UNLINKED',
    reboot = 'UNIT_REBOOT',
    button = 'UNIT_BUTTON',
    historySubsystemKnx = 'HISTORY_SUBSYSTEM_KNX'
}

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export enum DomainEventName {
    updateUserIndoor = 'DOMAIN_USER_INDOOR_UPDATE',
    insertUserIndoor = 'DOMAIN_USER_INDOOR_INSERT'
}

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export declare const EventNames: {
    RootEventName: typeof RootEventName;
    DatabaseEventName: typeof WebsocketEventName;
    UnitEventName: typeof UnitEventName;
    DomainEventName: typeof DomainEventName;
};

export type EventNames = RootEventName | WebsocketEventName | UnitEventName | DomainEventName;
