import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';
import { FirmwareUpdateModalComponent } from './firmware-update-modal/firmware-update-modal.component';
import { UnitEntity } from 'src/app/models/units.models';
import { SocketioService } from 'src/app/services/socketio.service';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { WebsocketMessage } from 'src/app/models/websocket.models';
import { FirmwareUpdateEntity } from 'src/app/models/firmware-update.model';

@Component({
    selector: 'firmware-update',
    templateUrl: './firmware-update.component.html',
    styleUrls: ['./firmware-update.component.scss']
})
export class FirmwareUpdateComponent implements OnInit {
    public object: FirmwareUpdateEntity[] = [];
    public newVersionAvailable = false;
    @Input() theme: AppTheme;
    @Input() unit: UnitEntity;

    constructor(private platformService: PlatformService, public modalController: ModalController, private socketioService: SocketioService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {
        this.socketioService.publishTopic<any>(this.unit.unitId, WebsocketEventName.reflashQuery);

        this.socketioService.getMessage(WebsocketEventName.reflashStatus).subscribe({
            next: (message: WebsocketMessage<any>) => {
                this.object = message.payload;
                const newVersObject = this.object.find((o) => o.newer === true);
                this.newVersionAvailable = newVersObject ? true : false;
            },
            error: (error) => {
                console.error('Errore durante la ricezione del messaggio WebSocket:', error);
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    openModal = async (): Promise<void> => {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: FirmwareUpdateModalComponent,
            componentProps: {
                theme: this.theme,
                firmwares: this.object,
                unit: this.unit
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();
    };
}
