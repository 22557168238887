<ion-content [ngClass]="contentClass">
    <item-section-header [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()" title="user.sections.time-window"></item-section-header>

    <date-time-window-chooser
        [theme]="theme"
        [daysTimeWindow]="daysTimeWindow"
        ($cancel)="modalClose()"
        ($submitRequest)="submitRequest($event)"
    ></date-time-window-chooser>
</ion-content>
