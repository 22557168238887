import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { FormGroup, FormGroupDirective } from '@angular/forms';

import { IonInput } from '@ionic/angular';

import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-input-password',
    templateUrl: './item-input-password.component.html',
    styleUrls: ['./item-input-password.component.scss']
})
export class ItemInputPasswordComponent implements OnInit {
    form: FormGroup;
    public type = 'password';

    @Input() lines = 'string';
    @Input() label: string;
    @Input() controlName: any;
    @Input() iconSlot = 'end';
    @Input() contentClass: string;

    @Input() readonly = false;

    @Input() theme: AppTheme;
    @Input() placeholder = 'Password';

    @Input() clearInput = true;
    @Input() formGroupName: string;
    @Input() pattern: RegExp;
    @Input() displayIf: string;
    @Input() icon: string;

    @Input() class: string;

    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.passwordIcon = true;

        this.form = this.formGroupName ? (this.rootFormGroup.control.get(this.formGroupName) as FormGroup) : this.rootFormGroup.control;
    }

    passwordIcon: boolean;
    showPassword = false;

    @ViewChild(IonInput, {
        read: ElementRef
    })
    input: ElementRef;

    toggleShow(): void {
        if (this.showPassword === false) {
            this.type = 'text';
        } else {
            this.type = 'password';
        }
        this.showPassword = !this.showPassword;
    }

    clearContent(): void {
        this.form.get(this.controlName.toString()).patchValue('');
    }
}
