import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OutdoorGuard } from './guards/outdoor.guard';
import { IndoorGuard } from './guards/indoor.guard';
import { LoginGuard } from './guards/login.guard';
import { WelcomeGuard } from './guards/welcome.guard';

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
const routes: Routes = [
    {
        path: 'welcome',
        canActivate: [WelcomeGuard],
        loadChildren: () => import('./pages/welcome/welcome.routing').then((m) => m.WelcomeRoutingModule)
    },
    {
        path: 'outdoor',
        canActivate: [LoginGuard, OutdoorGuard],
        loadChildren: () => import('./pages/outdoor/outdoor-routing.module').then((m) => m.OutdoorRoutingModule)
    },
    {
        path: 'indoor',
        canActivate: [LoginGuard, OutdoorGuard, IndoorGuard],
        loadChildren: () => import('./pages/indoor/indoor-routing.module').then((m) => m.IndoorRoutingModule)
    },
    {
        path: '**',
        redirectTo: 'welcome',
        pathMatch: 'full'
    }
];

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            //Abilita il debug delle rotte
            // enableTracing: true,
            paramsInheritanceStrategy: 'always',
            //TODO Capirne il funzionamento e perchè è stato commentato
            onSameUrlNavigation: 'reload'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
