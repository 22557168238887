import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemUnitAvatarComponent } from './avatar/component';
import { MapPositionModalComponent } from './map-position-modal/modal.component';
import { UnitCardComponent } from './card/card-component';
import { UnitAddModal } from './add/add-modal.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { StatusBadgeModule } from '../shared/status-badge/status-badge.module';
import { ItemSectionHeaderModule } from '../shared/item-section-header/item-section-header.module';
import { ItemInputsModule } from '../shared/item-inputs/item-inputs.module';
import { IpAddressesViewerComponent } from './ip-addresses-viewer/ip-addresses-viewer.component';
import { IpAddressesViewerModalComponent } from './ip-addresses-viewer/ip-addresses-viewer-modal/ip-addresses-viewer-modal.component';
import { UnitDiskDataComponent } from './unit-disk-data/unit-disk-data.component';
import { UnitDiskDataModalComponent } from './unit-disk-data/unit-disk-data-modal/unit-disk-data-modal.component';
import { UnitMemoryDataComponent } from './unit-memory-data/unit-memory-data.component';
import { UnitMemoryDataModalComponent } from './unit-memory-data/unit-memory-data-modal/unit-memory-data-modal.component';

import { FirmwareUpdateModalComponent } from './firmware-update/firmware-update-modal/firmware-update-modal.component';
import { FirmwareUpdateComponent } from './firmware-update/firmware-update.component';
import { UnitOutdoorUsersModal } from './unit-outdoor-users/unit-outdoor-users.modal';
import { ModalUnitDataComponent } from './modal-unit-data/modal-unit-data.component';
import { OutdoorNetworkReadonlyComponent } from './otutdoor-network-readonly/outdoor-network-readonly.component';
import { OutdoorNetworkReadonlyModalComponent } from './otutdoor-network-readonly/outdoor-network-readonly-modal/outdoor-network-readonly-modal.component';

import { ModalYmlVersionComponent } from './modal-yml-version/modal-yml-version.component';
import { OutdoorVpnReadonlyModalComponent } from './outdoor-vpn-readonly/outdoor-vpn-readonly-modal/outdoor-vpn-readonly-modal.component';
import { OutdoorVpnReadonlyComponent } from './outdoor-vpn-readonly/outdoor-vpn-readonly.component';
import { VpnModule } from './vpn/vpn.module';
import { VpnComponent } from './vpn/vpn.component';

@NgModule({
    declarations: [
        MapPositionModalComponent,
        ItemUnitAvatarComponent,
        UnitCardComponent,
        UnitAddModal,
        IpAddressesViewerComponent,
        IpAddressesViewerModalComponent,
        FirmwareUpdateComponent,
        FirmwareUpdateModalComponent,
        UnitDiskDataComponent,
        UnitDiskDataModalComponent,
        UnitMemoryDataComponent,
        UnitMemoryDataModalComponent,
        UnitOutdoorUsersModal,
        ModalUnitDataComponent,
        OutdoorNetworkReadonlyComponent,
        OutdoorNetworkReadonlyModalComponent,
        OutdoorVpnReadonlyComponent,
        OutdoorVpnReadonlyModalComponent,
        ModalYmlVersionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ItemSectionHeaderModule,
        StatusBadgeModule,
        VpnModule,
        ItemInputsModule,
        AngularSvgIconModule.forRoot(),
        NgCircleProgressModule.forRoot(),
        ReactiveFormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        MapPositionModalComponent,
        ItemUnitAvatarComponent,
        UnitCardComponent,
        UnitAddModal,
        ModalUnitDataComponent,
        IpAddressesViewerComponent,
        IpAddressesViewerModalComponent,
        FirmwareUpdateComponent,
        FirmwareUpdateModalComponent,
        UnitDiskDataComponent,
        UnitDiskDataModalComponent,
        UnitMemoryDataComponent,
        UnitMemoryDataModalComponent,
        VpnComponent,
        OutdoorNetworkReadonlyComponent,
        OutdoorNetworkReadonlyModalComponent,
        OutdoorVpnReadonlyComponent,
        OutdoorVpnReadonlyModalComponent,
        ModalYmlVersionComponent
    ]
})
export class UnitsFeaturesModule {}
