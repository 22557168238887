import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ItemInputRadioPage } from './item-input-radio.page';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { ItemInputRadioGroupComponent } from './group/group.component';
import { ItemInputRadioModalComponent } from './modal/modal.component';
import { ItemSectionHeaderModule } from '../item-section-header/item-section-header.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ItemSectionHeaderModule,
        IonicModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AngularSvgIconModule.forRoot()
    ],
    declarations: [ItemInputRadioPage, ItemInputRadioGroupComponent, ItemInputRadioModalComponent],
    exports: [ItemInputRadioPage]
})
export class ItemInputRadioModule {}
