import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export enum PopoverAction {
    edit = 'edit',
    delete = 'delete',
    favoriteAdd = 'favoriteAdd',
    favoriteDelete = 'favoriteDelete',
    technology = 'technology',
    controls = 'controls',
    graph = 'graph',
    immediateSend = 'immediateSend',
    information = 'information',
    instruction = 'instruction'
}

export enum PopoverFavouriteAction {
    add = 'add',
    delete = 'delete'
}

@Component({
    selector: 'popover-button',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {
    @Input() deletable = true;
    @Input() editable = true;
    @Input() favourite: PopoverFavouriteAction = null;
    @Input() technology = true;
    @Input() controls = true;
    @Input() graph = true;
    @Input() immediateSend = false;
    @Input() information = false;
    @Input() instruction = false;
    get PopoverAction(): typeof PopoverAction {
        return PopoverAction;
    }

    get PopoverFavouriteAction(): typeof PopoverFavouriteAction {
        return PopoverFavouriteAction;
    }

    constructor(private popoverController: PopoverController) {}

    close(action: string) {
        this.popoverController.dismiss(action);
    }
}
