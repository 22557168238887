import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ErrorDialogService } from 'src/app/services/error-dialog.service';
import { environment } from 'src/environments/environment';
import { ApiErrorService } from './errors.service';
import { GlobalError } from '../models/application.models';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    private errorDialogService: ErrorDialogService;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private logger: NGXLogger, private injector: Injector, private apiErrorService: ApiErrorService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    handleError = (errorResponse: HttpErrorResponse | (Error & { rejection: Error })): void => {
        //
        // TODO Commentato in data 14/09/2023 in attesa di uno studio più completo
        //
        // this.errorDialogService = this.injector.get(ErrorDialogService);
        // // Check if it's an error from an HTTP response
        // if (errorResponse instanceof HttpErrorResponse) {
        //     this.logger.error(`Http Error: ${JSON.stringify(errorResponse.error)}`);
        //     if (errorResponse.status !== 401 && errorResponse.status !== 403) {
        //         const { status, statusText, message: _message } = errorResponse;
        //         let message = _message;
        //         if (errorResponse.error) {
        //             if (typeof errorResponse.error === 'string') message += `<br><br>${errorResponse.error}`;
        //             if (errorResponse.error && errorResponse.error.message) message += `<br><br>${errorResponse.error.message}`;
        //         }
        //         const serverError: GlobalError = {
        //             message,
        //             status,
        //             statusText
        //         };
        //         if (environment.name === 'staging' || environment.name === 'production') {
        //             const unit = (<IndoorState>this.state.getValue().indoor).unit;
        //             const user = (<LoginState>this.state.getValue().login).user;
        //             if (user)
        //                 this.apiErrorService
        //                     .errorSendAndCreate(user.userId, {
        //                         unitId: unit && unit.unitId,
        //                         userInfo: `${user.firstName} ${user.lastName}`,
        //                         unitInfo: unit && unit.unitName,
        //                         resource: window.location.href,
        //                         error: `message: ${message} -status: ${status} -statusText: ${statusText}`
        //                     })
        //                     .subscribe();
        //         } else this.openDialog(serverError);
        //     }
        // } else {
        //     const partialError: Error = errorResponse.rejection ?? errorResponse;
        //     this.logger.error(`Client Error: ${JSON.stringify(partialError)}`);
        //     const message = partialError.stack ?? partialError.message ?? partialError.toString();
        //     const clientError: GlobalError = {
        //         message,
        //         status: 0,
        //         statusText: 'Client Error'
        //     };
        //     if (environment.name === 'staging' || environment.name === 'production') {
        //         const unit = (<IndoorState>this.state.getValue().indoor).unit;
        //         const user = (<LoginState>this.state.getValue().login).user;
        //         if (user)
        //             this.apiErrorService
        //                 .errorSendAndCreate(user.userId, {
        //                     unitId: unit.unitId,
        //                     userInfo: `${user.firstName} ${user.lastName}`,
        //                     unitInfo: unit.unitName,
        //                     resource: window.location.href,
        //                     error: `message: ${message} -statusText: Client Error`
        //                 })
        //                 .subscribe();
        //     } else {
        //         this.openDialog(clientError);
        //     }
        // }
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private openDialog = async (error: GlobalError) => {
        await this.errorDialogService.openToast(error);
    };
}
