import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-input-modal',
    templateUrl: './item-input-modal.component.html',
    styleUrls: ['./item-input-modal.component.scss']
})
export class ItemInputModalComponent implements OnInit {
    public imageDataUrl: string;
    public form: FormGroup;

    @Input() labelPosition = 'stacked';
    @Input() label: string;
    @Input() lines = 'full';
    @Input() inputType = 'text';
    @Input() controlName: string;
    @Input() contentClass: string;
    @Input() theme: AppTheme;
    @Input() readonly = false;
    @Input() iconName: string;
    @Input() avatar: string;
    @Input() value: unknown;
    @Input() formGroupName: string;
    @Input() showInputName = false;
    @Output() openModal = new EventEmitter();

    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        if (this.formGroupName) this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        else this.form = this.rootFormGroup.control;
    }

    openModalOnClick = (): void => {
        this.openModal.emit();
    };
}
