<ion-content>
    <item-section-header [theme]="theme" [iconClose]="true" [title]="title" (closeModal$)="modalClose()"></item-section-header>

    <form [formGroup]="editForm" (ngSubmit)="submitRequest()" class="modal-layout">
        <ion-label>{{ 'label.modal.subtitle.' + controlName | translate }}</ion-label>
        <item-input-text controlName="controlName" [theme]="theme" autofocus="true" [theme]="theme"></item-input-text>
        <ion-item lines="none">
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-align-items-center">
                    <ion-col size="12" class="ion-text-center">
                        <ion-button type="submit" size="default" class="solid" [disabled]="!editForm.valid" expand="full">
                            {{ 'button.confirm' | translate }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
    </form>
</ion-content>
