import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MenuRightComponent } from './right/menu-right.component';
import { MenuLeftComponent } from './left/menu-left.component';
import { UnitsFeaturesModule } from 'src/app/components/units/units-features.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { UsersFeaturesModule } from 'src/app/components/users/users-features.module';
import { UserAvatarComponent } from 'src/app/components/users/avatar/user-avatar.component';
import { ClockModule } from '../shared/clock/clock.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        UnitsFeaturesModule,
        UsersFeaturesModule,
        ClockModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [MenuRightComponent, MenuLeftComponent, UserAvatarComponent],
    exports: [MenuRightComponent, MenuLeftComponent]
})
export class MenusModule {}
