import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { Option } from '../interfaces';
import { DateTimeWindowChooserModalComponent } from './modal/modal.component';

import { AppTheme, DaysTimeWindow } from 'src/app/types/users.types';
import { PlatformService } from 'src/app/services/platform.service';
import { PopoverAction, PopoverComponent } from '../popover/popover.component';

@Component({
    selector: 'date-time-window',
    templateUrl: './date-time-window.component.html',
    styleUrls: ['./date-time-window.component.scss']
})
export class DateTimeWindowComponent {
    public deletable: boolean;
    public editable: boolean;
    public classDays: string;
    @Input() value: string;
    @Input() title: string;
    @Input() theme: AppTheme;
    @Input() daysTimeWindows: Array<DaysTimeWindow>;
    @Input() days: Option[];
    @Output() changed = new EventEmitter<Array<DaysTimeWindow>>();

    @Output() deleteDateTime$ = new EventEmitter();
    @Output() addEditDateTime$ = new EventEmitter();

    constructor(public modalController: ModalController, private popoverController: PopoverController, private platformService: PlatformService) {
        this.deletable = true;
        this.editable = true;
        this.setStyle();
        this.platformService.resize.subscribe(async () => this.setStyle());
    }

    private setStyle = () => {
        if (this.platformService.isSmartphone()) {
            [(this.classDays = 'label-item-roboto-regular-13 medium-' + this.theme)];
        }
        if (this.platformService.isDesktop()) {
            [(this.classDays = 'label-input-roboto-medium-13 medium-' + this.theme)];
        }
        if (this.platformService.isTablet()) {
            [(this.classDays = 'label-item-roboto-regular-13 medium-' + this.theme)];
        }
    };

    async addEditDateTime(index?: number): Promise<void> {
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: DateTimeWindowChooserModalComponent,
            componentProps: {
                theme: this.theme,
                daysTimeWindow: index >= 0 ? this.daysTimeWindows[index] : null
            },
            breakpoints,
            initialBreakpoint
        });

        modal.onDidDismiss().then((result: { data: DaysTimeWindow }) => {
            if (result.data) {
                const data =
                    index >= 0
                        ? this.daysTimeWindows.map((daysTimeWindow, i) => (i !== index ? daysTimeWindow : result.data))
                        : [...this.daysTimeWindows, result.data];

                this.changed.emit(data);
            }
        });

        await modal.present();
    }

    deleteDateTime(index: number): void {
        const data = this.daysTimeWindows.filter((_daysTimeWindow, i) => i !== index);
        this.changed.emit(data);
    }

    async presentPopover(e: Event, index: number) {
        const popover = await this.popoverController.create({
            component: PopoverComponent,
            event: e,
            componentProps: {
                deletable: this.deletable,
                editable: this.editable,
                controls: false,
                technology: false
            }
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        switch (data) {
            case PopoverAction.edit:
                this.addEditDateTime(index);
                break;
            case PopoverAction.delete:
                this.deleteDateTime(index);
                break;
        }
    }
}
