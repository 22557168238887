import { Component } from '@angular/core';
import { DeviceFamilyEntity } from '../models/devices-families';
import { RoomEntity } from '../models/rooms.models';
import { ScenarioEntity } from '../models/scenarios/°°°scenarios.models';
import { MenuItem } from '../components/menus/right/menu-right.service';
import { AvatarEntity } from '../services/avatar.service';
import { RoleEntity } from '../services/role.service';
import { Option } from '../components/shared/interfaces';
import { UserEventEntity } from '../models/history/history-users.models';
import { UnitEntity } from '../models/units.models';
import { AnomalyEntity } from '../models/anomalies/anomaly-units.models';
import { ViewEntity } from '../models/views.models';
import { UserEntity, UserPendingEntity } from '../models/users.models';
import { DeviceEntity } from '../models/devices/°°°devices';
import { TechnologyEntity } from '../models/technologies/technologies.models';
import { ActuatorEntity } from '../models/devices/actuator.model';
import { AutomationEntity } from '../models/devices/automation.model';
import { LightEntity } from '../models/devices/light.model';
import { SecuritySensorEntity } from '../models/devices/security-sensor.model';
import { SecurityAreaEntity } from '../models/devices/security-area.model';
import { NotifierEntity } from '../models/devices/notifier.model';
import { SocketEntity } from '../models/devices/socket.model';
import { ThermostatEntity } from '../models/devices/thermostat.model';
import { UnitEventEntity } from '../models/history/history-units.models';
import { ActionEntity } from '../models/scenarios/actions.models';
import { TriggerEntity } from '../models/scenarios/triggers.models';
import { ConditionEntity } from '../models/scenarios/conditions.model';
import { ScenarioScheduleEntity } from '../models/scenarios/°°°schedule.models';
import { AbstractControl } from '@angular/forms';
@Component({
    selector: 'track-by',
    templateUrl: './utilities.component.html',
    styleUrls: ['./utilities.component.scss']
})
export class TrackByComponent {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public trackScenarios(index: number, itemObject: ScenarioEntity): string {
        return itemObject.scenarioId;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public trackFamilies(index: number, itemObject: DeviceFamilyEntity): string {
        return itemObject.familyId;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public trackRooms(index: number, itemObject: RoomEntity): string {
        return itemObject.roomId;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackItems = (index: number, itemObject: MenuItem | any): string => {
        return itemObject.url;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackAvatars = (index: number, itemObject: AvatarEntity): string => {
        return itemObject.key;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackRoles = (index: number, itemObject: RoleEntity): string => {
        return itemObject.key;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/

    trackOption = (index: number, itemObject: Option): string => {
        return itemObject.key;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackVersions = (index: number, itemObject: any): string => {
        return itemObject.fileName;
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackEvents = (index: number, itemObject: UserEventEntity | UnitEventEntity): string => {
        return itemObject.eventId;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackUnit(index: number, itemObject: UnitEntity): string {
        return itemObject.unitId;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackAnomalies = (index: number, itemObject: AnomalyEntity): string => {
        //Dovendo ottenere una chiave univoca...
        return itemObject.anomalyId + itemObject.sourceId;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackView = (index: number, itemObject: ViewEntity): string => {
        return itemObject.viewId;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public trackUsers(index: number, itemObject: UserEntity | UserPendingEntity): string {
        return 'userId' in itemObject ? itemObject.userId : itemObject.unitId;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackDevices = (
        index: number,
        itemObject:
            | DeviceEntity
            | ActuatorEntity
            | AutomationEntity
            | LightEntity
            | SecuritySensorEntity
            | SecurityAreaEntity
            | NotifierEntity
            | SocketEntity
            | ThermostatEntity
    ): string => {
        return itemObject.deviceId;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackTechnology = (index: number, itemObject: TechnologyEntity): string => {
        return itemObject.technologyId;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackScenarioEvents = (index: number, itemObject: ActionEntity | TriggerEntity | ConditionEntity): string => {
        return 'actionId' in itemObject ? itemObject.actionId : 'triggerId' in itemObject ? itemObject.triggerId : itemObject.conditionId;
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackSchedules = (index: number, itemObject: ScenarioScheduleEntity): string => {
        return itemObject.scheduleId;
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    trackAddress = (index: number, itemObject: any): string => {
        return itemObject?.address;
    };
}

export function daysValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const days = control.value as boolean[];
    const isAnyDaySelected = days.some((day) => day); // Verifica se almeno un giorno è true
    return isAnyDaySelected ? null : { noDaysSelected: true }; // Restituisce l'errore se nessun giorno è selezionato
}
