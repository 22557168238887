import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';
import { LanguageEntity } from 'src/app/services/language.service';

@Component({
    templateUrl: './language-chooser-modal.component.html',
    styleUrls: ['./language-chooser-modal.component.scss']
})
export class LanguageChooserModalComponent implements OnInit {
    public contentClass: string;
    public langSelected: LanguageEntity;

    @Input() showValue: string;
    @Input() theme: AppTheme;
    @Input() languages: LanguageEntity[];

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private modalController: ModalController) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {
        this.langSelected = this.languages.find((l) => l.key === this.showValue);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    radioGroupChange(language: LanguageEntity): void {
        setTimeout(() => {
            this.modalController.dismiss(language);
        }, 600);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    closeModal() {
        this.modalController.dismiss();
    }
}
