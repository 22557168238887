import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToggleCustomEvent } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';
import { UtilitiesComponent } from 'src/app/utilities/utilities.component';

@Component({
    selector: 'item-input-toggle',
    templateUrl: './item-input-toggle.component.html',
    styleUrls: ['./item-input-toggle.component.scss']
})
export class ItemInputToggleComponent extends UtilitiesComponent {
    @Input() checked: boolean;
    @Input() title: string;
    @Input() description: string;
    @Input() lines = 'full';
    @Input() theme: AppTheme;
    @Input() itemToggleClass: string;
    @Input() descriptionButton: boolean = false;
    @Input() isSwitchDisabled: boolean = false;
    hours: string;
    minutes: string;
    days: string;
    milliseconds: number;
    futureTime: Date;
    @Input() descriptionValue: number;
    @Input() excludeForever: boolean;
    @Output() itemValueChange$ = new EventEmitter<boolean>();
    @Output() openModalRequest = new EventEmitter<void>();
    public labelClass: string;
    public descriptionClass: string;

    constructor(private platformService: PlatformService) {
        super();
        this.platformService.resize.subscribe(async () => this.setStyle());
        this.setStyle();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {
        this.setStyle();
    }

    ngAfterViewInit() {
        this.setStyle();
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            [(this.labelClass = 'medium-' + this.theme), (this.descriptionClass = 'label-input-roboto-medium-13 medium-' + this.theme)];
        }
        if (this.platformService.isSmartphone()) {
            (this.labelClass = 'medium-' + this.theme), (this.descriptionClass = 'label-input-roboto-medium-13 medium-' + this.theme);
        }
        if (this.platformService.isTablet()) {
            [(this.labelClass = 'medium-' + this.theme), (this.descriptionClass = 'label-input-roboto-medium-13 medium-' + this.theme)];
        }
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public itemValueChange($event?: Event) {
        const value: boolean = $event ? ($event as ToggleCustomEvent).detail.checked : !this.checked;
        this.checked = value;
        this.itemValueChange$.emit(value);
    }

    public timeWindowValueChange() {
        // if (this.descriptionValue) {
        this.openModalRequest.emit();
        // }
    }
}
