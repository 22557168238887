<ion-content [ngClass]="contentClass">
    <item-section-header title="headers.units-list-add.unit-binding" [iconClose]="true" (closeModal$)="modalClose()"></item-section-header>
    <ng-container [ngSwitch]="display">
        <!-- Inviamo il serial number da linkare -->
        <ng-container *ngSwitchCase="'main'">
            <form [formGroup]="unitCreateForm" class="ion-no-margin ion-no-padding">
                <ion-grid>
                    <ion-row class="ion-justify-content-center ion-align-items-center">
                        <ion-col size="12" class="ion-text-center"></ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-center ion-align-items-center">
                        <ion-col size="12">
                            <ion-img src="/assets/unit/serial-number.gif"></ion-img>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-center ion-align-items-center">
                        <ion-col size="12" class="ion-text-center ion-padding">
                            <ion-label class="h2-roboto-medium-22">
                                <ion-text>
                                    {{ 'unit.add.serial-placement' | translate }}
                                </ion-text>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-justify-content-center ion-align-items-center">
                        <ion-col size="12" class="ion-text-center">
                            <item-input-text
                                [theme]="theme"
                                label="unit.serialNumber"
                                controlName="serialNumber"
                                (keydown.space)="$event.preventDefault()"
                            ></item-input-text>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <ion-button *ngIf="fakeUnits" type="submit" color="lili" (click)="unitRegisterFake()" expand="block">Fake unit register!</ion-button>
                <ion-button class="solid" size="default" (click)="unitJoinToSerialNumber()" [disabled]="!serialNumber" expand="full">
                    {{ 'button.bind' | translate | uppercase }}
                </ion-button>
            </form>
        </ng-container>

        <!-- Attesa del click sulla centrale -->
        <ng-container *ngSwitchCase="'timer'">
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-align-items-center">
                    <ion-col size="12">
                        <ion-img src="/assets/unit/link-button.jpeg"></ion-img>
                    </ion-col>
                    <ion-col size="8" class="ion-text-center ion-padding ion-margin-top">
                        <ion-label>
                            <ion-text class="h2-roboto-medium-22">
                                {{ 'unit.add.press-button' | translate }}
                            </ion-text>
                        </ion-label>
                    </ion-col>

                    <ion-col size="12" class="ion-text-center ion-padding ion-margin-top">
                        <circle-progress
                            [space]="6"
                            [toFixed]="4"
                            [showTitle]="true"
                            [showSubtitle]="false"
                            [units]="''"
                            [title]="seconds"
                            [startFromZero]="false"
                            [titleFontSize]="'40'"
                            [titleColor]="'#ffffff'"
                            [percent]="percent"
                            [maxPercent]="100"
                            [outerStrokeWidth]="20"
                            [innerStrokeWidth]="0"
                            [outerStrokeColor]="'#FF4B00'"
                            [innerStrokeColor]="'#656564'"
                            class="circle-progress"
                        ></circle-progress>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <ion-button *ngIf="fakeUnits" color="lili" (click)="fakeLinkClick()" expand="block">Fake link click!</ion-button>
            <!-- <ion-button color="lili" (click)="tryAgain()" fill="outline" expand="block">
        {{ 'button.cancel' | translate }}
      </ion-button> -->
        </ng-container>

        <!-- Seriale non trovato nel database -->
        <ng-container *ngSwitchCase="'notFound'">
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-align-items-center">
                    <ion-col size="12" class="ion-text-center">
                        <ion-label style="color: #656463; font-size: large; font-weight: 700">
                            <ion-note>
                                {{ 'unit.add.not-found' | translate }}
                            </ion-note>
                        </ion-label>
                    </ion-col>
                    <ion-col size="8" sizeMd="6" class="ion-text-center">
                        <svg-icon [class]="'img-' + theme" [src]="'/assets/ha/svg/2214.svg'"></svg-icon>
                    </ion-col>
                    <ion-col size="12" class="ion-text-center">
                        <ion-label style="color: #656463; font-size: large">
                            <ion-note>
                                {{ 'unit.add.not-found-check' | translate }}
                            </ion-note>
                        </ion-label>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <ion-button [color]="theme" (click)="tryAgain()" fill="outline" expand="block">Riprova</ion-button>
        </ng-container>

        <!-- Centrale già bindata -->
        <ng-container *ngSwitchCase="'alreadyBound'">
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-align-items-center">
                    <ion-col size="12" class="ion-text-center">
                        <ion-label style="color: #656463; font-size: large; font-weight: 700">
                            <ion-note>
                                {{ 'unit.add.already-bounded' | translate }}
                            </ion-note>
                        </ion-label>
                    </ion-col>
                    <ion-col size="8" sizeMd="6" class="ion-text-center">
                        <svg-icon [class]="'img-' + theme" [src]="'/assets/ha/svg/2213.svg'"></svg-icon>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <ion-button [color]="theme" (click)="tryAgain()" fill="outline" expand="block">{{ 'button.try-again' | translate }}</ion-button>
        </ng-container>

        <!-- Click timeout -->
        <!-- <ng-container *ngSwitchCase="'timeout'">
    <ion-content>

      <ion-grid>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <ion-col size="12" class="ion-text-center">
            <ion-label style="color: #656463; font-size: large; font-weight: 700">
              <ion-note>
                {{ 'unit.add.timeout' | translate }}
              </ion-note>
            </ion-label>
          </ion-col>
          <ion-col size="12" class="ion-text-center">
            <svg-icon [class]="'img-' + theme" [src]="'/assets/ha/svg/2101.svg'"></svg-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-button [color]="theme" (click)="tryAgain()" fill="outline" expand="block">{{ 'button.try-again' | translate }}
    </ion-button>
  </ng-container> -->
    </ng-container>
</ion-content>
