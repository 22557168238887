import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { EntityView } from 'src/app/models/application.models';
import { UserByUnitEntity, UserPendingEntity } from 'src/app/models/users.models';
import { ViewEntity } from 'src/app/models/views.models';
import { PlatformService } from 'src/app/services/platform.service';
import { UsersService } from 'src/app/services/users.service';
import { ViewsService } from 'src/app/services/views.service';
import { AppTheme, DaysTimeWindow, Language, Role } from 'src/app/types/users.types';

@Component({
    selector: 'pending-user-edit-modal',
    templateUrl: './pending-user-edit-modal.component.html',
    styleUrls: ['./pending-user-edit-modal.component.scss']
})
export class PendingUserEditModalComponent implements OnInit, OnDestroy {
    @Input() unitId: string;
    public titleWindowsEnable = 'user.timeWindowsEnable';
    public theme: AppTheme;
    public daysTimeWindows: Array<DaysTimeWindow> = [];
    public timeWindowsEnable: boolean;
    public checkIn: string;
    public checkOut: string;
    public contentClass: string;
    public roleSelected: Role;
    public languageSelected: Language;

    public views: ViewEntity[];
    public view: ViewEntity = {} as ViewEntity;
    public viewsFilteredByRole: ViewEntity[] = [];
    public viewName: string;
    public form: FormGroup;
    public image = 'assets/backgrounds/user-default.webp';
    firstName: string;
    email: string;
    enable: boolean;

    @Input() showFields: string[];
    @Input() entityView: EntityView;
    @Input() inviterUser: UserByUnitEntity;

    @Input() updateOnSubmit: false;
    @Input() user: UserPendingEntity = {} as UserPendingEntity;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        private translate: TranslateService,
        public toastController: ToastController,
        private usersService: UsersService,
        public modalController: ModalController,
        private platformService: PlatformService,
        private viewsService: ViewsService
    ) {
        this.contentClass = 'ion-padding';
    }
    async ngOnInit(): Promise<void> {
        this.roleSelected = this.user.role;
        this.viewName = this.user.viewName;
        this.languageSelected = this.user.language;
        this.form = new FormGroup({
            role: new FormControl(this.user.role, { validators: Validators.required, updateOn: 'submit' }),
            viewId: new FormControl(this.user.viewId, { validators: Validators.required, updateOn: 'submit' }),
            viewName: new FormControl(this.user.viewName)
        });

        this.checkIn = this.user?.checkIn;
        this.checkOut = this.user?.checkOut;
        this.daysTimeWindows = this.user?.daysTimeWindows;
        if (this.user.role === 'member') {
            this.timeWindowsEnable = this.user.daysTimeWindows?.length > 0 ?? true;
        } else if (this.user.role === 'guest') {
            if (this.user.checkIn !== null || this.user.checkIn !== undefined) {
                this.timeWindowsEnable = true;
            } else {
                this.timeWindowsEnable = false;
            }
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose(): void {
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async presentToast(header: string): Promise<void> {
        const toast = await this.toastController.create({
            header: this.translate.instant(header),
            icon: 'information-circle',
            position: 'middle',
            duration: 4000,
            color: 'success'
        });
        toast.present();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeRole(event: any, key: string) {
        if (event.value !== this.roleSelected) {
            this.roleSelected = event.value;
            this.form.controls.role.setValue(event.value);
            this.form.controls.viewId.setValue(null);
            this.form.controls.viewName.setValue(null);
        }
        if (event.value === 'owner' || event.value === 'service') {
            this.daysTimeWindows = null;
            this.timeWindowsEnable = false;
            this.checkIn = null;
            this.checkOut = null;
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeLanguage(event: any, key: string) {
        this.languageSelected = event.value;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userPendingReinvite(): void {
        const data = {
            role: this.form.value.role,
            viewId: this.form.value.viewId
        };
        this.usersService.reinviteUser(this.user.apiKey, this.inviterUser.firstName, data).subscribe({
            next: () => {
                alert('Utente reinvitato');
            },
            error: (error: HttpErrorResponse) => {
                alert(error.message);
            }
        });
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userPendingUpdate(): void {
        if (this.roleSelected === 'member' || this.roleSelected === 'guest') {
            this.timeWindowsEnable = true;
        } else {
            this.timeWindowsEnable = false;
        }
        const data = {
            role: this.form.value.role,
            viewId: this.form.value.viewId,
            viewName: this.form.value.viewName,
            language: this.languageSelected,
            timeWindowsEnable: this.timeWindowsEnable
        };
        //Se timeWindowsEnable è true controlliamo che tipo di tempo stiamo assegnando (se da giorno x a giorno y o se per singoli giorni)
        if (this.timeWindowsEnable) {
            //Se abbiamo dei giorni dentro daysTimeWindows allora checkIn e checkOut saranno sicuramente null, resettiamo anche sul db
            //Se allDay è true escludiamo start e end
            if (this.daysTimeWindows?.length > 0 && this.roleSelected === 'member') {
                this.daysTimeWindows.forEach((day) => {
                    if (day.allday) {
                        day.start = day.end = null;
                    }
                });
                data['daysTimeWindows'] = this.daysTimeWindows;
                data['checkIn'] = null;
                data['checkOut'] = null;
            }
            //Se abbiamo checkIn e checkOut sicuramente daysTimeWindows deve essere vuoto, resettiamo anche sul db
            else if (this.checkIn && this.checkOut && this.roleSelected === 'guest') {
                data['daysTimeWindows'] = null;
                data['checkIn'] = this.checkIn;
                data['checkOut'] = this.checkOut;
                //Se il guest o il member non hanno inidicazioni temporali, resettiamo anche sul db
            } else {
                data['daysTimeWindows'] = null;
                data['checkIn'] = null;
                data['checkOut'] = null;
            }
        }
        //Se timeWindowsEnable è false resettiamo ogni riferimento al tempo
        else {
            data['daysTimeWindows'] = null;
            data['checkIn'] = null;
            data['checkOut'] = null;
        }

        this.usersService.updatePendingUser(this.user.apiKey, this.inviterUser.firstName, data).subscribe({
            next: async () => {
                await firstValueFrom(this.usersService.loadPendingUsers(this.unitId));
            },
            error: (error: HttpErrorResponse) => {
                alert(error.message);
            }
        });
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userPendingRevoke(): void {
        this.usersService.revokeUserPending(this.user.apiKey).subscribe({
            next: () => {
                alert('Utente scollegato');
            },
            error: (error: HttpErrorResponse) => {
                alert(error.message);
            }
        });
        this.modalController.dismiss();
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeView(event: any, key: string) {
        this.form.controls.viewId.setValue(event.viewId);
        this.form.controls.viewName.setValue(event.name);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    timeWindowsEnableValueChanged(newValue: boolean) {
        this.timeWindowsEnable = newValue;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    daysValueChanged(data: DaysTimeWindow[]) {
        this.daysTimeWindows = data;
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    removeTimezoneOffset(isoString: string) {
        if (isoString.length >= 20) return isoString.substring(0, 19);
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy(): void {
        this.daysTimeWindows = this.checkIn = this.checkOut = this.timeWindowsEnable = null;
    }
}
