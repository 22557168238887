import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ItemInputRadioModalComponent } from './modal/modal.component';
import { AppTheme } from 'src/app/types/users.types';
import { Option } from '../interfaces';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'item-input-radio',
    templateUrl: './item-input-radio.page.html',
    styleUrls: ['./item-input-radio.page.scss']
})
export class ItemInputRadioPage implements OnInit {
    private value: unknown;

    public optionsGroups: { [key: string]: Option[] };
    public optionsGroup: Option[];
    public form: FormGroup;
    public option: Option;
    @Input() lines = 'none';
    @Input() labelPosition = 'floating';
    @Input() label: string;
    @Input() title: string;
    @Input() inputType = 'text';
    @Input() controlName: string;
    @Input() contentClass: string;
    @Input() theme: AppTheme;
    @Input() readonly = true;
    @Input() placeholder: string;
    @Input() iconDetail = false;
    @Input() openOptionsDisable = false;
    @Input() set openOptionsModal(value: boolean) {
        if (value) this.openOptions();
    }
    @Input() searchbar = false;
    @Input() showInputName = true;
    @Input() iconName: string;
    @Input() orderBy = 'name';
    @Input() set options(value: { [key: string]: Option[] } | Option[]) {
        if (!value) this.optionsGroup = [];
        if (Array.isArray(value)) this.optionsGroup = value;
        else this.optionsGroups = value as { [key: string]: Option[] };
    }

    @Output() selected$ = new EventEmitter<string>();

    constructor(
        private platformService: PlatformService,
        public modalController: ModalController,
        private rootFormGroup: FormGroupDirective,
        private translate: TranslateService
    ) {
        this.translate.onLangChange.subscribe({
            next: () => {
                if (this.option?.key) this.option.name = this.translate.instant(this.option.key);
            }
        });
    }

    private setSelectedOption = (value: unknown): void => {
        if (this.optionsGroup) this.option = this.optionsGroup.find((o) => o.value === value || o.values?.includes(value));
        else
            for (const key in this.optionsGroups) {
                const option = this.optionsGroups[key].find((o: Option) => o.value === value || o.values?.includes(value));
                if (option) {
                    this.option = option;
                    break;
                }
            }

        if (this.option?.key) this.option.name = this.translate.instant(this.option.key);
    };

    ngOnInit(): void {
        this.form = this.rootFormGroup.control;
        this.value = this.form.controls[this.controlName].value?.value ?? this.form.controls[this.controlName].value;

        this.setSelectedOption(this.value);

        this.form.controls[this.controlName].valueChanges.subscribe((value) => {
            this.value = value?.value ?? value;
            this.setSelectedOption(this.value);
        });
    }

    openOptions = async (): Promise<void> => {
        if (this.openOptionsDisable) return;
        let breakpoints: [number, number];
        let initialBreakpoint: number;
        if (this.platformService.isSmartphone()) {
            breakpoints = [0.75, 1];
            initialBreakpoint = 0.75;
        }

        const modal: HTMLIonModalElement = await this.modalController.create({
            component: ItemInputRadioModalComponent,
            componentProps: {
                optionValue: this.value,
                theme: this.theme,
                optionsGroups: this.optionsGroups,
                optionsGroup: this.optionsGroup,
                searchbar: this.searchbar,
                title: this.title,
                orderBy: this.orderBy,
                controlName: this.controlName,
                form: this.form
            },
            breakpoints,
            initialBreakpoint
        });

        await modal.present();

        const { data, role } = await modal.onWillDismiss();

        this.selected$.emit(role);

        if (data) {
            const value = this.form.controls[this.controlName].value?.value ? data : data.value;

            this.form.controls[this.controlName].markAsDirty();
            this.form.controls[this.controlName].patchValue(value);
        }
    };
}
