import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-input-number',
    templateUrl: './item-input-number.component.html',
    styleUrls: ['./item-input-number.component.scss']
})
export class ItemInputNumberComponent {
    public form: FormGroup;
    @Input() lines = 'none';
    @Input() label: string;
    @Input() controlName: string;
    @Input() contentClass: string;
    @Input() readonly = false;
    @Input() theme: AppTheme;
    @Input() placeholder: string;
    @Input() clearInput = true;
    @Input() formGroupName: string;
    @Input() setFocus: boolean = false;
    @Input() min: number = 0;
    @Input() max: number = 100;
    @Input() step: number = 1;
    @Input() invalidText: string = 'Invalid number format';
    constructor(private rootFormGroup: FormGroupDirective) {}
    @ViewChild('ionInputElement', { read: ElementRef }) ionInputElement: ElementRef;
    ngOnInit(): void {
        this.form = this.formGroupName ? (this.rootFormGroup.control.get(this.formGroupName) as FormGroup) : this.rootFormGroup.control;
    }
    ngAfterViewInit() {
        if (this.setFocus) {
            setTimeout(() => {
                const ionInput = this.ionInputElement.nativeElement as HTMLIonInputElement;
                ionInput.setFocus();
            }, 150);
        }
    }
}
