import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

import { ViewEntity } from 'src/app/models/views.models';

@Component({
    selector: 'app-view-chooser-modal',
    templateUrl: './view-chooser-modal.component.html',
    styleUrls: ['./view-chooser-modal.component.scss']
})
export class ViewChooserModalComponent implements OnInit {
    public contentClass: string;
    public viewSelected: ViewEntity;

    @Input() showValue: string;
    @Input() theme: AppTheme;
    @Input() views: ViewEntity[];

    constructor(private modalController: ModalController) {}

    ngOnInit(): void {
        this.viewSelected = this.views.find((v) => v.name === this.showValue);
    }

    radioGroupChange(view: ViewEntity): void {
        setTimeout(() => {
            this.modalController.dismiss(view);
        }, 600);
    }
    closeModal() {
        this.modalController.dismiss();
    }
}
