<ion-header>
    <div *ngIf="!backgroundTransparent" [ngClass]="'background-'+theme" class="background-overlay" [style.background-image]="'url('+ imageDataUrl +')'"></div>

    <div class="profile-container" [ngClass]="'profile-gradient-'+theme">
        <ion-thumbnail class="profile-avatar pointer" (click)="imageUpload()">
            <ion-img [src]="imageDataUrl"></ion-img>
        </ion-thumbnail>
        <ion-badge mode="ios" color="warning" *ngIf="!enable" style="margin-right: 2px">{{ 'label.user.disabled' | translate }}</ion-badge>
        <ion-label class="ion-text-center">
            <ion-text [class]="className" [ngClass]="'section-header-title-'+theme">{{name}} {{surname}}</ion-text>
        </ion-label>
    </div>
</ion-header>
