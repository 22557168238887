import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';
import { RoleEntity } from 'src/app/services/role.service';
import { TrackByComponent } from 'src/app/utilities/track-by.component';

@Component({
    selector: 'app-role-chooser-modal',
    templateUrl: './role-chooser-modal.component.html',
    styleUrls: ['./role-chooser-modal.component.scss']
})
export class RoleChooserModalComponent extends TrackByComponent implements OnInit, AfterViewChecked {
    public contentClass: string;
    public roleSelected: RoleEntity;

    @Input() showValue: string;
    @Input() theme: AppTheme;
    @Input() roles: RoleEntity[];
    @Input() inviterRole: string;

    constructor(private modalController: ModalController) {
        super();
    }
    ngAfterViewChecked(): void {
        const array = this.roles;
        const inviterOrderId = array.find((io) => io.value === this.inviterRole);
        this.roles = this.roles.filter((r) => r.orderId >= inviterOrderId.orderId);
    }

    ngOnInit() {
        this.roleSelected = this.roles.find((r) => r.key === this.showValue);
    }

    radioGroupChange(role: RoleEntity): void {
        setTimeout(() => {
            this.modalController.dismiss(role);
        }, 600);
    }
    closeModal() {
        this.modalController.dismiss();
    }
}
