import { Injectable } from '@angular/core';
import { Language } from '../types/users.types';

export interface LanguageEntity {
    key: string;
    value: string;
    orderId: number;
}

export const languages: LanguageEntity[] = [
    {
        key: 'language-chooser.it',
        value: Language.it,
        orderId: 0
    },
    {
        key: 'language-chooser.en',
        value: Language.en,
        orderId: 1
    }
    // {
    //     key: 'language-chooser.fr',
    //     value: Language.fr,
    //     orderId: 2
    // },
    // {
    //     key: 'language-chooser.de',
    //     value: Language.de,
    //     orderId: 3
    // },
    // {
    //     key: 'language-chooser.es',
    //     value: Language.es,
    //     orderId: 4
    // }
];

@Injectable({ providedIn: 'root' })
export class LanguageService {
    get languages(): LanguageEntity[] {
        return languages;
    }
}
