import { Component } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import Vector from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { AppTheme } from 'src/app/types/users.types';

import { TranslateService } from '@ngx-translate/core';
@Component({
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.scss']
})
export class MapPositionModalComponent {
    private map: Map;
    public theme: AppTheme;
    private mapCenter: number[];
    private mapZoom: number;
    private coordinatesObj: any;

    private markerSource = new Vector();

    constructor(
        public modalController: ModalController,
        private alertController: AlertController,
        private navParams: NavParams,
        private logger: NGXLogger,
        public translate: TranslateService
    ) {}

    confirmChangeCoordinates = async (): Promise<void> => {
        await this.addressConfirm();
    };

    cancelChangeCoordinates = async (): Promise<boolean> => await this.modalController.dismiss();

    // l'inizializzazione della mappa va fatto dopo il DOM e quindi nella viewenter
    ionViewDidEnter(): void {
        //prendo i parametri della modal
        this.theme = this.navParams.get('theme');
        this.mapCenter = this.navParams.get('mapCenter');
        this.mapZoom = this.navParams.get('mapZoom');

        //default coordinate del mondo
        if (this.mapCenter.length === 0) {
            this.logger.warn('Coordinate assenti!');

            this.mapCenter = environment.map.mapCenter;
            this.mapZoom = 5;
        }

        const style = new Style({
            image: new Icon({
                src: '/assets/icons/2216.svg',
                scale: 0.5,
                color: '#f15a24'
            })
        });

        //creo la map
        this.map = new Map({
            target: 'unitMap',
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
                new VectorLayer({
                    source: this.markerSource,
                    style: style
                })
            ],
            view: new View({
                //le coordinate vanno convetite con la fromLonLat
                center: olProj.fromLonLat(this.mapCenter),
                zoom: this.mapZoom
            })
        });

        //aggiungo il marker delle coordinate iniziali
        if ((this.mapCenter[0], this.mapCenter[1])) {
            this.addMarker(this.mapCenter[0], this.mapCenter[1]);
        }

        //ogni click che viene fatto posiziona un marker alle coordinate del click
        this.map.on('click', (event) => {
            const lonLat = olProj.toLonLat(event.coordinate);
            this.addMarker(lonLat[0], lonLat[1]);
            //le coordinate vengono rimadate indietro come stringa divisa da uno spazio
            this.reverseGeocode(lonLat);
        });
    }

    private addMarker = (lon: number, lat: number): void => {
        // pulisce tutti i marker, in questo modo quello aggiuto è l'unico
        this.markerSource.clear();

        const marker = new Feature({
            geometry: new Point(olProj.transform([lon, lat], 'EPSG:4326', 'EPSG:3857'))
        });

        this.markerSource.addFeature(marker);
    };

    private reverseGeocode = async (coords): Promise<void> => {
        let tmp;

        // chiedo alle api di openmap di darmi l'indizzo di quelle coordinate e lo salvo in una variabile temporanea per aggirare lo scope
        await fetch('https://nominatim.openstreetmap.org/reverse?format=json&lon=' + coords[0] + '&lat=' + coords[1])
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                tmp = json;
            });

        this.coordinatesObj = tmp;
    };

    private addressConfirm = async (): Promise<void> => {
        const alert = await this.alertController.create({
            header: this.translate.instant('alerts.warning'),
            subHeader: this.translate.instant('alerts.map.subheader'),
            message: this.translate.instant('alerts.map.message') + `: ${this.coordinatesObj.display_name}?`,
            buttons: [
                {
                    text: this.translate.instant('button.cancel'),
                    role: 'cancel',
                    cssClass: 'theme',
                    handler: () => {
                        //se no display name viene cancellata
                        delete this.coordinatesObj.display_name;
                        this.modalController.dismiss(this.coordinatesObj);
                    }
                },
                {
                    text: this.translate.instant('button.confirm'),
                    handler: () => {
                        this.modalController.dismiss(this.coordinatesObj);
                    }
                }
            ]
        });
        await alert.present();
    };
}
