import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AnomalyEntity } from 'src/app/models/anomalies/anomaly-units.models';
import { UnitByUserEntity, UnitEntity } from 'src/app/models/units.models';
import { UserEntity } from 'src/app/models/users.models';
import { PlatformService } from 'src/app/services/platform.service';
import { TimeWindowService } from 'src/app/services/time-window.service';
import { UnitsService } from 'src/app/services/units.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'unit-card',
    templateUrl: './card-component.html',
    styleUrls: ['./card-component.scss']
})
export class UnitCardComponent implements OnInit {
    public colSize: number[];
    public className: string;
    public imageDataUrl: string;
    public status: string;
    public color: string;
    public gradient: string;
    @Input() unit: UnitByUserEntity;
    @Input() unitName: string;
    @Input() unitOnline: boolean;
    @Input() loggedUser: UserEntity;
    @Input() outdoorAnomalies: AnomalyEntity[] = [];
    @Input() notificationCount: number;
    @Input() theme: AppTheme;
    @Output() unitEnter$ = new EventEmitter();
    @Output() unitExit$ = new EventEmitter();

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(public platformService: PlatformService, private timeWindowService: TimeWindowService, private unitsService: UnitsService) {
        this.setStyle();
        this.platformService.resize.subscribe(async () => {
            this.setStyle();
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit(): void {
        this.updateStatusBadge();
        this.gradient = 'linear-gradient(180deg, rgba(241, 90, 36, 1) 0%, rgba(255, 5, 0, 1) 100%);';

        //Scarichiamo l'immagine della unit
        this.unitsService.unitDownloadImage(this.unit.unitId).subscribe((image) => {
            if (image) {
                const blob: Blob = new Blob([new Uint8Array(<Buffer>image['data'])], { type: 'image/webp' });
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => (this.imageDataUrl = <string>reader.result);
            } else {
                this.imageDataUrl = 'assets/backgrounds/unit-default.webp';
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.unitOnline && !changes.unitOnline.firstChange) {
            this.updateStatusBadge();
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    updateStatusBadge(): void {
        let anomalyUnit;
        const userCantEnter = this.rangeTime(this.unit);
        const anomaly = this.outdoorAnomalies.filter((anomaly) => anomaly.unitId === this.unit.unitId);
        if (anomaly.length > 0) {
            anomalyUnit = true;
        } else {
            anomalyUnit = false;
        }
        this.status = !this.unitOnline ? 'offline' : userCantEnter ? 'disabled' : anomalyUnit ? 'warning' : 'online';
        this.color = !this.unitOnline ? 'danger' : userCantEnter ? 'medium' : anomalyUnit ? 'warning' : 'success';
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    rangeTime(unit): boolean {
        if (!this.timeWindowService.userEnable(unit)) return true;
        if (!this.timeWindowService.userWinTime(unit)) return true;
        if (!this.unitOnline) return true;
        return false;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isSmartphone()) {
            this.className = 'mobile';
            this.colSize = [3, 9];
        }
        if (this.platformService.isDesktop()) {
            this.colSize = [4, 8];
            this.className = 'desktop';
        }
        if (this.platformService.isTablet()) {
            this.colSize = [3, 9];
            this.className = 'mobile';
        }
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    unitEnter(unitId: string) {
        this.unitEnter$.emit(unitId);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    unitExit(unitId: string) {
        this.unitExit$.emit(unitId);
    }
}
