import { Injectable } from '@angular/core';
import { Option } from '../components/shared/interfaces';
import unitBackgrounds from '../../assets/unit/backgrounds.json';
// import roomBackgrounds from '../../../assets/room/backgrounds.json';
import colors from '../../assets/colors/colors.json';
import avatars from '../../assets/ha/avatars.json';

import osAvatar from '../../assets/miscellaneous/osavatar.json';
import browserAvatar from '../../assets/miscellaneous/browseravatar.json';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppTheme, FavoriteHand, Group, Language, MeasurementSystem, Role, TimeFormat } from '../types/users.types';

// export const measurementSystems: Option[] = [
//     {
//         key: 'user.measurementSystems.international',
//         value: MeasurementSystem.international
//     },
//     {
//         key: 'user.measurementSystems.english',
//         value: MeasurementSystem.english
//     }
// ];

export const groups: Option[] = [
    {
        key: 'user.groups.administrator',
        value: Group.administrator
    },
    {
        key: 'user.groups.support',
        value: Group.support
    },
    {
        key: 'user.groups.customer',
        value: Group.customer
    }
];

export const days: Option[] = [
    {
        key: 'days.labels.monday',
        value: 0,
        selected: false
    },
    {
        key: 'days.labels.tuesday',
        value: 1,
        selected: false
    },
    {
        key: 'days.labels.wednesday',
        value: 2,
        selected: false
    },
    {
        key: 'days.labels.thursday',
        value: 3,
        selected: false
    },
    {
        key: 'days.labels.friday',
        value: 4,
        selected: false
    },
    {
        key: 'days.labels.saturday',
        value: 5,
        selected: false
    },
    {
        key: 'days.labels.sunday',
        value: 6,
        selected: false
    }
];
export const prefixNumber: Option[] = [
    {
        value: '+44',
        avatar: '/flags/110-united-kingdom',
        key: 'United Kingdom'
    },
    {
        value: '+39',
        key: 'Italy',
        avatar: '/flags/263-italy'
    },
    {
        value: '+33',
        key: 'France',
        avatar: '/flags/197-france'
    },
    {
        value: '+49',
        key: 'Germany',
        avatar: '/flags/208-germany'
    },
    {
        value: '+34',
        key: 'Spain',
        avatar: '/flags/230-spain'
    }
];

export const notificationChannels: Record<string, string> = {
    push: '2208',
    email: '2202',
    call: '3321',
    sms: 'at-outline'
};

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private showMenuSub = new Subject<{ enable: boolean; outdoor: boolean }>();
    public showMenu$ = this.showMenuSub.asObservable();
    public menuOpening$ = new BehaviorSubject(false);

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    showMenu(enable: boolean, outdoor = true): void {
        this.showMenuSub.next({ enable, outdoor });
    }

    get groups(): Option[] {
        return groups;
    }

    get avatars(): Option[] {
        return avatars.map((option: Option) => ({
            ...option,
            key: `ha-avatars.descriptions.${option.key}`,
            tags: option.tags.map((tag) => ({ ...tag, key: `ha-avatars.tags.${tag.key}` })),
            avatar: option.value as string
        }));
    }

    get days(): Option[] {
        return days;
    }

    // get measurementSystems(): Option[] {
    //     return measurementSystems;
    // }

    get unitBackgrounds(): Option[] {
        return unitBackgrounds;
    }

    get colors(): Option[] {
        return colors;
    }

    get notificationChannels(): Record<string, string> {
        return notificationChannels;
    }

    get osAvatar() {
        return osAvatar;
    }

    get browserAvatar() {
        return browserAvatar;
    }
    get prefixNumber() {
        return prefixNumber;
    }
}
