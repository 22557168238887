import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'status-badge',
    templateUrl: './status-badge.page.html',
    styleUrls: ['./status-badge.page.scss']
})
export class StatusBadgePage {
    @Input() color = 'success';
    @Input() status: string;
    @Input() slot = 'end';
    constructor() {}
}
