import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DisplayMode } from 'src/app/models/application.models';
import { UnitEntity } from 'src/app/models/units.models';
import { PlatformService } from 'src/app/services/platform.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { UnitsService } from 'src/app/services/units.service';
import { AppTheme } from 'src/app/types/users.types';
import { environment } from 'src/environments/environment';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
@Component({
    selector: 'app-modal-unit-data',
    templateUrl: './modal-unit-data.component.html',
    styleUrls: ['./modal-unit-data.component.scss']
})
export class ModalUnitDataComponent implements OnInit {
    @Input() theme: AppTheme;
    @Input() userId: string;
    @Input() unit: UnitEntity;
    @Input() imageDataUrl?: string;
    public contentClass: string;
    public background: string = 'assets/backgrounds/unit-default.webp';
    @Output() imageUpload$ = new EventEmitter();
    @Output() formDataEmitter = new EventEmitter<any>();
    display = DisplayMode.name;
    map: Map;
    unitform = new FormGroup({
        unitName: new FormControl(null, { validators: Validators.required }),
        address: new FormControl(null, { updateOn: 'blur' })
    });
    invalidAddress: string;

    constructor(
        public modalController: ModalController,
        private alertController: AlertController,
        private translate: TranslateService,
        private unitsService: UnitsService,
        private socketio: SocketioService,
        private platformService: PlatformService,
        private platform: Platform
    ) {
        this.contentClass = 'ion-padding';
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose = (): void => {
        this.modalController.dismiss();
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public imageUpload() {
        this.imageUpload$.emit();
    }
    goToCoordinate() {
        this.display = DisplayMode.coordinates;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {
        this.background = this.imageDataUrl;
    }

    locateAddress = async (): Promise<void> => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${this.unitform.get('address').value}`);

            if (!response.ok) {
                throw new Error('Errore nella richiesta al servizio di geocodifica');
            }

            const data = await response.json();

            if (!data || data.length === 0) {
                throw new Error('Indirizzo non trovato');
            }

            const { lat, lon, display_name } = data[0];

            const formData = {
                form: this.unitform.value,
                coordinates: { lat: parseFloat(lat), lon: parseFloat(lon) },
                address: display_name
            };

            this.locateAdressVerify(display_name, formData);
            // this.modalController.dismiss(formData);
        } catch (error) {
            console.error('Errore durante la geocodifica:', error.message);
            // Puoi mostrare un messaggio di errore all'utente, ad esempio usando un servizio di notifiche
            this.showError("Impossibile trovare l'indirizzo. Per favore riprova inserendo un indirizzo valido.");
        }
    };

    showError(message: string) {
        this.invalidAddress = message;
        // Implementa la logica per mostrare un messaggio di errore all'utente
        // Ad esempio, puoi usare un servizio di notifiche o un alert controller di Ionic
        console.error(message); // Temporaneo: stampa l'errore nella console
    }

    public async locateAdressVerify(adress: string, formData: any) {
        const alert = await this.alertController.create({
            header: this.translate.instant("L'indirizzo è corretto?"),
            subHeader: adress,

            buttons: [
                {
                    text: this.translate.instant('button.confirm'),
                    handler: () => {
                        this.modalController.dismiss(formData);
                    }
                },
                {
                    text: this.translate.instant('button.cancel'),
                    role: 'cancel'
                }
            ]
        });

        await alert.present();
    }
}
