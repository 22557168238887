import { Component, Input } from '@angular/core';
import { FormGroupDirective, FormGroup } from '@angular/forms';
import { PickerController } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

import { millisecondsToTime } from 'src/app/utilities/utilities';

@Component({
    selector: 'item-input-timer-picker',
    templateUrl: './component.html',
    styleUrls: ['./component.scss']
})
export class ItemInputTimerPickerComponent {
    public hours: string;
    public minutes: string;
    public seconds: string;
    public form: FormGroup;

    @Input() labelPosition = 'stacked';
    @Input() label: string;
    @Input() theme: AppTheme;
    @Input() controlName: string;

    constructor(private pickerCtrl: PickerController, private rootFormGroup: FormGroupDirective) {}

    ngOnInit() {
        this.form = this.rootFormGroup.control;

        const milliseconds: number = this.form.controls[this.controlName].value;
        const { hours, minutes, seconds } = millisecondsToTime(milliseconds);

        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

    async openPicker() {
        const optionsH = [];
        const optionsMS = [];

        let hoursSelectedIndex = 0;
        let minutesSelectedIndex = 0;
        let secondsSelectedIndex = 0;

        for (let value = 0; value <= 24; value++) {
            const text = value < 10 ? '0' + value : value.toString();
            if (this.hours === text) hoursSelectedIndex = optionsH.length;
            optionsH.push({
                text,
                value
            });
        }

        for (let value = 0; value <= 60; value++) {
            const text = value < 10 ? '0' + value : value.toString();
            if (this.minutes === text) minutesSelectedIndex = optionsMS.length;
            if (this.seconds === text) secondsSelectedIndex = optionsMS.length;
            optionsMS.push({
                text,
                value
            });
        }

        const picker = await this.pickerCtrl.create({
            columns: [
                {
                    name: 'hours',
                    options: optionsH,
                    selectedIndex: hoursSelectedIndex
                },
                {
                    name: 'minutes',
                    selectedIndex: minutesSelectedIndex,
                    options: optionsMS
                },
                {
                    name: 'seconds',
                    selectedIndex: secondsSelectedIndex,
                    options: optionsMS
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Confirm',
                    handler: ({ hours, minutes, seconds }) => {
                        this.hours = hours.text;
                        this.minutes = minutes.text;
                        this.seconds = seconds.text;
                        const delay = hours.value * 60 * 60 * 1000 + minutes.value * 60 * 1000 + seconds.value * 1000;
                        this.form.controls['delay'].patchValue(delay);
                    }
                }
            ],
            cssClass: 'input-picker'
        });

        await picker.present();
    }
}
