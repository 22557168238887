import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BackendService } from './backend.service';

@Injectable({
    providedIn: 'root'
})
export class ApiErrorService {
    private readonly path = `/v2/errors`;
    constructor(private backend: BackendService) {}

    public errorSendAndCreate = (userId: string, data: any): Observable<void> => this.backend.post<void>(this.path, { userId, ...data });
}
