import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private router: Router, private authService: AuthService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // console.log(`Login Guard: Can Activate "${state.url}"?`);
        // Verifichiamo di essere ancora in sessione
        if (!this.authService.getSession()) {
            console.warn('Login Guard STOP! Redirect to login!');
            // Redirect to login
            this.router.parseUrl('/welcome/login');

            return false;
        }

        return true;
    }
}
