<ion-item details="none" class="ion-text-align-start ion-no-margin ion-no-padding">
  <ion-avatar slot="start" style="border: none" class="ion-no-margin-right ion-no-padding-right" *ngIf="osAvatar">
    <svg-icon [class]="'avatar-' + theme" [src]="'assets/ha/svg/'+osAvatar+'.svg'"></svg-icon>
  </ion-avatar>
  <ion-avatar style="border: none" *ngIf="browserAvatar">
    <svg-icon [class]="'avatar-' + theme" [src]="'assets/ha/svg/'+browserAvatar+'.svg'"></svg-icon>
  </ion-avatar>

  <ion-label>
    <!-- <ion-text style="font-size: x-small; margin:0; padding:0;">
      {{osName}}
    </ion-text> -->
    <ion-text style="font-size: small; margin:0; padding:0;">
      {{browserName}}
    </ion-text>
    <br>
    <ion-text>
      <ion-text style="font-size: x-small; color: grey; ">{{ 'users.labels.last-activity' | translate }}
        {{client.activityAt |
        date :
        'short'}}
      </ion-text>
    </ion-text></ion-label>
  <ion-text style="font-size: small; text-decoration: underline; " color="danger" type="button" (click)="disconnect()"
    class="pointer">
    {{
    'users.labels.buttons.disconnect'
    | translate }}</ion-text>

</ion-item>