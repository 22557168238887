import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { ThemeEntity } from 'src/app/services/theme.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'section-header-background',
    templateUrl: './section-header-background.page.html',
    styleUrls: ['./section-header-background.page.scss']
})
export class SectionHeaderBackgroundPage implements OnInit {
    //Gestione dell'immagine di background
    @Input() imageDataUrl: string;

    @Input() name: string;
    @Input() surname: string;
    @Input() theme: AppTheme;
    @Input() enable: boolean;
    //Ho aggiunto questi due input per far si che io possa di default vedere il background con l'immagine sotto la thumbnail, oppure avere un background di sfondo transparente che mantiene le stesse caratteristiche css dell'altro
    @Input() backgroundTransparent = false;
    @Output() imageUpload$ = new EventEmitter();
    public className: string;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(public platformService: PlatformService) {
        this.setStyle();
        this.enable = true;
        this.platformService.resize.subscribe(async () => this.setStyle());
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public imageUpload() {
        this.imageUpload$.emit();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            this.className = 'h1-desktop';
        }
        if (this.platformService.isSmartphone()) {
            this.className = 'h1-roboto-bold-26';
        }
        if (this.platformService.isTablet()) {
            this.className = 'h1-roboto-bold-26';
        }
    };
}
