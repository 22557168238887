import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { ItemSectionHeaderModule } from '../item-section-header/item-section-header.module';
import { PopoverComponent } from './popover.component';

@NgModule({
    declarations: [PopoverComponent],
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        ItemSectionHeaderModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [PopoverComponent]
})
export class PopoverModule {}
