<div class="searchbar" [ngClass]="adaptWidth">
    <ion-searchbar
        #ionSearchbar
        [ngClass]="['searchbar-' + theme]"
        (ionChange)="onChangeSearchValue($event)"
        animated="false"
        [placeholder]="searchLabel | translate"
        [debounce]="debounce"
        show-clear-button="always"
        mode="ios"
    ></ion-searchbar>
</div>
