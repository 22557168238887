import { PrivateIp } from '../types/units.types';
import { DaysTimeWindow, Role } from '../types/users.types';

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export interface UnitEntity {
    unitId: string;
    latitude: number;
    longitude: number;
    registeredAt: Date;
    activatedAt: Date;
    linkedAt: Date;
    uptime: number;
    unitName: string;
    serialNumber: string;
    ownerEmail: string;
    ownerFullname: string;
    publicAddress: string;
    ownerPhone: string;
    serviceEmail: string;
    serviceFullname: string;
    servicePhone: string;
    hardwareVersion: string;
    softwareVersion: string;
    hardwarePlatform: string;
    firmwareVersion: string;
    privateIp: PrivateIp;
    addresses: string[];
    hostname: string;
    unitCode: string;
    address: string;
    timeZone: string;
    ntpEnable: string;
    ntpServer: string;
    cpuLoad: number;
    cpuTemp: number;
    memory: any; //Mettere il tipo giusto
    disk: any; //Mettere il tipo giusto
    heartbeat: number;
    //background: Buffer;
    logRetention: number;
    online: boolean;
    enable: boolean;
    vpns: Vpn[];
    fake: boolean;
    image: string;
}

export type UnitData = Partial<UnitEntity>;

export interface Vpn {
    dev: string;
    mac: string;
    name: string;
    nwid: string;
    status: string;
    type: string;
    ztAssignedIps: string;
}

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------------------------------------*/
export interface UnitByUserEntity extends UnitEntity {
    userId: string;
    willExpireAt: Date;
    enable: boolean;
    dockerCompose: string;
    role: Role;
    timeWindowsEnable: boolean;
    daysTimeWindows: Array<DaysTimeWindow>;
    checkIn: string;
    checkOut: string;
}

export type UnitByUserData = Partial<UnitByUserEntity>;
