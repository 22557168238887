import { UserEntity } from 'src/app/models/users.models';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map, skipWhile } from 'rxjs';
import { Group } from '../types/users.types';
import { UsersService } from '../services/users.service';
import { HomeService } from '../services/home.service';

@Injectable({ providedIn: 'root' })
export class WelcomeGuard implements CanActivate {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private router: Router, private authService: AuthService, private homeService: HomeService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(`Welcome Guard: Can Activate "${state.url}"?`);

        //Notifichiamo di essere sloggati
        //this.authService.clrSession();
        this.authService.emitLogOutState();
        //Notifichiamo di essere usciti di casa
        this.homeService.emitHomeOutState();

        return true;
    }
}
