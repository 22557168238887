import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ErrorDialogPage } from './error-dialog.page';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [ErrorDialogPage],
    exports: [ErrorDialogPage]
})
export class ErrorDialogModule {}
