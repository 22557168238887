import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-input-email',
    templateUrl: './item-input-email.component.html',
    styleUrls: ['./item-input-email.component.scss']
})
export class ItemInputEmailComponent implements OnInit {
    public form: FormGroup;

    @Input() label: string;
    @Input() controlName: string;
    @Input() contentClass: string;
    @Input() lines: string = 'none';
    @Input() readonly = false;
    @Input() theme: AppTheme;
    @Input() placeholder: string;
    @Input() clearInput = true;
    @Input() formGroupName: string;
    @Input() informationMessage: string;
    @Input() successMessage: string;
    @Input() errorMessage: string = 'Invalid email format';
    constructor(private rootFormGroup: FormGroupDirective) {}

    @ViewChild(IonInput, { read: ElementRef }) input: ElementRef;
    ngOnInit(): void {
        this.form = this.formGroupName ? (this.rootFormGroup.control.get(this.formGroupName) as FormGroup) : this.rootFormGroup.control;
        // this.required =
        //     this.form.controls[this.controlName].validator && this.form.controls[this.controlName].validator({} as AbstractControl)?.required ? true : false;
    }
}
