import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { AppService } from 'src/app/services/application.service';
import { Option } from 'src/app/components/shared/interfaces';

import { AppTheme, DaysTimeWindow, Language, Role } from 'src/app/types/users.types';
import { UsersService } from 'src/app/services/users.service';
import { InviteStatus, UserByUnitData, UserByUnitEntity, UserInviteData, UserInviteResult } from 'src/app/models/users.models';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewEntity } from 'src/app/models/views.models';
import { Subscription, firstValueFrom } from 'rxjs';
import { ViewsService } from 'src/app/services/views.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleEntity, RoleService } from 'src/app/services/role.service';
import { FavoriteHandService } from 'src/app/services/favorite-hand.service';
import { LanguageEntity } from 'src/app/services/language.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    templateUrl: './invite-user.modal.component.html',
    styleUrls: ['./invite-user.modal.component.scss']
})
export class UserInviteModalComponent {
    public form: FormGroup;
    public titleWindowsEnable = 'user.timeWindowsEnable';
    public languages: LanguageEntity[];
    public roles: RoleEntity[];
    public views: ViewEntity[];
    public view: ViewEntity = {} as ViewEntity;
    public roleSelected: Role;
    public languageSelected: Language;

    public daysTimeWindows: Array<DaysTimeWindow> = [];
    public timeWindowsEnable: boolean;
    public checkIn: string;
    public checkOut: string;
    public contentClass: string;
    public selectedItem: number;
    private subs: Subscription;

    @Input() theme: AppTheme;
    @Input() unitId: string;
    @Input() inviterUser: UserByUnitEntity;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        public modalController: ModalController,
        private viewsService: ViewsService,
        private platformService: PlatformService,
        private usersService: UsersService,
        private roleService: RoleService
    ) {
        this.subs = new Subscription();
        this.setStyle();
        this.contentClass = 'ion-padding';
        this.roles = this.roleService.roles;

        this.form = new FormGroup({
            firstName: new FormControl(null, { validators: Validators.required }),
            email: new FormControl(null, { validators: [Validators.required, Validators.email] }),
            viewId: new FormControl(null, { validators: Validators.required }),
            viewName: new FormControl(null)
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async ngOnInit() {
        this.views = await firstValueFrom(this.viewsService.loadViewsList(this.unitId));
        this.roleSelected = this.inviterUser.role;
        this.languageSelected = this.inviterUser.language;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose(): void {
        this.modalController.dismiss();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeLanguage(event: any, key: string) {
        this.languageSelected = event.value;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeRole(event: any, key: string) {
        if (event.value !== this.roleSelected) {
            this.roleSelected = event.value;
            this.form.controls.viewId.setValue(null);
        }
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async changeView(event: any, key: string) {
        this.form.controls.viewId.setValue(event.viewId);
        this.form.controls.viewName.setValue(event.name);
    }
    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    changedTime(data: any) {
        this.checkIn = data.checkIn;
        this.checkOut = data.checkOut;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    timeWindowsEnableValueChanged(newValue: boolean) {
        this.timeWindowsEnable = newValue;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    daysValueChanged(data: DaysTimeWindow[]) {
        this.daysTimeWindows = data;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userIndoorInvite(): void {
        const { firstName: firstname, email, viewId, viewName } = this.form.value;
        if (this.roleSelected === 'member' || this.roleSelected === 'guest') {
            this.timeWindowsEnable = true;
        } else {
            this.timeWindowsEnable = false;
        }
        //qui ci andrebbero checkin e checkout ma non è possibile metterli in quanto io mando un array di dayTime non un singolo giorno, inoltre nello user pending model mancano proprio le fasce orarie
        const data: UserInviteData = {
            unitId: this.unitId,
            inviterName: this.inviterUser.firstName,
            invitedName: firstname,
            language: this.languageSelected,
            email,
            role: this.roleSelected,
            viewId,
            viewName,
            daysTimeWindows: this.daysTimeWindows,
            checkIn: this.checkIn,
            checkOut: this.checkOut,
            timeWindowsEnable: this.timeWindowsEnable
        };

        //Invitiamo l'utente
        this.usersService.inviteUser(this.unitId, this.inviterUser.firstName, data).subscribe({
            next: (result: UserInviteResult) => {
                switch (result.status) {
                    case InviteStatus.linked:
                        alert('Utente linkato');
                        break;
                    case InviteStatus.alreadyLinked:
                        alert('Utente già linkato');
                        break;
                    case InviteStatus.invited:
                        alert('Utente invitato');
                        break;
                }

                //Ritorniamo il result per aggiornare la lista
                this.modalController.dismiss(result);
            },
            error: (error: HttpErrorResponse) => {
                alert(error.message);
                this.modalController.dismiss();
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
