import { Injectable } from '@angular/core';
import { catchError, map, Observable, ReplaySubject, tap, throwError } from 'rxjs';
import { UserByTokenEntity, UserByWelcomeEntity } from '../models/welcome.models';
import { BackendService } from './backend.service';

@Injectable({
    providedIn: 'root'
})
export class WelcomeService {
    //Utenti pendenti
    public pendingUsersObserver: ReplaySubject<UserByWelcomeEntity[]>;
    private pendingUsersEntities: UserByWelcomeEntity[] = [];

    constructor(private backendService: BackendService) {
        this.pendingUsersObserver = new ReplaySubject<UserByWelcomeEntity[]>(1);
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Lista gli utenti pending
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersList(): Observable<UserByWelcomeEntity[]> {
      return this.backendService.get<UserByWelcomeEntity[]>(`/v2/welcome`).pipe(
          map((users: UserByWelcomeEntity[]) => {
            if (users && Array.isArray(users)) {
                this.pendingUsersEntities = users;
                console.log('Emitting added listedUsersEntities list...', this.pendingUsersEntities);
                this.pendingUsersObserver.next(this.pendingUsersEntities);
            }
            return users;
          }),
          catchError(error => {
              console.error('Error occurred while fetching users:', error);
              return throwError(() => new Error('Failed to fetch users'));
          })
      );
    }

   /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Elimina un utente e aggiorna la lista degli utenti da mostrare
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public deleteUser(apiKey: string, job: string): Observable<UserByWelcomeEntity[]> {
        return this.backendService.delete<UserByWelcomeEntity[]>(`/v2/welcome/delete`, {apiKey, job}).pipe(
            map((users: UserByWelcomeEntity[]) => {
              if (users && Array.isArray(users)) {
                  this.pendingUsersEntities = users;
                  console.log('Emitting added listedUsersEntities list...', this.pendingUsersEntities);
                  this.pendingUsersObserver.next(this.pendingUsersEntities);
              } else {
                this.pendingUsersEntities = [];
                console.log('Dopo l eliminazione dello user, non sono rimasti altri user da mostrare.');
                this.pendingUsersObserver.next(this.pendingUsersEntities);
              }
              return users;
            }),
            catchError(error => {
                console.error('Error occurred while fetching users:', error);
                return throwError(() => new Error('Failed to fetch users'));
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Cerchiamo degli utenti
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public usersSearch(match: string, limit = 10): Observable<UserByWelcomeEntity[]> {
        return this.backendService.get<UserByWelcomeEntity[]>(`/v2/welcome/search`, {match}).pipe(
            map((users: UserByWelcomeEntity[]) => {
                //Aggiorniamo le info della lista utenti
                this.pendingUsersEntities = users;
                //Notifichiamo i nuovi dati utente
                // console.log('Emitting new listedUsersEntities...', this.listedUsersEntities);
                this.pendingUsersObserver.next(this.pendingUsersEntities);
                return users;
            })
        );
    }
}
