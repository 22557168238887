import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UnitsService } from './units.service';
import { UsersService } from './users.service';

@Injectable({ providedIn: 'root' })
export class HomeService {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private router: Router, private unitsService: UnitsService, private usersService: UsersService) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public getUnitIdFromStorage(): string {
        //TODO Prendere la unitId dal token???
        //const accessToken = this.getAuthToken();
        //Apriamo il payload del token
        //const decoded: any = jwt_decode(accessToken);
        //return decoded.unitId;
        const unitId = localStorage.getItem('unitId');
        return unitId;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public homeIn(unitId: string, userId: string) {
        console.log(`Entering home...`);
        localStorage.setItem('unitId', unitId);
        //Navighiamo alla pagina principale indoor
        this.router.navigateByUrl('/indoor', { replaceUrl: true });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public homeOut() {
        console.log(`Exiting home...`);
        localStorage.removeItem('unitId');
        //Navighiamo alla pagina principale outdoor
        this.router.navigateByUrl('/outdoor', { replaceUrl: true });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public emitHomeOutState() {
        this.unitsService.clearIndoorUnitEntity();
        this.usersService.clearIndoorUserEntity();
    }
}
