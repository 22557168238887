<ion-item button [formGroup]="form" (click)="openModalOnClick()" detail="false" [lines]="lines" [ngClass]="contentClass">
    <ion-label [position]="labelPosition" style="color: grey">{{ label | translate }}</ion-label>
    <ion-input *ngIf="showInputName" [type]="inputType" [readonly]="readonly" [value]="value" [autofocus]="true"></ion-input>
    <ion-input
        *ngIf="controlName"
        [type]="inputType"
        [formControlName]="controlName"
        [readonly]="readonly"
        [hidden]="showInputName"
        [autofocus]="true"
    ></ion-input>
    <ion-input *ngIf="!controlName" [type]="inputType" [readonly]="readonly" [value]="value" [hidden]="showInputName" [autofocus]="true"></ion-input>
    <ion-icon *ngIf="iconName && !avatar && !imageDataUrl" slot="end" [name]="iconName"></ion-icon>
    <ion-avatar *ngIf="avatar" slot="end" [ngClass]="'my-background-' + theme">
        <svg-icon [class]="'avatar-' + theme" [src]="'assets/ha/svg/' + avatar + '.svg?'"></svg-icon>
    </ion-avatar>
</ion-item>
