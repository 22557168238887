import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { UAParser } from 'ua-parser-js';
import { Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SocketioService } from './socketio.service';
import { JwtPayload } from 'jsonwebtoken';
import { ClientData } from '../models/clients.models';
import { UsersService } from './users.service';
import { RenewAccessToken, Token, TokenUserPayload } from '../models/token.models';

@Injectable({ providedIn: 'root' })
export class AuthService {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        private router: Router,
        private platform: Platform,
        public toastController: ToastController,
        private translateService: TranslateService,
        private socketio: SocketioService,
        private usersService: UsersService
    ) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Save tokens and userId in localstorage or sessionStorage
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public setSession(tokens: Token, loginRemember: boolean): string {
        const { accessToken, refreshToken } = tokens;
        if (loginRemember) {
            // Salviamo in memoria per ricordare il login anche chiudendo il browser
            console.log('Write local tokens');
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            //Cacelliamo l'altra variante di token
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
        } else {
            // Salviamo in memoria ma perdiamo il login chiudendo il browser

            sessionStorage.setItem('accessToken', accessToken);
            sessionStorage.setItem('refreshToken', refreshToken);
            //Cacelliamo l'altra variante di token
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }

        const decoded: JwtPayload = jwt_decode(accessToken);
        const { userId } = decoded;
        return userId;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Clear tokens in localstorage or sessionStorage
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public clrSession(): void {
        console.log('Clearing tokens');
        //Cancelliamo l'altra variante di token
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        //Cancelliamo l'altra variante di token
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public getAuthToken(): string {
        const localToken = localStorage.getItem('accessToken');
        const sessionToken = sessionStorage.getItem('accessToken');

        //Se abbiamo il token memorizzato in sessione, diamo la precedenza a lui (rememberLogin = false)
        if (sessionToken !== null && sessionToken != '') {
            //console.log('getAuthToken() found sessionToken', sessionToken);
            return sessionToken;
        } else {
            //console.log('getAuthToken() found localToken', localToken);
            return localToken;
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public getRefreshToken(): string {
        const localToken = localStorage.getItem('refreshToken');
        const sessionToken = sessionStorage.getItem('refreshToken');

        //Se abbiamo il token memorizzato in sessione, diamo la precedenza a lui (rememberLogin = false)
        if (sessionToken !== null && sessionToken != '') {
            //console.log('getAuthToken() found sessionToken', sessionToken);
            return sessionToken;
        } else {
            //console.log('getAuthToken() found localToken', localToken);
            return localToken;
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public getUserIdFromStorage(): string {
        const accessToken = this.getAuthToken();
        //Apriamo il payload del token
        const decoded: any = jwt_decode(accessToken);
        return decoded.userId;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Chiamata dalla canActivate
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public getSession() {
        //Se manca l'accessToken...
        if (!this.getAuthToken()) {
            console.log('Missing accessToken');
            //Dritti al login!
            return false;
        }
        //Se manca il refreshToken...
        if (!this.getRefreshToken()) {
            console.log('Missing refreshToken');
            //Dritti al login!
            return false;
        }

        //Ok, proseguiamo nella navigazione
        return true;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public logOut(options?: { clientsDeleteAll?: boolean; notRedirect?: boolean }) {
        const { clientsDeleteAll, notRedirect } = options || {};

        // if (this.tokenPayloadGet()) {
        //     const { userId, clientId } = this.tokenPayloadGet();
        //
        //     this.apiUserClientService.userClientDelete(userId, clientId).subscribe();
        //     if (clientsDeleteAll) this.apiUserClientService.userClientsDelete(userId).subscribe();
        //     this.socketio.topicUnsubscribe(userId);
        //     Notification.topicUnsubscribe({
        //         eventName: RootEventName.unsubscribe,
        //         topicUnsubscribe: {
        //             topic: userId,
        //             eventName: RootEventName.unsubscribe,
        //             origin: Origin.app
        //         }
        //     });
        // }

        this.clrSession();
        this.router.navigateByUrl('/welcome/login', { replaceUrl: true });
    }

    /*********************************************************** DA SISTEMARE SOTTO ***************************************************************************/

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public accessTokenRefresh = (): Observable<RenewAccessToken> => {
        // Passandogli il refreshToken...
        const refreshToken = this.getRefreshToken();
        localStorage.setItem('accessToken', null);

        if (!refreshToken) {
            console.log(`Missing refresh token`);
            return new Observable<RenewAccessToken>((subscriber) => subscriber.next({ accessToken: null }));
        }

        const client: ClientData = this.getClient();

        //TODO Mi da circular dependency dovuta all'importazione del loginService. devo trovare una soluzione
        // // ...Otteniamo un nuovo accessToken dal backend
        // return this.loginService.accessTokenRefresh(refreshToken, client).pipe(
        //     tap((refreshToken: RenewAccessToken) => {
        //         const { accessToken } = refreshToken;
        //         this.logger.info(`Storing new accessToken: ${accessToken}`);
        //         localStorage.setItem('accessToken', accessToken);
        //     })
        // );
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Otteniamo i dati specifici del client su cui sta girando il frontend
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public getClient = (): ClientData => {
        const parser = new UAParser();
        const client: ClientData = {
            browser: parser.getBrowser(),
            cpu: parser.getCPU(),
            device: parser.getDevice(),
            engine: parser.getEngine(),
            os: parser.getOS(),
            platform: {
                rtl: this.platform.isRTL,
                landscape: this.platform.isLandscape(),
                portrait: this.platform.isPortrait(),
                width: this.platform.width(),
                height: this.platform.height()
            }
        };
        return client;
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
      Call canActivate
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public isUserLogged = (): boolean => {
        // // Se manca l'accessToken...
        // if (!this.getAuthToken()) {
        //     this.logger.info('Missing accessToken');
        //     // Dritti al login!
        //     return false;
        // }
        // //Se manca il refreshToken...
        // if (!this.getRefreshToken()) {
        //     this.logger.info('Missing refreshToken');
        //     // Dritti al login!
        //     return false;
        // }

        // // Ok, proseguiamo nella navigazione
        return true;
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public tokenPayloadGet = (): Omit<TokenUserPayload, 'username'> => {
        const accessToken = this.getAuthToken();

        if (!accessToken) return null;

        const decoded: JwtPayload = jwt_decode(accessToken);

        const { userId, clientId } = decoded;

        return { userId, clientId };
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public userNotEnabled = async (message?: string): Promise<void> => {
        // if (!this.state.getValue().login?.user) return;
        // const theme = this.state.getValue().login?.user.theme;

        const toastTopOverlay = await this.toastController.getTop();
        if (toastTopOverlay) return;

        const toast = await this.toastController.create({
            header: this.translateService.instant(message ?? 'alerts.login-user-unauthorized.message'),
            icon: 'information-circle',
            position: 'middle',
            duration: 4000,
            color: 'lili', //TODO
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel'
                }
            ]
        });

        const style = document.createElement('style');

        style.textContent = `
        ion-toast::part(header) {
          font-size: 1rem;
        }

        ion-toast::part(icon) {
          font-size: 2rem;
        }`;

        toast.appendChild(style);
        toast.present();
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public emitLogOutState() {
        this.usersService.clearLoggedUserEntity();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    // public navigateIntoOutdoor(userId: string) {
    //     //     //Facciamo un redirect in base al gruppo dell'utente loggato
    //     //     switch (user.group) {
    //     //         case Group.administrator:
    //     //             console.log('Yes! Redirecting to: /outdoor/dashboard');
    //     //             return this.router.parseUrl('/outdoor/dashboard');
    //     //         case Group.support:
    //     //             console.log('Yes! Redirecting to: /outdoor/dashboard');
    //     //             return this.router.parseUrl('/outdoor/dashboard');
    //     //         case Group.customer:
    //     //             console.log('Yes! Redirecting to: /outdoor/units/list');
    //     //             return this.router.parseUrl('/outdoor/units/list');
    //     //         default:
    //     //             console.log('NO!');
    //     //             return false;
    //     //     }
    //     // }

    //     this.router.navigateByUrl('outdoor/units/list', { replaceUrl: true });
    // }
}
