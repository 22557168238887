import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-section-header',
    templateUrl: './item-section-header.page.html',
    styleUrls: ['./item-section-header.page.scss']
})
export class ItemSectionHeaderPage {
    @Input() title: string;
    @Input() name: string;
    @Input() sectionTitle: string;
    @Input() subTitle: string;
    @Input() titlePage: string;
    @Input() theme: AppTheme;
    @Input() slot: string;
    @Input() class: string;
    @Input() iconClose: boolean;
    @Input() backroute: boolean;
    @Input() sectionSubtitle: string;
    @Output() closeModal$ = new EventEmitter();
    @Output() goBack$ = new EventEmitter();

    public classModalTitle: string;
    public classSectionTitle: string;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private translate: TranslateService, private platformService: PlatformService) {
        this.translate.onLangChange.subscribe({
            next: () => {
                this.applyTranslations();
            }
        });

        this.setStyle(); // Applicare lo stile subito dopo la creazione
        this.platformService.resize.subscribe(async () => this.setStyle());
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    modalClose() {
        this.closeModal$.emit();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    goBack() {
        this.goBack$.emit();
    }
    ngOnInit() {
        this.setStyle(); // Applicare lo stile quando il componente è inizializzato
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private applyTranslations = () => {
        if (this.title) this.title = this.translate.instant(this.title);
        if (this.sectionSubtitle) this.sectionSubtitle = this.translate.instant(this.sectionSubtitle);
        if (this.sectionTitle) this.sectionTitle = this.translate.instant(this.sectionTitle);
        if (this.subTitle) this.subTitle = this.translate.instant(this.subTitle);

        this.setStyle(); // Applicare lo stile dopo aver applicato le traduzioni
    };
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isDesktop()) {
            this.classModalTitle = 'text-input-roboto-bold-16';
            this.classSectionTitle = 'h2-roboto-medium-22 medium-dark-' + this.theme;
        }
        if (this.platformService.isSmartphone()) {
            this.classModalTitle = 'text-input-roboto-bold-16';
            this.classSectionTitle = 'h2-roboto-medium-22 medium-dark-' + this.theme;
        }
        if (this.platformService.isTablet()) {
            this.classModalTitle = 'text-input-roboto-bold-16';
            this.classSectionTitle = 'h2-roboto-medium-22 medium-dark-' + this.theme;
        }
    };
}
