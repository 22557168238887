import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';

import { AlertController, ModalController } from '@ionic/angular';
import { UnitEntity, Vpn } from 'src/app/models/units.models';
import { SocketioService } from 'src/app/services/socketio.service';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'vpn-item',
    templateUrl: './vpn-item.component.html',
    styleUrls: ['./vpn-item.component.scss']
})
export class VpnItemComponent implements OnInit {
    public theme: AppTheme;
    public descriptionClass: string;
    public serialNumberClass: string;
    @Input() vpn: Vpn;
    @Input() unit: UnitEntity;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(
        public platformService: PlatformService,
        private socketioService: SocketioService,
        private alertController: AlertController,
        private translate: TranslateService,
        public modalController: ModalController
    ) {
        this.setStyle();

        this.platformService.resize.subscribe(async () => {
            this.setStyle();
        });
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnInit() {}
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private setStyle = () => {
        if (this.platformService.isSmartphone()) {
            [(this.descriptionClass = 'text-input-roboto-regular-16'), (this.serialNumberClass = 'caption-roboto-regular-13 medium-dark' + this.theme)];
        }

        if (this.platformService.isDesktop()) {
            [(this.descriptionClass = 'text-input-roboto-regular-16'), (this.serialNumberClass = 'caption-roboto-regular-13 medium-dark' + this.theme)];
        }

        if (this.platformService.isTablet()) {
            [(this.descriptionClass = 'text-input-roboto-regular-16'), (this.serialNumberClass = 'caption-roboto-regular-13 medium-dark' + this.theme)];
        }
    };

    async leaveNetwork(nwid: string) {
        const alert = await this.alertController.create({
            header: this.translate.instant('alerts.leave.networkId.header'),
            subHeader: this.translate.instant('alerts.leave.networkId.subHeader'),
            message: this.translate.instant('alerts.leave.networkId.message'),
            buttons: [
                {
                    text: this.translate.instant('button.cancel'),
                    role: 'cancel',
                    cssClass: this.theme
                },
                {
                    text: this.translate.instant('button.confirm'),
                    cssClass: this.theme,
                    handler: async () => {
                        this.socketioService.publishTopic<any>(this.unit.unitId, WebsocketEventName.leaveVpnNetwork, {
                            payload: { command: 'leave', network: nwid }
                        });
                        await this.modalController.dismiss({ returnToParent: true });
                    }
                }
            ]
        });
        await alert.present();
    }
}
