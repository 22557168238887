import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpContext, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class BackendService {
    private headers: HttpHeaders = new HttpHeaders().set('Content-type', 'application/json');
    private backendApiUrl = environment.backendUrl;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private http: HttpClient, private logger: NGXLogger) {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
      Error handler
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    private handleError =
        (logger: NGXLogger) =>
        (error: HttpErrorResponse): Observable<never> => {
            if (error.status === 0) {
                // A client-side or network error occurred. Handle it accordingly.
                logger.error(`A client-side or network error occurred:  ${JSON.stringify(error.error)}`);
            }
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            else logger.error(`Backend returned code ${error.status} - body was:  ${JSON.stringify(error.error)}`);
            // Return an observable with a user-facing error message.
            return throwError(() => new HttpErrorResponse(error));
        };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
      Make a POST call
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public post = <T>(
        path: string,
        body: { [key: string]: string | boolean | number } | unknown,
        options?: {
            url?: string;
            retryCount?: number;
            options?: {
                headers?:
                    | HttpHeaders
                    | {
                          [header: string]: string | string[];
                      };
                context?: HttpContext;
                params?:
                    | HttpParams
                    | {
                          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                      };
                reportProgress?: boolean;
                withCredentials?: boolean;
                [key: string]: unknown;
            };
        }
    ): Observable<T> =>
        this.http.post<T>(`${options?.url ?? this.backendApiUrl}${path}`, body, { headers: this.headers, ...options?.options }).pipe(
            retry({
                count: options?.retryCount ?? 0 //retrycount
            }),
            catchError(this.handleError(this.logger))
        );

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
      Make a put call
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public put = <T>(
        path: string,
        body: { [key: string]: string | boolean | number } | unknown,
        options?: {
            url?: string;
            retryCount?: number;
            options?: {
                headers?:
                    | HttpHeaders
                    | {
                          [header: string]: string | string[];
                      };
                context?: HttpContext;
                params?:
                    | HttpParams
                    | {
                          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                      };
                reportProgress?: boolean;
                withCredentials?: boolean;
                [key: string]: unknown;
            };
        }
    ): Observable<T> =>
        this.http.put<T>(`${options?.url ?? this.backendApiUrl}${path}`, body, { headers: this.headers, ...options?.options }).pipe(
            retry({
                count: options?.retryCount ?? 0
            }),
            catchError(this.handleError(this.logger))
        );

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
      Make a delete call
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public delete = <T>(
        path: string,
        body: { [key: string]: string | boolean | number } | unknown,
        options?: {
            url?: string;
            retryCount?: number;
            options?: {
                headers?:
                    | HttpHeaders
                    | {
                          [header: string]: string | string[];
                      };
                context?: HttpContext;
                params?:
                    | HttpParams
                    | {
                          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                      };
                reportProgress?: boolean;
                withCredentials?: boolean;
                [key: string]: unknown;
            };
        }
    ): Observable<T> =>
        this.http.delete<T>(`${options?.url ?? this.backendApiUrl}${path}`, { body, headers: this.headers, ...options?.options }).pipe(
            retry({
                count: options?.retryCount ?? 0
            }),
            catchError(this.handleError(this.logger))
        );

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
      Make a GET call
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public get = <T, W = { [key: string]: string | boolean | number | ReadonlyArray<string | number | boolean> }>(
        path: string,
        params: W = null,
        options?: {
            url?: string;
            retryCount?: number;
            options?: {
                headers?:
                    | HttpHeaders
                    | {
                          [header: string]: string | string[];
                      };
                context?: HttpContext;
                params?:
                    | HttpParams
                    | {
                          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                      };
                reportProgress?: boolean;
                withCredentials?: boolean;
                [key: string]: unknown;
            };
        }
    ): Observable<T> => {
        const { params: _params, ..._options } = options?.options || {};
        const __params = {
            ...params,
            ..._params
        };

        return this.http
            .get<T>(`${options?.url ?? this.backendApiUrl}${path}`, {
                params: __params,
                headers: this.headers,
                ..._options
            })
            .pipe(
                retry({
                    count: options?.retryCount ?? 0
                }),
                catchError(this.handleError(this.logger))
            );
    };

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
      Make a patch call
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public patch = <T>(
        path: string,
        body: { [key: string]: string | boolean | number } | unknown,
        options?: {
            url?: string;
            retryCount?: number;
            options?: {
                headers?:
                    | HttpHeaders
                    | {
                          [header: string]: string | string[];
                      };
                context?: HttpContext;
                params?:
                    | HttpParams
                    | {
                          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                      };
                reportProgress?: boolean;
                withCredentials?: boolean;
                [key: string]: unknown;
            };
        }
    ): Observable<T> =>
        this.http.patch<T>(`${options?.url ?? this.backendApiUrl}${path}`, body, { headers: this.headers, ...options?.options }).pipe(
            retry({
                count: options?.retryCount ?? 0
            }),
            catchError(this.handleError(this.logger))
        );
}
