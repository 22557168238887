<ion-content>
    <item-section-header [title]="'language-chooser.modal.title'" (closeModal$)="closeModal()" [iconClose]="true"></item-section-header>
    <ion-list class="input-padding">
        <ion-radio-group [value]="langSelected" class="ion-no-padding ion-no-margin">
            <ion-item-sliding *ngFor="let language of languages" class="ion-no-padding ion-no-margin">
                <ion-item (click)="radioGroupChange(language)" class="ion-no-padding ion-no-margin">
                    <ion-label>
                        <ion-text>{{ language.key | translate }}</ion-text>
                        <br />
                        <ion-text style="font-size: x-small"></ion-text>
                    </ion-label>
                    <ion-radio [ngClass]="contentClass" slot="end" [value]="language"></ion-radio>
                </ion-item>
            </ion-item-sliding>
        </ion-radio-group>
    </ion-list>
</ion-content>
