import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UnitEntity } from 'src/app/models/units.models';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'app-ip-addresses-viewer-modal',
    templateUrl: './ip-addresses-viewer-modal.component.html',
    styleUrls: ['./ip-addresses-viewer-modal.component.scss']
})
export class IpAddressesViewerModalComponent implements OnInit {
    public contentClass: string;
    public addressesArray: any;
    public addressesValues: any[] = [];
    public addressesKeys: any[] = [];
    public keyValueObject: Record<string, string>;
    @Input() theme: AppTheme;
    @Input() publicIp: string;
    @Input() addresses: any[];

    constructor(private modalController: ModalController) {}

    ngOnInit() {}
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    closeModal() {
        this.modalController.dismiss();
    }
    getKey(obj: any): string {
        return Object.keys(obj)[0];
    }
    getValue(obj: any) {
        return Object.values(obj)[0];
    }
}
