<item-section-header [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()" [title]="'users.admitted.edit-modal'"></item-section-header>
<section-header-background
    [theme]="theme"
    [imageDataUrl]="image"
    [name]="user.firstName"
    [surname]="user.lastName"
    [backgroundTransparent]="true"
></section-header-background>
<ion-content [ngClass]="contentClass">
    <ion-text class="h3-roboto-medium-18">{{ 'users.info.edit-modal.description' | translate | titlecase }}</ion-text>
    <ion-list lines="full">
        <item-label-readout label="user.email" [value]="user.email"></item-label-readout>
        <item-label-readout label="user.phoneNumber" [value]="user.phoneNumber"></item-label-readout>
        <item-label-readout label="user.language" [value]="user.language"></item-label-readout>
        <form [formGroup]="form">
            <!-- Selettore del ruolo -->
            <role-chooser [theme]="theme" [value]="user.role" (selected)="changeRole($event, 'role')"></role-chooser>
            <view-chooser
                [theme]="theme"
                [unitId]="user.unitId"
                (selected)="changeView($event, 'view')"
                [viewName]="viewName"
                [role]="roleSelected"
                [isEdit]="true"
            ></view-chooser>
            <access-configuration
                *ngIf="roleSelected === 'member' || roleSelected === 'guest'"
                [timeWindowsEnable]="timeWindowsEnable"
                [role]="roleSelected"
                [theme]="theme"
                [(daysTimeWindows)]="daysTimeWindows"
                [(checkIn)]="checkIn"
                [(checkOut)]="checkOut"
                (changedTime)="changedTime($event)"
                (valueChange)="timeWindowsEnableValueChanged($event)"
                (daysTimeWindowsChange)="daysValueChanged($event)"
                [titleWindowsEnable]="titleWindowsEnable"
            ></access-configuration>

            <!-- enable -->
            <item-input-toggle
                [theme]="theme"
                controlName="enable"
                [title]="'user.label.enable'"
                [checked]="userEnable"
                (itemValueChange$)="toggleEnable($event)"
            ></item-input-toggle>
            <!-- <ion-item lines="full" detail="false" class="ion-no-padding ion-no-margin"> -->
            <!-- <ion-label>{{ 'user.label.unlink' | translate }}</ion-label> -->
            <ion-grid fixed class="ion-no-margin ion-no-padding margin-top-24">
                <ion-row class="ion-no-margin ion-no-padding">
                    <ion-col size="6" class="ion-no-margin ion-no-padding padding-right-16">
                        <ion-button [color]="theme" expand="full" (click)="userUnlink()" class="outline ion-no-margin ion-no-padding" [disabled]="enableUser()">
                            {{ 'button.user.unlink' | translate | uppercase }}
                        </ion-button>
                    </ion-col>
                    <ion-col size="6" class="ion-no-margin ion-no-padding">
                        <ion-button (click)="editUser()" class="solid ion-no-margin ion-no-padding" expand="full" [disabled]="!form.valid">
                            {{ 'button.submit' | translate | uppercase }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </ion-list>
</ion-content>
