import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpLoaderFactory } from 'src/app/http-loader-factory.module';
import { DateTimeWindowChooserModalComponent } from './modal/modal.component';
import { DateTimeWindowChooserComponent } from './chooser/chooser.component';
import { DateTimeWindowComponent } from './date-time-window.component';
import { ItemSectionHeaderModule } from '../item-section-header/item-section-header.module';
import { ItemInputsModule } from '../item-inputs/item-inputs.module';
import { PopoverModule } from '../popover/popover.module';

@NgModule({
    declarations: [DateTimeWindowComponent, DateTimeWindowChooserModalComponent, DateTimeWindowChooserComponent],
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        PopoverModule,
        ItemSectionHeaderModule,
        ItemInputsModule,
        AngularSvgIconModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateModule,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [DateTimeWindowComponent, DateTimeWindowChooserModalComponent, DateTimeWindowChooserComponent]
})
export class DateTimeWindowModule {}
