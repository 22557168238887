export enum DevicesFamilyNames {
    actuators = 'actuators',
    automations = 'automations',
    energyManagements = 'energy-managements',
    envSensors = 'environment-sensors',
    irrigations = 'irrigations',
    lights = 'lights',
    meterSensors = 'metering-sensors',
    notifiers = 'notifiers',
    securityAreas = 'security-areas',
    securitySensors = 'security-sensors',
    sockets = 'sockets',
    thermostats = 'thermostats'
    // Non fare al momento
    // accessControls = 'accessControls',
    // appliances = 'appliances',
    // femGenerators = 'femGenerators',
    // hvacGenerators = 'hvacGenerators',
    // hvacVentilators = 'hvacVentilators',
    // tanks = 'tanks',
    // videoSurveillances = 'videoSurveillances',
    // waterSofteners = 'waterSofteners'
}


export const DevicesFamilyTags = {
    actuators: ["actuator"],
    automations: ["automation"],
    envSensors: ["env"],
    lights: ["light"],
    meterSensors: ["env"],
    notifiers: ["notifier"],
    securityAreas: ["security"],
    securitySensors: ["security"],
    sockets: ["socket"],
    thermostats: ['thermostat', 'chronotermostat'],
    energyManagements: [],
    irrigations: []
}

export enum FamiliesAvatars {
    actuators = 'water-pump',
    automations = 'shutter-close',
    energyManagements = 'wallbox',
    envSensors = 'thermometer',
    irrigations = 'sprinkler',
    lights = 'bulb',
    meterSensors = 'current-clamp',
    notifiers = 'megaphone',
    saSeSensors = 'magnetic-contact',
    securities = 'lock-closed',
    sockets = 'italian-socket',
    thermostats = 'thermometer'
    // Non fare al momento
    // accessControls = '1706',
    // appliances = '1411',
    // femGenerators = '2401',
    // hvacGenerators = '2510',
    // hvacVentilators = '2602',
    // tanks = '4202',
    // videoSurveillances = '4503',
    // waterSofteners = '4600'
}

export const FamilyDataType = {
    lights: {
        name: 'lights',
        avatar: '1300'
    }
};
