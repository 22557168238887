import { Injectable } from '@angular/core';
import { UnitByUserEntity } from '../models/units.models';
import { UserByUnitEntity } from '../models/users.models';

@Injectable({ providedIn: 'root' })
export class TimeWindowService {
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor() {}

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userEnable(entity: UnitByUserEntity | UserByUnitEntity) {
        if (entity.enable === null || entity.enable === undefined) {
            return true;
        }
        if (entity.enable) {
            return true;
        }
        return false;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userWinTime(entity: UnitByUserEntity | UserByUnitEntity) {
        if (entity.role == 'service') {
            return true;
        }
        if (entity.role == 'owner') {
            return true;
        }

        if (entity.timeWindowsEnable) {
            const nowTMZ = new Date();
            const now = new Date(
                Date.UTC(
                    nowTMZ.getUTCFullYear(),
                    nowTMZ.getUTCMonth(),
                    nowTMZ.getUTCDate(),
                    nowTMZ.getUTCHours(),
                    nowTMZ.getUTCMinutes(),
                    nowTMZ.getUTCSeconds()
                )
            );

            if (entity.checkIn && entity.checkOut) {
                const checkIn = new Date(entity.checkIn);
                const checkOut = new Date(entity.checkOut);
                if (now > checkIn && now < checkOut) {
                    return true;
                }
                return false;
            }

            const currentDayOfWeek = this.getDayOfWeek(now);
            if (entity.daysTimeWindows) {
                return entity.daysTimeWindows.some((o) => {
                    if (o.days[currentDayOfWeek] === true) {
                        if (o.allday) {
                            return true;
                        } else {
                            const currentHH = now.getHours();
                            const currentmm = now.getMinutes();
                            const startParts = o.start.split(':').map((part) => parseInt(part));
                            const endParts = o.end.split(':').map((part) => parseInt(part));
                            const startHour = startParts[0];
                            const startMinute = startParts[1];
                            const endHour = endParts[0];
                            const endMinute = endParts[1];
                            if (currentHH < startHour || (currentHH === startHour && currentmm < startMinute)) {
                                return false;
                            }

                            if (currentHH > endHour || (currentHH === endHour && currentmm > endMinute)) {
                                return false;
                            }
                            return true;
                        }
                    } else {
                        return false;
                    }
                });
            }
        }
        return true;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    getDayOfWeek(date) {
        const day = date.getDay();
        return day === 0 ? 6 : day - 1;
    }
}
