import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlatformService implements OnDestroy {
    public resize = new BehaviorSubject<void>(undefined);

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private platform: Platform) {
        // Riceviamo i cambi di dimensione
        this.platform.resize.subscribe(async () => {
            // Notifichiamo la nuova dimensione
            this.resize.next();
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public isDesktop(): boolean {
        if (this.platform.is('tablet')) {
            return false;
        }
        if (this.platform.is('desktop')) {
            return true;
        }
        if (this.platform.is('mobile')) {
            return false;
        }
        return true;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public isTablet(): boolean {
        if (this.platform.is('tablet')) {
            return true;
        }
        if (this.platform.is('ipad')) {
            return true;
        }
        if (this.platform.is('desktop')) {
            return false;
        }
        if (this.platform.is('mobile')) {
            return false;
        }
        return true;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public isSmartphone(): boolean {
        if (this.platform.is('tablet')) {
            return false;
        }
        if (this.platform.is('desktop')) {
            return false;
        }
        if (this.platform.is('mobile')) {
            return true;
        }
        return true;
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public isPortrait(): boolean {
        return this.platform.isPortrait();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public isLandscape(): boolean {
        return this.platform.isLandscape();
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public width(): number {
        return this.platform.width();
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public height(): number {
        return this.platform.height();
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public platforms(): string[] {
        return this.platform.platforms();
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public ngOnDestroy() {}
}
