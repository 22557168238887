import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';
import { AppTheme } from 'src/app/types/users.types';
import { UtilitiesComponent } from 'src/app/utilities/utilities.component';

@Component({
    selector: 'app-date-time-range-chooser-modal',
    templateUrl: './date-time-range-chooser-modal.component.html',
    styleUrls: ['./date-time-range-chooser-modal.component.scss']
})
export class DateTimeRangeChooserModalComponent extends UtilitiesComponent implements OnInit {
    public stepIndex = 1;
    public contentClass: string;
    public title = 'user.sections.time-window';

    public setCheckIn: boolean;
    public setcheckOut: boolean;
    public classColorSelected: string;
    public toggle = false;
    @Input() theme: AppTheme;
    @Input() checkInOnly: boolean = false;
    @Input() checkIn: string;
    @Input() checkOut: string;
    @Input() timeZone: string;
    userEditForm: FormGroup;
    constructor(public modalController: ModalController, private platformService: PlatformService, private navParams: NavParams) {
        super();
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';

        this.userEditForm = new FormGroup(
            {
                checkIn: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
                checkOut: new FormControl(null, { validators: Validators.required, updateOn: 'change' })
            },
            { validators: this.validateDates }
        );
    }

    private validateDates = (form: FormGroup): ValidationErrors | null => {
        if(this.checkInOnly) {
            return Date.parse(form.value.checkIn) > Date.parse(new Date().toISOString()) ? null : { mismatch: true };
        } else {
            return Date.parse(form.value.checkIn) < Date.parse(form.value.checkOut) ? null : { mismatch: true };
        }
    };

    ngOnInit(): void {
        if (this.platformService.isDesktop()) this.contentClass = 'ion-padding';
        if(this.checkInOnly) {
            this.userEditForm = new FormGroup(
                {
                    checkIn: new FormControl(null, { validators: Validators.required, updateOn: 'change' })
                },
                { validators: this.validateDates }
            );
        } else {
            this.userEditForm = new FormGroup(
                {
                    checkIn: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
                    checkOut: new FormControl(null, { validators: Validators.required, updateOn: 'change' })
                },
                { validators: this.validateDates }
            );
        }
        this.setCheckIn = false;
        this.setcheckOut = false;
        if (!this.checkIn){
            let now = new Date(); // Ottieni l'ora corrente
            // Ottieni i minuti attuali
            let minutes = now.getMinutes();
            // Arrotonda i minuti all'ora o alla mezza più vicina
            if (minutes < 15) {
            now.setMinutes(0);   // Imposta i minuti a 00 se sono tra 0 e 14
            } else if (minutes < 45) {
            now.setMinutes(30);  // Imposta i minuti a 30 se sono tra 15 e 44
            } else {
            now.setMinutes(0);   // Imposta i minuti a 00 se sono tra 45 e 59
            now.setHours(now.getHours() + 1); // Aumenta l'ora di 1 se i minuti erano tra 45 e 59
            }
            this.checkIn = now.toISOString();
            this.userEditForm.controls.checkIn.patchValue(now.toISOString());
        }
        else {
            this.userEditForm.controls.checkIn.patchValue(this.checkIn);
            this.userEditForm.controls.checkOut.patchValue(this.checkOut);
        }
    }

    submitRequest = async (): Promise<void> => {
        await this.modalController.dismiss(this.userEditForm.value);
    };
    public changeTimesetCheckIn() {
        this.setCheckIn = !this.setCheckIn;
    }
    public changeTimesetCheckOut() {
        this.setcheckOut = !this.setcheckOut;
    }
    public step(): void {
        this.toggle = !this.toggle;
        this.stepIndex = this.stepIndex > 0 ? 0 : 1;
    }
    closeDateTimePicker() {
        this.setCheckIn = false;
        this.setcheckOut = false;
    }
}
