import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { AppTheme } from 'src/app/types/users.types';

@Component({
    selector: 'item-input-text',
    templateUrl: './item-input-text.component.html',
    styleUrls: ['./item-input-text.component.scss']
})
export class ItemInputTextComponent implements OnInit {
    public form: FormGroup;
    // private required: boolean;

    @Input() lines = 'none';
    @Input() label: string;
    @Input() controlName: string;
    @Input() contentClass: string;
    @Input() readonly = false;
    @Input() theme: AppTheme;
    @Input() placeholder: string;
    @Input() clearInput = true;
    @Input() formGroupName: string;
    @Input() invalidText: string = 'Invalid name format';
    @Input() setFocus: boolean = false;

    constructor(private rootFormGroup: FormGroupDirective) {}

    @ViewChild('ionInputElement', { read: ElementRef }) ionInputElement: ElementRef;
    ngOnInit(): void {
        this.form = this.formGroupName ? (this.rootFormGroup.control.get(this.formGroupName) as FormGroup) : this.rootFormGroup.control;
        // this.required =
        //     this.form.controls[this.controlName].validator && this.form.controls[this.controlName].validator({} as AbstractControl)?.required ? true : false;
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/

    ngAfterViewInit() {
        if (this.setFocus) {
            setTimeout(() => {
                const ionInput = this.ionInputElement.nativeElement as HTMLIonInputElement;
                ionInput.setFocus();
            }, 150);
        }
    }
}
