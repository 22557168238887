<item-section-header [theme]="theme" [iconClose]="true" (closeModal$)="modalClose()" title="users.admitted.edit-modal"></item-section-header>
<ion-content [ngClass]="contentClass">
    <section-header-background [theme]="theme" [imageDataUrl]="image" [name]="user.firstName" [surname]="user.lastName"></section-header-background>

    <ion-list lines="full">
        <item-label-readout label="user.email" [value]="user.email"></item-label-readout>
        <item-label-readout label="user.phoneNumber" [value]="user.phoneNumber"></item-label-readout>
        <item-label-readout label="user.language" [value]="user.language"></item-label-readout>
        <form [formGroup]="form">
            <!-- Selettore del ruolo -->
            <role-chooser [theme]="theme" [value]="user.role" (selected)="changeRole($event, 'role')"></role-chooser>
            <view-chooser
                [theme]="theme"
                [unitId]="user.unitId"
                (selected)="changeView($event, 'view')"
                [viewName]="viewName"
                [role]="roleSelected"
                [isEdit]="true"
            ></view-chooser>
            <access-configuration
                *ngIf="roleSelected === 'member' || roleSelected === 'guest'"
                [timeWindowsEnable]="timeWindowsEnable"
                [role]="roleSelected"
                [theme]="theme"
                [(daysTimeWindows)]="daysTimeWindows"
                [(checkIn)]="checkIn"
                [(checkOut)]="checkOut"
                (changedTime)="changedTime($event)"
                (valueChange)="timeWindowsEnableValueChanged($event)"
                (daysTimeWindowsChange)="daysValueChanged($event)"
                [titleWindowsEnable]="titleWindowsEnable"
            ></access-configuration>

            <!-- enable -->
            <item-input-toggle
                [theme]="theme"
                controlName="enable"
                title="user.label.enable"
                [checked]="userEnable"
                (itemValueChange$)="toggleEnable($event)"
            ></item-input-toggle>
            <ion-item lines="full" detail="false" class="ion-no-padding ion-no-margin">
                <ion-label>{{ 'user.label.unlink' | translate }}</ion-label>
                <ion-button [color]="theme" slot="end" (click)="userUnlink()" class="outline" [disabled]="enableUser()">
                    {{ 'button.user.unlink' | translate }}
                </ion-button>
            </ion-item>
        </form>

        <ion-button (click)="editUser()" class="solid button-modal" expand="block" [disabled]="!form.valid">
            {{ 'button.submit' | translate }}
        </ion-button>
    </ion-list>
</ion-content>
