import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketEventName } from 'src/app/models/events-names.models';
import { FirmwareUpdateEntity } from 'src/app/models/firmware-update.model';
import { UnitEntity } from 'src/app/models/units.models';
import { WebsocketMessage } from 'src/app/models/websocket.models';
import { PlatformService } from 'src/app/services/platform.service';
import { SocketioService } from 'src/app/services/socketio.service';
import { AppTheme } from 'src/app/types/users.types';
import { TrackByComponent } from 'src/app/utilities/track-by.component';

@Component({
    selector: 'firmware-update-modal',
    templateUrl: './firmware-update-modal.component.html',
    styleUrls: ['./firmware-update-modal.component.scss']
})
export class FirmwareUpdateModalComponent extends TrackByComponent implements OnInit {
    public contentClass: string;
    public progressValue: number;

    @Input() firmwares: FirmwareUpdateEntity[] = [];
    @Input() unit: UnitEntity;

    @Input() theme: AppTheme;

    constructor(
        public modalController: ModalController,
        private alertCtrl: AlertController,
        private translate: TranslateService,
        private socketioService: SocketioService,
        public platformService: PlatformService
    ) {
        super();
    }

    ngOnInit() {
        this.socketioService.publishTopic<any>(this.unit.unitId, WebsocketEventName.reflashQuery);

        this.socketioService.getMessage(WebsocketEventName.reflashStatus).subscribe({
            next: (message: WebsocketMessage<any>) => {
                const isError = message.payload.some((obj) => obj.status === 'error');
                if (isError) {
                    this.errorAlert();
                } else {
                    this.firmwares = message.payload;
                }
            },
            error: (error) => {
                console.error('Errore durante la ricezione del messaggio WebSocket:', error);
            }
        });
    }
    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    closeModal() {
        this.modalController.dismiss();
    }

    async updateFirmware(item: any) {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('unit.update-firmware.header'),
            subHeader: this.translate.instant('unit.update-firmware.sub-header'),
            message: this.translate.instant('unit.update-firmware.message'),
            buttons: [
                {
                    text: this.translate.instant('button.cancel'),
                    role: 'cancel',
                    cssClass: this.theme
                },
                {
                    text: this.translate.instant('button.ok'),
                    cssClass: this.theme,
                    handler: () => {
                        this.socketioService.publishTopic<any>(this.unit.unitId, WebsocketEventName.reflashRequest, { payload: { filename: item.fileName } });
                    }
                }
            ]
        });
        (await alert).present();
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    async errorAlert() {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('unit.update-firmware.error.header'),
            subHeader: this.translate.instant('unit.update-firmware.error.sub-header'),
            message: this.translate.instant('unit.update-firmware.error.message'),
            buttons: [
                {
                    text: this.translate.instant('button.ok'),
                    cssClass: this.theme,
                    handler: () => {}
                }
            ]
        });
        (await alert).present();
    }
}
