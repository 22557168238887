import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItem } from 'src/app/components/menus/right/menu-right.service';
import { LeftMenuService } from './menu-left.service';
import { UserByUnitEntity, UserEntity } from 'src/app/models/users.models';
import { AppTheme, Group, Role, UserDisplayMode } from 'src/app/types/users.types';
import { UsersService } from 'src/app/services/users.service';
import { UnitsService } from 'src/app/services/units.service';
import { UnitEntity } from 'src/app/models/units.models';
import { TrackByComponent } from 'src/app/utilities/track-by.component';

@Component({
    selector: 'app-menu-left',
    templateUrl: './menu-left.component.html',
    styleUrls: ['./menu-left.component.scss']
})
export class MenuLeftComponent extends TrackByComponent {
    public indoor = false;
    public logged = false;
    private subs: Subscription;
    public loggedUserEntity: UserEntity;
    public indoorUserEntity: UserByUnitEntity;
    public indoorUnitEntity: UnitEntity;
    public menuItems: MenuItem[] = [];
    public theme: AppTheme;
    public group: Group;
    public displayMode: UserDisplayMode;
    public enable: boolean;

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    constructor(private router: Router, private menuLeftService: LeftMenuService, private usersService: UsersService, private unitsService: UnitsService) {
        super();
        this.subs = new Subscription();
        this.enable = true;
        //Gestiamo i dati dell'utente loggato
        this.subs.add(
            this.usersService.loggedUserObserver.subscribe((user) => {
                //console.log('menu-left.component received loggedUserEntity', Object.assign({}, user));
                this.loggedUserEntity = user;
                if (this.loggedUserEntity.userId) {
                    this.logged = true;
                    this.theme = this.loggedUserEntity.theme;
                    this.displayMode = this.loggedUserEntity.displayMode;
                    //Impostiamo la visualizzazione preferita dall'utente
                    this.menuLeftService.setDisplayMode(this.displayMode);
                    //Aggiorniamo il menu
                    this.updateMenuItems();
                } else {
                    this.logged = false;
                }
            })
        );

        //Gestiamo i dati dell'utente indoor
        this.subs.add(
            this.usersService.indoorUserObserver.subscribe((user) => {
                // console.log('menu-left.component received indoorUserEntity', Object.assign({}, user));
                this.indoorUserEntity = user;
                if (this.indoorUserEntity.userId) {
                    // console.log('Menu indoor');
                    this.indoor = true;
                } else {
                    // console.log('Menu outoor');
                    this.indoor = false;
                }
                //Aggiorniamo il menu
                this.updateMenuItems();
            })
        );

        //Gestiamo i dati della unit indoor
        this.subs.add(
            this.unitsService.indoorUnitObserver.subscribe((unit) => {
                this.indoorUnitEntity = unit;
            })
        );

        //Gestiamo il colore del menu selezionato
        this.subs.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.menuItems.forEach((item) => {
                        if (this.router.url.includes(item.url)) {
                            item.color = this.theme;
                            item.textColor = '#fff';
                        } else {
                            item.textColor = '#858585';
                            item.color = 'transparent';
                        }
                    });
                }
            })
        );
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    public updateMenuItems() {
        //Siamo loggati
        if (this.logged) {
            //Siamo outdoor
            if (!this.indoor) {
                switch (this.loggedUserEntity.group) {
                    case Group.administrator:
                        this.menuItems = this.menuLeftService.menu.outdoor.adminItems;
                        break;
                    case Group.support:
                        this.menuItems = this.menuLeftService.menu.outdoor.supportItems;
                        break;
                    case Group.customer:
                        this.menuItems = this.menuLeftService.menu.outdoor.customerItems;
                        break;
                    default:
                        this.menuItems = [];
                        break;
                }
            }
            //Siamo indoor
            else {
                switch (this.indoorUserEntity.role) {
                    case Role.service:
                        this.menuItems = this.menuLeftService.menu.indoor.serviceItems;
                        break;
                    case Role.owner:
                        this.menuItems = this.menuLeftService.menu.indoor.ownerItems;
                        break;
                    case Role.member:
                        this.menuItems = this.menuLeftService.menu.indoor.memberItems;
                        break;
                    case Role.guest:
                        this.menuItems = this.menuLeftService.menu.indoor.guestItems;
                        break;
                    default:
                        this.menuItems = [];
                        break;
                }
            }
        }
        //Non siamo loggati
        else {
            this.menuItems = [];
        }

        //Aggiorniamo il colore del menu selezionato
        this.menuItems.forEach((item) => {
            if (this.router.url.includes(item.url)) {
                item.color = this.theme;
                item.textColor = 'white';
            } else {
                item.textColor = '#858585';
                item.color = 'transparent';
            }
        });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    userEdit(): void {
        const userId = this.loggedUserEntity.userId;
        if (!this.indoor) {
            this.router.navigateByUrl(`/outdoor/users/edit`, {
                replaceUrl: true
            });
        } else {
            this.router.navigateByUrl(`/indoor/users/edit`, {
                replaceUrl: true
            });
        }
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
        Click delle voci di menu
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    routerLink(url: string) {
        this.router.navigateByUrl(url, { replaceUrl: true });
    }

    /*----------------------------------------------------------------------------------------------------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------------------------------------------*/
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
